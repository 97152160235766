<template>
  <div class="is-main-content" style="padding: 5% 0 0 0 !important">
    <!-- <div class="overlay-disabled-page"></div> -->
    <div class="is-fullheight accueil">
      <div class="ResponsiveTableUser">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div
              class="columns is-multiline justify-content-space-evenly"
              style="
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                margin: 0 100px 0 10px;
                margin-top: 45px;
              "
            >
              <div class="">
                <div class="has-text">
                  <p
                    v-if="
                      (group.nb_users_licence ||
                        group.nb_users_licence === 0) &&
                      group.nb_users
                    "
                    class="has-text-weight-semibold is-size-2 box-titre"
                  >
                    {{ group.nb_users_licence }} /
                    <span v-if="group.nb_users">{{ group.nb_users }}</span>
                    <span v-else>Illimité</span>
                  </p>
                  <p
                    v-else
                    class="has-text-weight-semibold credits-info is-size-2 box-titre"
                  > 
                  <i class="fas fa-calendar-alt"></i>
                  <!-- <i class="fas fa-coins"></i> -->
                    {{ group.credits }} mois de distanciels restants (à attribuer)
                  </p>
                  <p v-if="group.nb_users">
                    {{ $t("accesSousLicenceActives") }}
                  </p>
                  <p v-else></p>
                  <a
                    v-if="group.nb_users_licence >= group.nb_users"
                    class="button is-primary button-acheter"
                  >
                    {{ $t("acheter") }}&nbsp;&nbsp;
                    <b-icon pack="fas" icon="plus" size="is-small"></b-icon>
                  </a>
                </div>
              </div>
              <div
                :class="{ lock: group.type_abonnement == 'gratuit' }"
                class="has-text-centered box-information"
              >
                <p>
                  <b-icon pack="fas" icon="user-plus" size="is-large"></b-icon>
                </p>
                <a style="background: #239380;" class="button is-margin-10 is-primary buttonAjoutUser" @click="openModal()">
                  Créez un patient/client
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isCardAddUserOpen" >
      <!--class="my-custom-modal"-->
      <!-- <div class="my-custom-modal-content" style="width:80%;"> -->
        <creation-utilisateur-component @close="handleCloseCreateUser" @refreshListUser="handleGetMyUsers" @closeForm="isCardAddUserOpen = false" />
        
      <!-- </div> -->
    </div>
        <div class="" style="margin-bottom: 0px; margin-top: 20px">
          <liste-utilisateurs-component
            :class="{ lock: group.type_abonnement == 'gratuit' }"
            v-if="loadDone"
            :usersInMyGroup="usersInMyGroup"
            :group="group"
            @closeModal="openEditUser = false"
            @openEditUser="openEditUser"
            @openSuivi="openSuivi"
            @openRelance="openRelance"
            @refreshListUser="handleGetMyUsers"

          />
        </div>
      </div>
    </div>
    <b-modal
      :width="1200"
      :active.sync="isCardEditUserOpen"
      @close="closeModalEdit()"
    >
      <modification-utilisateur-component
        :UserSelected="UserSelected"
        :group="group"
        :user="user"
        @editUser="editUser"
        @closeModal="closeModalEdit"
        @closeModalEdit="closeModalEdit"
        @closeModalLicence="closeModalLicence"
      />
    </b-modal>
    <b-modal
      :width="1300"
      :active.sync="isCardSuiviOpen"
      @close="isCardSuiviOpen = false"
    >
      <suivi-utilisateur-component
        :UserSelected="UserSelected"
        @closeModal="isCardSuiviOpen = false"
      />
    </b-modal>

    <b-modal
      :width="1200"
      :active.sync="isCardRelanceOpen"
      @close="isCardRelanceOpen = false"
    >
      <relance-utilisateur-component
        :UserSelected="UserSelected"
        @closeModal="isCardRelanceOpen = false"
      />
    </b-modal>

    <b-modal
      :width="1200"
      :active.sync="isCardModalIframe"
      @close="isCardModalIframe = false"
    >
      <modal-iframe-component
        url="https://www.symbiocenter.fr/2020/01/23/espace-de-gestion-creer-des-utilisateurs/"
      />
    </b-modal>
  </div>
</template>

<script>
import Parse from "parse";
import { createNamespacedHelpers } from "vuex";
import * as types from "@/store/user-mutation-type";
import { URL_FRONT } from "@/configuration/urlConf.js";
// import creationUtilisateurComponent from "./utilisateur/creationUtilisateur.vue";
import creationUtilisateurComponent from "./utilisateur/NewCreationUtilisateur.vue";

import modificationUtilisateurComponent from "./utilisateur/modificationUtilisateur.vue";
import suiviUtilisateurComponent from "./utilisateur/suiviUtilisateur.vue";
import listeUtilisateursComponent from "./utilisateur/listeUtilisateurs.vue";
import RelanceUtilisateurComponent from "./utilisateur/relanceUtilisateur.vue";
import ModalIframe from "./modalInformation.vue";
import { unlockFolder } from "@/services/folders/unlockFolder.js";
import { getFilesDoneByUserId } from "@/services/folders/getFilesDoneByUserId.js";
import { TYPE_PRATIQUE } from "@/configuration/userGroupConf.js";
import { getUsersByGroupId } from "@/services/utilisateurs/getUsersByGroupId.js";
import router from "@/router";
import asyncForEach from "@/services/general/asyncForEach.js";
import { getSuiviForUser } from "@/services/utilisateurs/getSuiviForUser.js";
import WebSocketService from "@/services/socket/WebSocketService.js";

const User = createNamespacedHelpers("user");

export default {
  name: "utilisateurs",
  components: {
    CreationUtilisateurComponent: creationUtilisateurComponent,
    ModificationUtilisateurComponent: modificationUtilisateurComponent,
    ListeUtilisateursComponent: listeUtilisateursComponent,
    SuiviUtilisateurComponent: suiviUtilisateurComponent,
    ModalIframeComponent: ModalIframe,
    RelanceUtilisateurComponent: RelanceUtilisateurComponent,
  },
  data() {
    return {
      usersInMyGroup: [],
      isCardAddUserOpen: false,
      newTemporaryUser: {},
      errorEmailFormat: false,
      errorEmailUse: false,
      error: false,
      foldersBrique: [],
      foldersBriqueEditUser: [],
      linksToAdd: [],
      UserSelected: null,
      isCardEditUserOpen: false,
      openAccesExercices: false,
      openSuiviPratique: true,
      typePratique: TYPE_PRATIQUE.cabinet,
      textCabinet: TYPE_PRATIQUE.cabinet,
      textLicence: TYPE_PRATIQUE.licence,
      textAffiliation: TYPE_PRATIQUE.affiliation,
      isCardSuiviOpen: false,
      isCardRelanceOpen: false,
      loadDone: false,
      isCardModalIframe: false,
      suiviData: [],
    };
  },
  computed: {
    ...User.mapGetters(["group", "user", "folders"]),
    canAddUser() {
      return this.group.type_abonnement === "pulse";
    },
  },
  methods: {
    ...User.mapMutations({
      editUser: types.EDIT_USER,
      editFirstConnection: types.EDIT_FIRST_CONNECTION,
    }),
    ...User.mapActions({
      actionsLoadFolder: "loadFolder",
      actionsLogOut: "logOut",
    }),
    handleCloseCreateUser() {
      this.isCardAddUserOpen = false;
    },
    handleGetMyUsers() {
      //console.log('handleGetMyUsers')
      this.getMyUsers();
    },
    async getMyUsers() {
      this.loadDone = false;
      this.usersInMyGroup = [];
      const props = await getUsersByGroupId(this.group);
      var QueryGroup = new Parse.Query(Parse.Object.extend("user_group"));
      QueryGroup.equalTo("objectId", this.group.objectId);
      var ResponseGroup = await QueryGroup.first();
      this.group.nb_users_licence = props.nb_users_licence;
      this.usersInMyGroup = props.usersInMyGroup;
      Parse.Object.extend("session_detail");
      var QuerySessionGroup = new Parse.Query(
        Parse.Object.extend("session_group")
      );
      QuerySessionGroup.equalTo("user_group", ResponseGroup);
      var ResponseSessionGroup = await QuerySessionGroup.find();

      await asyncForEach(ResponseSessionGroup, async (seance_group) => {
        if (seance_group.attributes.date_end >= new Date()) {
          var RelationSeanceGroup = seance_group.relation("users");
          let QueryUsersFromRelation = RelationSeanceGroup.query();
          QueryUsersFromRelation.limit(1000);
          var ResponseUsersFromRelation = await QueryUsersFromRelation.find();
          await ResponseUsersFromRelation.forEach((userFromRelation) => {
            var user = this.usersInMyGroup.find((e) => {
              return e.objectId === userFromRelation.id;
            });
            if (user) {
              user.seance_group = {
                name: seance_group.attributes.name,
                date: seance_group.attributes.date_start,
              };
            }
          });
        }
      });
      this.loadDone = true;
    },
    async openModal() {
      this.isCardAddUserOpen = true;
    },
    unlockProtocol(props) {
      unlockFolder(props, this.group.type_abonnement);
    },
    async openEditUser(e) {
      const loadingComponent = this.$buefy.loading.open();
      this.UserSelected = Object.assign({}, e);
      this.UserSelected.filesDone = null;
      this.isCardEditUserOpen = true;
      loadingComponent.close();
    },
    async openSuivi(e, fileDone) {
      const loadingComponent = this.$buefy.loading.open();
      this.UserSelected = Object.assign({}, e);
      this.UserSelected.filesDone = await getFilesDoneByUserId(e.objectId);
      this.suiviData = await getSuiviForUser(e.objectId);
      this.isCardSuiviOpen = true;
      loadingComponent.close();
    },
    async openRelance(e) {
      const loadingComponent = this.$buefy.loading.open();
      this.UserSelected = Object.assign({}, e);
      this.UserSelected.filesDone = await getFilesDoneByUserId(e.objectId);
      this.isCardRelanceOpen = true;
      loadingComponent.close();
    },
    async supprimerUtilisateur() {
      Parse.masterKey = "myMasterKey";
      const queryUser = new Parse.Query(Parse.User);
      queryUser.equalTo("objectId", this.UserSelected.objectId);
      const userResponse = await queryUser.first();
      userResponse.set("compte_desactive", true);
      await userResponse.save({}, { useMasterKey: true });
      await this.getMyUsers();
      this.isCardEditUserOpen = false;
    },
    async createUser(e) {
      const loadingComponent = this.$buefy.loading.open();
      await this.getMyUsers();
      this.isCardAddUserOpen = false;
      this.isCardEditUserOpen = false;
      loadingComponent.close();
      if (e === "affiliation") {
        router.push("/retrocession-et-royalties");
      }
    },
    async editUser() {
      const loadingComponent = this.$buefy.loading.open();
      await this.getMyUsers();
      this.isCardEditUserOpen = false;
      loadingComponent.close();
    },
    async relancer() {},
    async closeModalEdit(e) {
      if (e === "affiliation") {
        router.push("/retrocession-et-royalties");
      }
      this.isCardEditUserOpen = false;
      if (e) {
        const user = this.usersInMyGroup.find((user) => {
          return user.objectId === e;
        });
        this.usersInMyGroup.splice(this.usersInMyGroup.indexOf(user), 1);
      }
    },
    async closeModalLicence(e) {
      this.isCardEditUserOpen = false;
      if (e) {
        const user = this.usersInMyGroup.find((user) => {
          return user.objectId === e;
        });
        user.typePratique = "cabinet";
        this.group.nb_users_licence -= 1;
      }
    },
    callSocketOnline()
    {
      try {
          const webSocketService = new WebSocketService();

          // Pour se connecter au WebSocket
          webSocketService.connectToWebSocket();

          // Pour envoyer l'ID de l'utilisateur
          const userId = Parse.User.current().id;
          webSocketService.sendUserId(userId);
        } catch (error) {
          console.error("Erreur lors de la connexion au WebSocket :", error);
        }
    },
  },
  async mounted() {
    if (this.user.therapeute !== true) {
      window.location.href = URL_FRONT;
    }
    // try {
    //   var query = new Parse.Query("site_admin");
    //   await query.first();
    //   // Parse.Cloud.run("getContacts");
    //   //  Parse.Cloud.run("passwordTherapeute");
    // } catch (error) {
    //   await this.actionsLogOut();
    //   router.go("/connexion");
    //   return;
    // }
    const loadingComponent = this.$buefy.loading.open();
    await this.getMyUsers();
    loadingComponent.close();

    //this.callSocketOnline();
  },
};
</script>

<style lang="scss">
.icon-tableau {
  max-width: 25px;
}

.icon-tableaubis {
  max-width: 30px;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.lock {
  pointer-events: none;
  background-color: lightgrey;
  opacity: 0.5;
}
.buttonAjoutUser:hover
{
  background-color: #239380;
  transition: 0.3s;
  transform: scale(1.04);
}
/* .overlay-disabled-page {
  position: absolute; 
  width: 100%;
  height: 100%;
  top: 0;
  left: 225px;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); 
  z-index: 2; 
  cursor: pointer; 
} */

$my-modal-bg-color: rgba(0, 0, 0, 0.5);
$my-modal-content-bg-color: white;
$my-modal-content-width: 60%;
$my-modal-content-padding: 20px;
$my-modal-content-border-radius: 8px;

.my-custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $my-modal-bg-color;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
}

.my-custom-modal-content {
  background-color: $my-modal-content-bg-color;
  width: $my-modal-content-width;
  max-width: 100%;
  padding: $my-modal-content-padding;
  border-radius: $my-modal-content-border-radius;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Ajout de la propriété overflow-y pour activer la barre de défilement verticale */
  max-height: 80vh; /* Réglez la hauteur maximale de la div pour qu'elle ne dépasse pas l'écran */
}

/* Pour les navigateurs WebKit (Chrome, Safari) */
.my-custom-modal-content::-webkit-scrollbar {
  width: 12px; /* Largeur de la barre de défilement */
}
.credits-info {
  font-size: 2rem; /* Taille de la police */
  font-weight: bold; /* Graisse de la police */
  color: #239380; /* Couleur du texte (bleu dans cet exemple) */
  background-color: #f0f0f0; /* Couleur de fond (gris clair dans cet exemple) */
  padding: 10px; /* Espacement intérieur pour la boîte */
  border: 1px solid #ccc; /* Bordure fine */
  border-radius: 5px; /* Coins arrondis */
  text-align: center; /* Centre le texte horizontalement */
  margin-top: 1rem; /* Marge supérieure pour l'espacement */
}
.my-custom-modal-content::-webkit-scrollbar-thumb {
  background-color: #888; /* Couleur de la poignée de défilement */
  border-radius: 6px; /* Rayon des coins de la poignée de défilement */
}

.my-custom-modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Couleur de la poignée de défilement au survol */
}

.my-custom-modal-content::-webkit-scrollbar-track {
  background-color: #eee; /* Couleur de la piste de défilement */
}

/* Pour Firefox */
.my-custom-modal-content {
  scrollbar-width: thin; /* Largeur de la barre de défilement */
  scrollbar-color: #888 #eee; /* Couleur de la poignée de défilement et de la piste de défilement */
}

/* Pour Firefox, personnalisation de la poignée de défilement au survol */
.my-custom-modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Couleur de la poignée de défilement au survol */
}
// @media screen and (min-width: 768px) and (max-width: 1400px) and (orientation: landscape) {
//   .ResponsiveTableUser {
//     margin: 500px;
//   }
// }

.ResponsiveTableUser {
  margin-left: 35px;
}
</style>
