<template>
  <div>
    <div
      v-for="item in questionnaireBFP"
      class="card-container-bfp"
      :key="item.id"
    >
      <!-- <button @click="test(item)">test</button> -->
      <div
        v-bind:style="
          questionnaireSelectedId === item.id && displayQuestionnaire
            ? 'background-color: #489181'
            : ''
        "
        class="card-bfp"
        @click="openForm(item)"
        v-if="!isForBeginPage"
      >
        <div class="card-content-bfp">
          <div class="card-icon-bfp">
            <img
              v-if="questionnaireSelectedId === item.id && displayQuestionnaire"
              src="../../assets/img/icon-bfp-white.png"
            />
            <img src="../../assets/img/icon-bfp.png" v-else />
          </div>
          <div class="card-text-bfp">
            <h2
              v-bind:style="
                questionnaireSelectedId === item.id && displayQuestionnaire
                  ? 'color:white'
                  : ''
              "
            >
              {{ item.attributes.name }}
            </h2>
            <img
              v-if="questionnaireSelectedId === item.id && displayQuestionnaire"
              class="chevron-down-bfp"
              src="../../../../assets/img/ChevronUpBlancV2.png"
            />
            <img
              v-else
              src="../../../../assets/img/ChevronDownV2.png"
              class="chevron-down-bfp"
            />
          </div>
        </div>
      </div>
      <div
        class="boxExercicesEchelles"
        v-if="questionnaireSelectedId === item.id"
      >
        <div v-if="startEdas">
          <p>
            Veuillez lire chaque énoncé et indiquez lequel correspond le mieux à
            votre expérience au cours de la dernière semaine. Indiquez votre
            choix en encerclant le chiffre qui y correspond (soit 0,1,2 ou 3).
            Il n’y a pas de bonne ou de mauvaise réponse. Ne vous attardez pas
            trop longuement aux énoncés.
          </p>
          <div class="divButtonDemarrer">
            <button
              class="buttonDemarrerQuestionnaire"
              @click="startQuestionnaire()"
            >
              Commencer
            </button>
            <!-- <button class="redirect-button" @click="redirigerVersRelecture">Redirigez-vous vers la relecture</button> -->
          </div>
        </div>
        <div v-if="!startEdas">
          <div
            v-if="startQuestionnaireValue && questions.length > 0 && !IsFinish"
          >
            <div class="QuestionTitleQCM">
              <div class="numberCircle">
                {{ currentQuestionIndex + 1 + "/" + numberOfQuestions }}
              </div>
              <div class="QuestionQCM">
                {{ questions[currentQuestionIndex].name }}
              </div>
            </div>
            <div
              v-for="answer in shuffledAnswers"
              :key="answer.id"
            >
              <!-- <span>{{ answer.text }}</span> -->
              <label class="answer">
                <input
                  :id="answer.points"
                  type="radio"
                  :name="answer.objectId"
                  :value="answer.points"
                  v-model="localPicked"
                />
                {{ " " + answer.text }}
              </label>
            </div>
            <!-- <button @click="test(questions)">Test</button> -->

            <div
              class="navigation-buttons"
              v-if="currentQuestionIndex === questions.length - 1 && !IsFinish"
            >
              <div class="navigation-button">
                <button
                  style="margin-left:auto;margin-right:auto;color: white !important; backgroundColor:#239380 !important;'"
                  class="buttonChoix"
                  @click="TerminerExerciceQCM(item.id, resultat)"
                >
                  Enregistrer et continuer
                </button>
              </div>
            </div>
            <div v-else-if="!IsFinish" class="navigation-buttons">
              <div class="navigation-button">
                <button
                  @click="
                    prevQuestion(localPicked, questions[currentQuestionIndex])
                  "
                  class="buttonChoix"
                  :disabled="currentQuestionIndex === 0"
                >
                  Précédent
                </button>
                <button
                  @click="
                    NextQuestion(localPicked, questions[currentQuestionIndex])
                  "
                  class="buttonChoix"
                  :disabled="currentQuestionIndex === questions.length - 1"
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="score-results" v-if="!isForBeginPage">
              <h3>Résultats</h3>
              <p>
                Félicitations, vous avez terminé le questionnaire
                {{ questionnaireSelected.attributes.name }} !
              </p>
              <p>
                La relecture de l'exercice est disponible dans le suivi de votre
                utilisateur.
              </p>
              <button class="redirect-button" @click="redirigerVersRelecture" v-if="!isForBeginPage">
                Voir le Résultat
              </button>
            </div>
            <div v-else>
              <p>
                Félicitations, vous avez terminé le questionnaire
                {{ questionnaireSelected.attributes.name }} !
              </p>
              <p>
                Le résultat de votre questionnaire a été envoyé à votre thérapeute.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="answers">
              <div class="answer">Ne s'applique pas du tout à moi</div>
              <div class="answer">S'applique un peu à moi, ou une partie du temps</div>
              <div class="answer">S'applique beaucoup à moi, ou une bonne partie du temps</div>
              <div class="answer">S'applique entièrement à moi, ou la grande majorité du temps</div>
            </div> -->
</template>

<script>
import { getEDAS } from "../../services/bfp/getEDAS";
import { findQuestionsByQuestionnaireId } from "@/services/bfp/findQuestionsByQuestionnaireId";
import Parse from "parse";
import { createNotificationForBeginQuestionnaire } from "@/services/notifications/createNotificationForBeginQuestionnaire.js";

import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  data() {
    return {
      questionnaireBFP: [],
      questionnaireSelected: {},
      questionnaireSelectedId: "",
      displayQuestionnaire: false,
      questions: [],
      selectedAnswers: [],
      selectedAnswersText: [],
      totalScore: 0,
      showNotification: false,
      progressWidth: 0,
      intervalId: null,
      refreshKey: 0,
      startQuestionnaireValue: false,
      currentQuestionIndex: 0,
      startEdas: true,
      localPicked: null,
      answers: [],
      resultat: 0,
      categoryScores: {},
      startTime: null,
      endTime: null,
      IsFinish: false,
    };
  },
  async created() {
    // console.log("Création du component edasComponent");
    this.questionnaireBFP = await getEDAS();
    // console.log("Questionnaire BFP", this.questionnaireBFP);
    if (this.isForBeginPage) {
      this.openForm(this.questionnaireBFP[0]);
    }
  },
  
  watch: {
    currentQuestion(newValue, oldValue) {
      if (newValue === undefined) {
        this.TerminerExercice(this.questionnaireSelectedId, this.totalScore);
      }
    },
  },
  props: {
    isForBeginPage: {
      type: Boolean,
      default: false,
    },
    idPatientTherapeute: {
      type: String,
      default: null,
    },
    
  },
  computed: {
    numberOfQuestions() {
      return this.questions.length;
    },
    shuffledAnswers() {
      // Copiez les réponses pour ne pas modifier l'ordre original
      const answersCopy = [...this.questions[this.currentQuestionIndex].answers];

      // Mélangez les réponses
      const shuffled = this.shuffleArray(answersCopy);

      return shuffled;
    },
  },
  methods: {
    async startQuestionnaire() {
      this.startQuestionnaireValue = true;
      this.startEdas = false;
      this.startTime = new Date();
    },
    shuffleArray(array) {
      // Algorithme de mélange de Fisher-Yates
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    redirigerVersRelecture() {
      console.log("IdPatient :", this.idPatientTherapeute);
      //expire après 1 minutes
      document.cookie =
        "IdUserBfp=" + this.idPatientTherapeute + ";path=/;max-age=60";
      window.location.href = `/#/utilisateurs`;
    },
    nextQuestion() {},
    NextQuestion(picked, question) {
      // this.$emit("NextQuestion", picked, element2);
      if (picked === null) {
        Swal.fire({
          icon: "error",
          title: "Attention",
          text: "Veuillez sélectionner une réponse avant de continuer.",
        });
      } else {
        console.log("Question", question);
        console.log("Score:", picked);
        this.localPicked = null;

        this.answers.push({
          question_id: question.id,
          question: question.name,
          note: picked,
        });
        this.resultat += picked;

        if (this.currentQuestionIndex < this.questions.length - 1) {
          this.currentQuestionIndex++;
        }

        if (question.category) {
          const categoryName = question.category.name;
          // Si un score cumulatif n'existe pas pour cette catégorie, le créer et l'initialiser à 0
          if (!this.categoryScores[categoryName]) {
            this.categoryScores[categoryName] = 0;
          }
          // Ajouter le score de la question au résultat cumulatif de la catégorie correspondante
          this.categoryScores[categoryName] += picked;
        }
        console.log("this.categoryScores", this.categoryScores);
      }
    },
    test(question) {
      console.log("Question", question);
    },
    prevQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
      }
      this.localPicked = null;
    },
    refreshComponent() {
      this.refreshKey++; // Incrémente la valeur de refreshKey pour déclencher le rafraîchissement du composant
    },
    openForm(questionnaire) {
      console.log("Questionnaire reçu dans openForm", questionnaire);
      this.questionnaireSelectedId = questionnaire.id;
      this.questionnaireSelected = questionnaire;
      this.findQuestion(questionnaire.id);
      this.displayQuestionnaire = !this.displayQuestionnaire;
    },
    startTimer() {
      this.showNotification = true;
      this.progressWidth = 0;
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.progressWidth += 3.33;
        if (this.progressWidth >= 100) {
          clearInterval(this.intervalId);
        }
      }, 100);
      setTimeout(() => {
        this.closeNotification();
      }, 3000);
    },
    closeNotification() {
      this.showNotification = false;
      this.progressWidth = 0;
    },
    TerminerExercice(id, resultat) {
      this.$emit(
        "TerminerExerciceQCM",
        id,
        resultat,
        this.answers,
        this.categoryScores
      );
    },
    async TerminerExerciceQCM(fileId, note) {
      console.log(this.categoryScores);

      // Supposons que this.categoryScores soit votre objet actuel
      const scoresArray = Object.entries(this.categoryScores).map(
        ([question, score]) => ({
          question,
          score: score * 2, // Vous pouvez convertir le score en chaîne si nécessaire
        })
      );
      var NoteDetailArray = JSON.stringify(scoresArray);
      //transforme le tableau en string
      console.log(scoresArray);
      this.IsFinish = true;
      this.endTime = new Date();
      const timeDifference = this.endTime - this.startTime;
      const secondsDifference = timeDifference / 1000;
      //arrondir à l'unité
      secondsDifference.toFixed(0);
      this.$emit("EnableButton");
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      let noteQuestionnaire = note.toString();
      const objectFolder = Parse.Object.extend("folder");
      const objectFile = Parse.Object.extend("file");
      const objectUser = Parse.Object.extend("User");
      const objetLink = Parse.Object.extend("link");
      const objectQuestionnaire = Parse.Object.extend("questionnaire");
      const queryFolder = new Parse.Query(objectFolder);
      const queryFolder2 = new Parse.Query(objectFolder);
      const queryUser = new Parse.Query(objectUser);
      const queryFile = new Parse.Query(objectFile);
      const queryLink = new Parse.Query(objetLink);
      const queryQuestionnaire = new Parse.Query(objectQuestionnaire);
      queryLink.include("file");
      //find a link with file
      queryQuestionnaire.equalTo("objectId", fileId);
      queryFile.matchesQuery("questionnaire_", queryQuestionnaire);
      queryLink.matchesQuery("file", queryFile);

      let brique, module, file, user, link, questionnaire;
      if (this.idPatientTherapeute) {
        queryUser.equalTo("objectId", this.idPatientTherapeute);
      } 
      else if(localStorage.getItem("idUserForSave"))
      {
        queryUser.equalTo("objectId", localStorage.getItem("idUserForSave"));
      }
      else {
        queryUser.equalTo("objectId", Parse.User.current().id);
      }
      [brique, module, file, user, link, questionnaire] = await Promise.all([
        queryFolder.find(),
        queryFolder2.find(),
        queryFile.find(),
        queryUser.find(),
        queryLink.find(),
        queryQuestionnaire.find(),
      ]);
      console.log(brique, module, file, user, link, questionnaire);
      // var scoreDetail = JSON.stringify(this.answers);
      //passer la note en string
      noteQuestionnaire = this.resultat;
      var noteQuestionnaire2 = noteQuestionnaire.toString();
      const dataFileDone = {
        brique: undefined,
        time_elapsed: secondsDifference,
        user: user[0],
        module: undefined,
        protocole: undefined,
        resultatJeux: undefined,
        note: noteQuestionnaire,
        file: file[0],
        comment: undefined,
        group: Parse.User.current().get("group"),
        note_detail: NoteDetailArray,
        bfp: true,
      };
      const Data = Parse.Object.extend("file_done");
      const dataDone = new Data();
      try {
        const result = await dataDone.save(dataFileDone);
        console.log("Data saved", result);
        createNotificationForBeginQuestionnaire(file[0], noteQuestionnaire);
        // const result2 = await dataLink.save(dataLinkCompleted);
        this.answers = [];
        Toast.fire({
          icon: "success",
          title: "Votre questionnaire a été envoyé avec succès",
        });
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Une erreur est survenue lors de l'envoi du questionnaire",
        });
        // s;
        console.error("Error while creating Data: ", error);
      }
      this.$forceUpdate();
    },
    test(item) {
     console.log("iteMMMMMM", item);
    },
    async findQuestion(id) {
      try {
        const results = await findQuestionsByQuestionnaireId(id);
        this.questions = results;
        console.log("Questions", this.questions);
      } catch (error) {
        console.error("Error while fetching questions:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container-bfp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-bfp {
  width: 100%;
  max-width: 1000px;
  border: 1px solid #489181;
  padding: 20px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: white;
  margin-top: 1rem;
}
.card-bfp:hover {
  background-color: #489181;
  color: white !important;
  transition: 0.3s;
  transform: scale(1.04);
}

.card-bfp:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-content-bfp {
  display: flex;
  align-items: center;
}

.card-icon-bfp {
  margin-right: 10px;
  font-size: 24px;
  color: #42b983;
}

.card-text-bfp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.card-content-bfp h2,
.card-content-bfp p {
  margin: 0;
  color: #000;
}

.chevron-down-bfp {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.form-card-bfp {
  width: 80%;
  max-width: 1000px;
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: -10px;
  border-radius: 0px 0px 8px 8px;
  background-color: white;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.selected-questionnaire {
  margin-top: 2%;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 1.5rem;
}
.SelectedResponse {
  text-align: center;
}
.selected-questionnaire h2 {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  width: 50%;
}

.question {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  padding-bottom: 120px;
  background-color: white;
}

.SelectedResponse {
  padding: 15px;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  color: #856404;
  font-weight: bold;
}

.SelectedResponse p {
  margin-bottom: 10px;
}

.button {
  margin-right: 10px;
}
.selected-questionnaire .start-questionnaire {
  font-size: 1rem;
  font-weight: 600;
  background-color: #3273dc;
  color: #fff;
  border-color: transparent;
  box-shadow: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}

.selected-questionnaire .start-questionnaire:hover {
  background-color: #2366d1;
  color: #fff;
}
.questions {
  margin-top: 1rem;
}

.answer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin: 1rem;
}

input[type="radio"] {
  margin-right: 0.5rem;
}
// .score-results {
//   display: flex;
//   align-items: center;
//   max-width: 1000px;
//   width: 100%;
//   padding: 20px;
//   background-color: #f8f8f8;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// }

.result-indicator {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result-indicator.vert {
  background-color: #00ff00;
}

.result-indicator.orange {
  background-color: #ffa500;
}

.result-indicator.rouge {
  background-color: #ff0000;
}

.score-details {
  flex: 1;
}

.score-details h3 {
  margin-bottom: 10px;
  text-align: center;
}

.score-details p {
  text-align: center;
}

.divQuestionEDAS {
  width: 60%;
}

.divQuestionEDAS {
  display: flex;
  flex-direction: column;
  width: 60%;

  .selected-questionnaire {
    position: relative;
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;

    &:hover {
      //   background-color: #e0e0e0;
      transform: scale(1.02);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    }

    .question-number {
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: #489181;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      transition: background-color 0.3s;

      &:hover {
        background-color: #3d7a67;
      }
    }

    h2 {
      font-size: 20px;
      margin-top: 15px;
      color: #333;
      margin-left: 2rem;
      margin-right: 2rem;
    }

    .answers {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;

      .answer {
        padding: 10px 20px;
        border: 2px solid #ccc;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s, border-color 0.3s;

        &:hover {
          background-color: #e0e0e0;
          border-color: #489181;
        }
      }
    }
  }
}

.boxExercicesEchelles {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  width: 60%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  padding: 1.5rem;
  border: 1px solid #00800069;
}

.buttonDemarrerQuestionnaire {
  padding: 10px 20px;
  background-color: rgb(35, 147, 128);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  margin-left: auto;
  margin-right: auto;
}
.divButtonDemarrer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.navigation-buttons {
  display: flex;
  margin-top: 20px;
}
.navigation-button {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.buttonChoix {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  background-color: lightgray;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  margin: 8px;
  font-size: 80%;
}

.score-results {
  // border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  h3 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 15px;
  }
  .redirect-button {
    background-color: rgb(35, 147, 128);
    color: #fff;
    padding: 10px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgb(28, 126, 109);
    }
  }
}
</style>
