<template>
  <section class="main-connexion">
    <div class="connexion-content">
      <div class="container has-text-centered">
        <div
          class="column is-4 is-offset-4 is-offset-2-mobile is-8-mobile"
          v-if="!codeSend"
        >
          <a
            class="card-content is-flex is-horizontal-center"
            a
            href="https://symbiocenter.fr/"
            target="_blank"
          >
            <img
              src="../../../assets/img/Logos_Symbiofi_SymbioCenter_PRO_vectoriser.png"
              class=""
            />
          </a>
          <h3 class="title has-text-primary has-text-centered is-size-4">
            {{ $t("reinitialiserMotDePasse") }} <br />
          </h3>
          <div class="box">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="email"
                  placeholder="Email"
                  autofocus=""
                  v-model="user.email"
                />
              </div>
            </div>
            <div>
              <p
                class="has-text-weight-light has-text-danger is-size-6"
                v-if="error"
              >
                {{ error }}
              </p>
            </div>
            <button
              class="button is-primary is-primary is-fullwidth is-large"
              @click="sendCode()"
            >
              {{ $t("envoyerCode") }}
            </button>
          </div>
        </div>
        <div
          class="column is-4 is-offset-4"
          v-if="codeSend"
        >
          <a
            class="card-content is-flex is-horizontal-center"
            a
            href="https://symbiocenter.fr/"
            target="_blank"
          >
            <img
              src="../../../assets/img/Logos_Symbiofi_SymbioCenter_PRO_vectoriser.png"
              class=""
            />
          </a>
          <h3 class="title has-text-primary has-text-centered is-size-4">
            {{ $t("reinitialiserMotDePasse") }} <br />
          </h3>
          <div class="box">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="email"
                  placeholder="Email"
                  v-model="user.email"
                  disabled
                />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="code"
                  placeholder="Code de vérification"
                  v-model="user.code"
                />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="Mot de passe"
                  v-model="user.password"
                />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="Mot de passe"
                  v-model="user.repassword"
                />
              </div>
            </div>
            <div>
              <p
                class="has-text-weight-light has-text-danger is-size-6"
                v-if="error"
              >
                {{ error }}
              </p>
            </div>
            <button
              class="button is-primary is-primary is-fullwidth is-large"
              @click="resetPassword()"
            >
              {{ $t("reinitialiserMotDePasse") }}
            </button>
          </div>
        </div>

        <div
          class="button has-text-primary has-text-centered is-margin-10 is-small"
        >
          <router-link class="has-text-centered" :to="'/connexion'">
            {{ $t("retourPageConnexion") }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export default {
  name: "resetPassword",
  data() {
    return {
      user: {
        email: "",
        code: "",
        password: "",
        repassword: "",
      },
      error: "",
      codeSend: false,
      userId: "",
      passwordCode: "",
    };
  },
  methods: {
    async sendCode() {
      ParseConfig();
      let result = await Parse.Cloud.run("passwordCode", {
        email: this.user.email,
      });
      if (result && result.code && result.user) {
        this.error = "";
        this.passwordCode = result.code;
        this.userId = result.user;
        this.codeSend = true;
      } else {
        this.error = "Email invalide.";
      }
    },
    async resetPassword() {
      if (!this.passwordCode || !this.userId) return false;
      if (!this.user.code || !this.user.password || !this.user.repassword) {
        return false;
      }
      if (this.user.code !== this.passwordCode) {
        this.error = "Le code de vérification ne correspond pas.";
        return false;
      }
      if (this.user.password !== this.user.repassword) {
        this.error = "Les mots de passes ne correspondent pas.";
        return false;
      }
      await Parse.Cloud.run("passwordUser", {
        objectId: this.userId,
        password: this.user.password,
      });
      window.history.back();
    },
  },
};
</script>

<style></style>
