<template>
  <div>
    <div
      class="is-main-content mon-profil"
      v-if="userCopy && groupCopy"
      style="padding: 5% 0 0 0 !important"
    >
      <div class="is-fullheight accueil">
        <div class="container">
          <div class="columns">
            <div
              class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen container is-fluid"
            >
              <div class="header">
                <div class="header-left">
                  <h1 class="title is-size-4">
                    {{ $t("profil") }}
                  </h1>
                  <div
                    class="columns is-multiline columns-profil is-margin-b-50"
                  >
                    <!-- <div v-if="!passwordDisable" class="column is-4"></div> -->

                    <div class="column is-4">
                      <p class="is-padding-l-10">{{ $t("nom") }}</p>
                    </div>
                    <div class="column is-4">
                      <b-input
                        name="nom"
                        v-model="userCopy.lastname"
                        expanded
                        size="is-small"
                      ></b-input>
                    </div>
                    <div class="column is-4"></div>
                    <div class="column is-4">
                      <p class="is-padding-l-10">{{ $t("prenom") }}</p>
                    </div>
                    <div class="column is-4">
                      <b-input
                        name="prenom"
                        v-model="userCopy.firstname"
                        expanded
                        size="is-small"
                      ></b-input>
                    </div>
                    <div class="column is-4"></div>
                    <div class="column is-4">
                      <p class="is-padding-l-10">{{ $t("profession") }}</p>
                    </div>
                    <div class="column is-4">
                      <b-input
                        name="profession"
                        v-model="userCopy.profession"
                        expanded
                        size="is-small"
                      ></b-input>
                    </div>
                    <div class="column is-4"></div>
                    <div class="column is-4">
                      <p class="is-padding-l-10">
                        {{ $t("telephoneProfessionnel") }}
                      </p>
                    </div>
                    <div class="column is-4">
                      <b-input
                        name="telephone"
                        v-model="userCopy.telephone_pro"
                        expanded
                        size="is-small"
                      ></b-input>
                    </div>
                    <div class="column is-4 is-margin-b-50"></div>
                  </div>
                  <h1 class="title is-size-4">
                    {{ $t("mesInformationsDeConnexion") }}
                  </h1>
                  <div
                    class="columns is-multiline columns-profil is-margin-b-50"
                  >
                    <div class="column is-4">
                      <p class="is-padding-l-10">
                        {{ $t("identifiant") }} / {{ $t("email") }}
                      </p>
                    </div>
                    <div class="column is-4">
                      <b-input
                        :disabled="emailDisable"
                        name="email"
                        expanded
                        size="is-small"
                        id="copy"
                        v-model="mailUser"
                        @focus="doNotCopyPast('copy')"
                      ></b-input>
                    </div>
                    <div class="column is-4">
                      <a
                        v-if="emailDisable"
                        class="button is-small is-cyan is-outlined"
                        @click="emailDisable = false"
                      >
                        {{ $t("modifierMonEmail") }}
                      </a>
                    </div>
                    <div v-if="!emailDisable" class="column is-4">
                      <p class="is-padding-l-10">{{ $t("recopierEmail") }}</p>
                    </div>
                    <div v-if="!emailDisable" class="column is-4">
                      <b-field
                        :type="{
                          'is-danger':
                            (!mailCopy && error) ||
                            errorSameMail ||
                            errorEmailAlreadyExist,
                        }"
                        :message="{
                          'Veuillez recopier votre mail': !mailCopy && error,
                          'Il faut que votre adresse email soit identique à la précédente':
                            errorSameMail && error,
                          'L\'adresse email existe déjà':
                            errorEmailAlreadyExist,
                        }"
                      >
                        <b-input
                          name="recopieremail"
                          expanded
                          size="is-small"
                          id="past"
                          v-model="mailCopy"
                          @focus="doNotCopyPast('past')"
                        ></b-input>
                      </b-field>
                    </div>
                    <div v-if="!emailDisable" class="column is-4"></div>
                    <div class="column is-4">
                      <p class="is-padding-l-10">{{ $t("motDePasse") }}</p>
                    </div>
                    <div class="column is-4">
                      <b-input
                        :disabled="passwordDisable"
                        type="password"
                        name="password"
                        expanded
                        size="is-small"
                        id="copy"
                        v-model="passwordUser"
                        @focus="doNotCopyPast('copy')"
                      ></b-input>
                    </div>
                    <div class="column is-4">
                      <a
                        v-if="passwordDisable"
                        class="button is-small is-cyan is-outlined"
                        @click="passwordDisable = false"
                      >
                        {{ $t("modifierMotDePasse") }}
                      </a>
                    </div>
                    <div v-if="!passwordDisable" class="column is-4">
                      <p class="is-padding-l-10">
                        {{ $t("recopierMotDePasse") }}
                      </p>
                    </div>
                    <div v-if="!passwordDisable" class="column is-4">
                      <b-field
                        :type="{
                          'is-danger':
                            (!passwordCopy && error) || errorSamePassword,
                        }"
                        :message="{
                          'Veuillez recopier votre mail professionel':
                            !passwordCopy && error,
                          'Il faut que votre adresse email soit identique à la précédente':
                            errorSamePassword && error,
                        }"
                      >
                        <b-input
                          name="recopierpassword"
                          type="password"
                          expanded
                          size="is-small"
                          id="past"
                          v-model="passwordCopy"
                          @focus="doNotCopyPast('past')"
                        ></b-input>
                      </b-field>
                    </div>
                    <a
                      v-if="!passwordDisable"
                      class="button is-small is-cyan is-outlined"
                      @click="passwordDisable = true"
                    >
                      {{ $t("modifierMotDePasse") }}
                    </a>
                  </div>
                </div>
                <div class="header-right">
                  <div class="box has-text-centered box-information box-offre">
                    <avatar
                      class="center-block"
                      v-if="user && user.photo"
                      :src="user.photo.url"
                    ></avatar>
                    <avatar
                      class="center-block"
                      v-else-if="user && !user.photo"
                      :username="user.firstname + ' ' + user.lastname"
                    >
                    </avatar>
                    <br />
                    <p class="is-size-7">{{ user.email }}</p>
                    <p class="is-size-7 is-margin-b-10">
                      {{ $t("inscriptDepuis") }} :
                      <span class="is-size-6">
                        {{ new Date(user.createdAt).toLocaleDateString() }}
                      </span>
                    </p>
                    <a v-on:click="verifToken()"
                      class="button is-cyan buttonRadius is-margin-b-10"
                      :href="urlFront"
                    >
                      {{ $t("monEspaceActivite") }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="is-margin-b-20 is-margin-t-20">
                <b-checkbox v-model="userCopy.cgu">
                  {{ $t("cgu2") }} (<a
                    href="https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgu") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf"
                    target="_blank"
                  >
                    {{ $t("cgu2_rgpd") }}</a
                  >)
                </b-checkbox>
                <div
                  class="is-size-6 has-text-danger"
                  v-if="errorCgu && !userCopy.cgu"
                >
                  {{ $t("erreurCgu") }}
                </div>
              </div>

              <div class="card is-margin-b-50 card-profil">
                <header class="card-header card-header-cyan">
                  <p class="has-text-white">
                    {{ $t("adresseFacturation") }}
                  </p>
                </header>
                <div
                  class="columns is-multiline is-margin-r-10 is-margin-t-10 is-margin-l-10 columns-profil"
                >
                  <div class="column is-4">
                    <p>{{ $t("statut") }} / {{ $t("entreprise") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="adresse_statut"
                      v-model="userCopy.statut"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("Adresse Professionelle") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="adresse"
                      v-model="userCopy.adresse"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("adresseComplementaire") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="complement_adresse"
                      v-model="userCopy.complement_adresse"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("ville") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="nom"
                      v-model="userCopy.ville"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("codePostal") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="code_postal"
                      v-model="userCopy.code_postal"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("pays") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="pays"
                      v-model="userCopy.pays"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("region") }} / {{ $t("departement") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="departement"
                      v-model="userCopy.departement"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4" style="padding-bottom: 20px">
                    <p>{{ $t("numeroTva") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="tva"
                      v-model="groupCopy.numero_tva"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                </div>
              </div>
              <div class="card card-profil is-margin-b-20">
                <header class="card-header card-header-cyan">
                  <p class="has-text-white">
                    {{ $t("adresseDeLivraison") }}
                  </p>
                </header>
                <div
                  class="columns is-multiline is-margin-r-10 is-margin-t-10 is-margin-l-10 columns-profil"
                  v-if="adresseLivraisonUpdate"
                >
                  <div class="column is-12">
                    <a
                      class="button is-small is-cyan is-pulled-right"
                      @click="copyAddress()"
                    >
                      {{ $t("copierAdresse") }}
                    </a>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("nom") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="nom"
                      v-model="groupCopy.adresse_livraison_nom"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("prenom") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="prenom"
                      v-model="groupCopy.adresse_livraison_prenom"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("statut") }} / {{ $t("entreprise") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="statut"
                      v-model="groupCopy.adresse_livraison_statut"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("Adresse Professionnelle") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="adresse"
                      v-model="groupCopy.adresse_livraison"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("adresseComplementaire") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="complement_adresse"
                      v-model="groupCopy.adresse_livraison_complement"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("ville") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="ville"
                      v-model="groupCopy.adresse_livraison_ville"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("codePostal") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="code_postal"
                      v-model="groupCopy.adresse_livraison_code_postal"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("pays") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="pays"
                      v-model="groupCopy.adresse_livraison_pays"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("region") }} / {{ $t("departement") }}</p>
                  </div>
                  <div class="column is-8">
                    <b-input
                      name="adresse_departement"
                      v-model="groupCopy.adresse_livraison_departement"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <p>{{ $t("telephone") }}</p>
                  </div>
                  <div class="column is-8" style="padding-bottom: 20px">
                    <b-input
                      name="telephone"
                      v-model="groupCopy.adresse_livraison_telephone"
                      expanded
                      size="is-small"
                    ></b-input>
                  </div>
                </div>
              </div>
              <br class="is-margin-b-50" />
              <div
                class="navbar is-fixed-bottom"
                style="
                  position: float;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  border-top: 5px;
                  margin-left: 0px;
                  background-color: #6753be;
                "
              >
                <div class="navbar-end">
                  <a
                    class="button-mon-profil button is-grey is-margin-r-20"
                    @click="back()"
                  >
                    {{ $t("retour") }}
                  </a>
                  <a
                    class="button-mon-profil button is-cyan is-margin-r-20"
                    @click="save()"
                  >
                    {{ $t("mettreAJourProfil") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { getObjectById } from "@/services/parse/getObject.js";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import router from "@/router";
import getDateRenouvellement from "@/services/abonnement/getDateRenouvellement.js";
import moment from "moment";
import Avatar from "vue-avatar";
import { URL_FRONT } from "@/configuration/urlConf.js";
import { verificationToken } from "@/services/token/verificationToken.js";

const User = createNamespacedHelpers("user");

export default {
  name: "mon-profil",
  components: {
    avatar: Avatar,
  },
  data() {
    return {
      refresh: false,
      emailDisable: true,
      passwordDisable: true,
      mailCopy: null,
      mailUser: null,
      passwordCopy: null,
      passwordUser: null,
      errorSamePassword: false,
      errorSameMail: false,
      error: false,
      userCopy: null,
      groupCopy: null,
      dateRenouvellement: null,
      nbUsersInMyGroupWithActiveAccess: 0,
      urlFront: URL_FRONT,
      adresseLivraisonUpdate: true,
      errorEmailAlreadyExist: false,
      errorCgu: false,
    };
  },
  computed: {
    ...User.mapGetters(["group", "abonnement", "user"]),
  },
  methods: {
    ...User.mapActions({
      actionsUpdateUserGroup: "updateUserGroup",
      actionsLogOut: "logOut",
      actionsUpdateUser: "updateUser",
      actionsLogIn: "logIn",
      actionsCgu: "updateCgu",
    }),
    doNotCopyPast(inputId) {
      const myInput = document.getElementById(inputId);
      myInput.onpaste = function (e) {
        e.preventDefault();
      };
      myInput.oncopy = function (e) {
        e.preventDefault();
      };
    },
    async goToActivites() {
      await this.actionsLogOut();
      window.location.href = this.urlFront;
    },
    copyAddress() {
      this.adresseLivraisonUpdate = false;
      this.groupCopy.adresse_livraison_nom = this.userCopy.lastname;
      this.groupCopy.adresse_livraison_prenom = this.userCopy.firstname;
      this.groupCopy.adresse_livraison_statut = this.userCopy.adresse_statut;
      this.groupCopy.adresse_livraison = this.userCopy.adresse;
      this.groupCopy.adresse_livraison_complement =
        this.userCopy.complement_adresse;
      this.groupCopy.adresse_livraison_ville = this.userCopy.ville;
      this.groupCopy.adresse_livraison_code_postal = this.userCopy.code_postal;
      this.groupCopy.adresse_livraison_pays = this.userCopy.adresse_pays;
      this.groupCopy.adresse_livraison_departement =
        this.userCopy.adresse_departement;
      this.groupCopy.adresse_livraison_telephone = this.userCopy.telephone_pro;
      this.adresseLivraisonUpdate = true;
    },
    async save() {
      this.errorEmailAlreadyExist = false;
      this.errorCgu = false;
      ParseConfig();
      if (!this.userCopy.cgu) {
        this.errorCgu = true;
        this.$buefy.toast.open({
          duration: 5000,
          message:
            "Vous devez confirmer les conditions générales d'utilisation afin d'utiliser la plateforme.",
          type: "is-danger",
        });
        return;
      }
      if (!this.emailDisable && this.errorSameMail) {
        this.error = true;
        return;
      }
      if (!this.passwordDisable && this.errorSamePassword) {
        this.error = true;
        return;
      }
      const ResponseUser = await getObjectById("User", this.userCopy.objectId);
      const ResponseGroup = await getObjectById(
        "user_group",
        this.groupCopy.objectId
      );

      if (!this.emailDisable) {
        const UserQuery = new Parse.Query(Parse.User);
        UserQuery.equalTo("username", this.userCopy.email);
        const userAlreadyExist = await UserQuery.first();
        if (userAlreadyExist) {
          this.errorEmailAlreadyExist = true;
          return;
        }
        ResponseUser.set("username", this.userCopy.email);
        ResponseUser.set("email", this.userCopy.email);
      }

      ResponseUser.set("lastname", this.userCopy.lastname);
      ResponseUser.set("firstname", this.userCopy.firstname);
      ResponseUser.set("profession", this.userCopy.profession);
      ResponseUser.set("telephone_pro", this.userCopy.telephone_pro);
      ResponseUser.set("statut", this.userCopy.statut);
      ResponseUser.set("adresse", this.userCopy.adresse);
      ResponseUser.set("complement_adresse", this.userCopy.complement_adresse);
      ResponseUser.set("ville", this.userCopy.ville);
      ResponseUser.set("code_postal", this.userCopy.code_postal);
      ResponseUser.set("pays", this.userCopy.pays);
      ResponseUser.set("departement", this.userCopy.departement);
      ResponseUser.set("cgu", this.userCopy.cgu);
      this.actionsCgu();

      ResponseGroup.set(
        "adresse_livraison_nom",
        this.groupCopy.adresse_livraison_nom
      );
      ResponseGroup.set(
        "adresse_livraison_prenom",
        this.groupCopy.adresse_livraison_prenom
      );
      ResponseGroup.set(
        "adresse_livraison_statut",
        this.groupCopy.adresse_livraison_statut
      );
      ResponseGroup.set("adresse_livraison", this.groupCopy.adresse_livraison);
      ResponseGroup.set(
        "adresse_livraison_complement",
        this.groupCopy.adresse_livraison_complement
      );
      ResponseGroup.set(
        "adresse_livraison_ville",
        this.groupCopy.adresse_livraison_ville
      );
      ResponseGroup.set(
        "adresse_livraison_code_postal",
        this.groupCopy.adresse_livraison_code_postal
      );
      ResponseGroup.set(
        "adresse_livraison_pays",
        this.groupCopy.adresse_livraison_pays
      );
      ResponseGroup.set(
        "adresse_livraison_departement",
        this.groupCopy.adresse_livraison_departement
      );
      ResponseGroup.set(
        "adresse_livraison_telephone",
        this.groupCopy.adresse_livraison_telephone
      );
      ResponseGroup.set("numero_tva", this.groupCopy.numero_tva);

      Parse.masterKey = "myMasterKey";
      await ResponseUser.save({}, { useMasterKey: true });
      await this.actionsUpdateUser();
      await ResponseGroup.save();
      await this.actionsUpdateUserGroup();
      if (!this.passwordDisable) {
        const currentUser = await getObjectById("User", this.userCopy.objectId);
        currentUser.setPassword(this.userCopy.password);
        await currentUser.save();
        var userCourant = await Parse.User.current();
        Parse.Cloud.run("passwordTherapeute", {
          destinataire: this.userCopy.email,
          utilisateur:
            userCourant.attributes.firstname +
            " " +
            userCourant.attributes.lastname,
          password: this.userCopy.password,
        });
        await Parse.User.logOut();
        await Parse.User.logIn(this.userCopy.email, this.userCopy.password);
      }
      this.refresh = true;
    },
    back() {
      router.push("/accueil");
    },
    getDateRenouvellement() {
      this.dateRenouvellement = getDateRenouvellement(this.group);
    },
    async getMyUsers() {
      ParseConfig();
      this.nbUsersInMyGroupWithActiveAccess = 0;
      const UserGroupObject = Parse.Object.extend("user_group");
      const UserGroupTemporary = new UserGroupObject();
      UserGroupTemporary.id = this.group.objectId;
      const UserObject = Parse.Object.extend("User");
      const UserQuery = new Parse.Query(UserObject);
      UserQuery.equalTo("group", UserGroupTemporary);
      const UsersInMyGroup = await UserQuery.find();
      UsersInMyGroup.forEach((user) => {
        if (
          moment().toDate() <
            moment(user.attributes.fin_acces_plateforme).toDate() &&
          user.attributes.fin_acces_plateforme
        ) {
          this.nbUsersInMyGroupWithActiveAccess++;
        }
      });
    },
    goToMonEspace() {
      window.location.href = URL_FRONT;
    },
  },
  async mounted() {
    this.userCopy = JSON.parse(JSON.stringify(this.user));
    this.groupCopy = JSON.parse(JSON.stringify(this.group));
    await this.getMyUsers();
    this.getDateRenouvellement();
    this.mailUser = this.userCopy.email;
  },
  watch: {
    refresh() {
      if (this.refresh) {
        window.location.reload(); // refresh page
      }
    },
    mailCopy() {
      this.errorSameMail = false;
      if (this.mailCopy !== this.userCopy.email) {
        this.errorSameMail = true;
      }
    },
    mailUser() {
      this.userCopy.email = this.mailUser;
      this.errorSameMail = false;
      if (this.mailCopy !== this.userCopy.email) {
        this.errorSameMail = true;
      }
    },
    passwordCopy() {
      this.errorSamePassword = false;
      if (this.passwordCopy !== this.userCopy.password) {
        this.errorSamePassword = true;
      }
    },
    passwordUser() {
      this.userCopy.password = this.passwordUser;
      this.errorSamePassword = false;
      if (this.passwordCopy !== this.userCopy.password) {
        this.errorSamePassword = true;
      }
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
}

.navbar-end
{
  margin-left: auto;
  margin-right: auto;
}
</style>
