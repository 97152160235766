<template>
  <div>
    <div class="Container">
      <div class="GlobalDivChart">
        <canvas id="dynamicChart"></canvas>
      </div>
      <div class="button-container containerLabelWindow">
        <button @click="decreaseWindow">-</button>
        <span>{{ maxLabels + ' secondes' }}</span>

        <button @click="increaseWindow">+</button>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";

export default {
  name: "DynamicLineChart",
  data() {
    return {
      chart: null,
      counter: 0, // Compteur pour générer des étiquettes de temps
      maxLabels: 60,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Indice de lâcher-prise moyenné",
            // backgroundColor: "rgba(75, 192, 192, 0.5)",
            borderColor: "rgba(75, 192, 192, 0.8)",
            borderWidth: 6,
            pointRadius: 0, // Définissez à zéro pour retirer les points
            fill: false,
            lineTension: 0.1,
            data: [],
          },
          {
            label: "Indice de lâcher-prise instantané",
            // backgroundColor: "rgba(255, 159, 64, 0.1)",
            borderColor: "rgba(255, 159, 64, 0.4)",
            pointRadius: 0, // Définissez à zéro pour retirer les points
            borderWidth: 4,
            opacity: 0.7,
            fill: false,
            lineTension: 0.1,
            data: [],
          },
        ],
      },
      options: {
        animation: {
          duration: 500,
          easing: "easeInOutExpo",
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                color: "rgba(200, 200, 200, 0.2)",
              },
              scaleLabel: {
                display: true,
                labelString: "Valeur",
              },
              ticks: {
                beginAtZero: true,
                max: 150,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                color: "rgba(200, 200, 200, 0.2)",
              },
              scaleLabel: {
                display: true,
                labelString: "Temps (s)",
              },
            },
          ],
        },
        legend: {
          display: true,
          position: "top",
          labels: {
            fontColor: "#333",
            fontSize: 12,
          },
        },
        title: {
          display: true,
          text: "Indice de lâcher-prise en temps réel",
          fontSize: 16,
          fontColor: "#333",
        },
      },
    };
  },
  props: {
    value1: {
      type: Number,
      required: true,
    },
    value2: {
      type: Number,
      required: true,
    },
    lastBPM: {
      type: Number,
      required: true,
    },
    updateInterval: {
      type: Number,
      default: 1000, // Mise à jour toutes les 1000 ms (1 seconde)
    },
    widthSize: {
      type: Number,
      default: 1200,
    },
  },
  watch: {
    // value1(newVal) {
    //   this.addDataToChart(0, newVal);
    // },
    // value2(newVal) {
    //    this.addDataToChart(1, newVal);
    //  }
  },
  mounted() {
    this.initChart();
    // this.updateChartData();
    this.startAutoUpdate();
  },
  methods: {
    initChart() {
      const ctx = document.getElementById("dynamicChart").getContext("2d");
      this.chart = new Chart(ctx, {
        type: "line",
        data: this.chartData,
        options: this.options,
      });
    },
    startAutoUpdate() {
      this.intervalId = setInterval(() => {
        if (this.value1 && this.value2) {
          // Mettez à jour les deux datasets en une seule opération.
          this.addDataToChart({ 0: this.value1, 1: this.value2 });
        }
        console.log("value1:", this.value1, "value2:", this.value2);
      }, this.updateInterval);
    },

    addDataToChart(values) {
      let label =
        this.counter < 60
          ? `${this.counter}s`
          : `${Math.floor(this.counter / 60)}:${(this.counter % 60)
              .toString()
              .padStart(2, "0")}`;
      this.counter++;
      // Assurez-vous que les labels ne dépassent pas un certain nombre.
      if (this.chartData.labels.length > this.maxLabels) {
        this.chartData.labels.shift();
        this.chartData.datasets.forEach((dataset) => dataset.data.shift());
      }

      // Ajoutez le nouveau label
      this.chartData.labels.push(label);

      // Mise à jour des datasets avec les nouvelles valeurs.
      // values est supposé être un objet avec des clés correspondant aux indices des datasets.
      Object.keys(values).forEach((index) => {
        const value = values[index];
        if (this.chartData.datasets[index]) {
          this.chartData.datasets[index].data.push(value);
        }
      });

      this.chart.update();
    },
    increaseWindow() {
      this.maxLabels += 10; // Augmente la fenêtre de temps
      this.updateWindow(); // Met à jour le graphique après l'ajustement
    },
    decreaseWindow() {
      if (this.maxLabels > 10) this.maxLabels -= 10; // Diminue la fenêtre de temps
      this.updateWindow(); // Met à jour le graphique après l'ajustement
    },
    updateWindow() {
      // Cette méthode sera appelée à chaque changement du slider
      while (this.chartData.labels.length > this.maxLabels) {
        this.chartData.labels.shift();
        this.chartData.datasets.forEach((dataset) => dataset.data.shift());
      }
      this.chart.update();
    },
  },
};
</script>

<style scoped>
.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px;  */
  position: relative;
}
#dynamicChart {
  width: 1200px;; /* Utilisez 100% de la largeur de l'élément conteneur */
  height: 400px; /* Hauteur fixe, ajustez selon les besoins */
  /* max-width: 150rem; Maximum width */
}
@media (max-width: 1300px) {
  #dynamicChart {
    width: 100%; /* Utilisez 100% de la largeur de l'écran sur les petits dispositifs */
    height: 300px; /* Ajustez la hauteur pour les petits écrans si nécessaire */
  }
}

@media (max-width: 768px) {
  #dynamicChart {
    width: 100%; /* Utilisez 100% de la largeur de l'écran sur les petits dispositifs */
    height: 300px; /* Ajustez la hauteur pour les petits écrans si nécessaire */
  }
}

.GlobalDivChart {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 75px;
  padding-right: 75px;
  margin-right: 50px;
  width: 100%;
}
@media (max-width: 1300px) {
  .GlobalDivChart {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
  }
}
.circle {
  width: 150px;
  height: 150px;
  color: #489181;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  background: linear-gradient(145deg, #e6fff5, #d4f7ec);
  border: 6px solid #489181;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  cursor: pointer;
  animation: heartbeat 15s infinite; /* Applique l'animation de battement */
}

.circle:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-color: #417f6b;
}

.circle small {
  font-size: 0.8rem;
  margin-top: 5px;
  color: #489181;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.circle:hover small {
  opacity: 1;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.InformationDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #333;
  transition: all 0.2s ease-in-out;
}

.InformationDiv:hover {
  background-color: #f5f5f5;
}

.InformationDiv small {
  margin-left: 5px;
  color: #777;
}

.InformationDiv .value2 {
  font-size: 24px;
  color: #444;
}

.InformationDiv .icon {
  width: 20px;
  height: 20px;
  fill: #777;
}

@media (max-width: 768px) {
  .InformationDiv {
    flex-direction: column;
    align-items: flex-start;
  }

  .InformationDiv .value2 {
    font-size: 18px;
  }

  .InformationDiv .icon {
    display: none;
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

button {
  background-color: rgba(33, 150, 243, 0.8); /* Couleur verte */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  background-color: rgba(33, 150, 243, 0.8); /* Un peu plus foncé */
  transform: scale(1.1); /* Légère augmentation de la taille */
}

button:active {
  transform: scale(0.9); /* Effet de clic */
}

span {
  font-size: 16px;
  color: #333; /* Gris foncé */
  font-weight: bold;
}

.containerLabelWindow {
  position: absolute;
  top: 0;
  left: 150px;
}
</style>
