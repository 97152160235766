<template lang="html">
    <div
    >
      <div class="is-fullheight accueil">
        <div>
          <div style="padding: 0rem" class="overflow2">
            <folders-component
              :idPatientTherapeute="idPatientTherapeute"
              style="padding: 0rem"
              :foldersBrique="foldersBrique"
              :themes="themes"
              :group="group"
              v-on:pop-up-contact="popUpContact"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ParseConfig from "@/api/parse.config.js";
  import Parse from "parse";
  import { Chrome } from "vue-color/";
  import { createNamespacedHelpers } from "vuex";
  import cloneDeep from "lodash/cloneDeep";
  import foldersComponent from "../EchellesFolders2.vue";
  import { URL_FRONT } from "@/configuration/urlConf.js";
  const User = createNamespacedHelpers("user");
  
  export default {
    name: "mes-activites",
    components: {
      "chrome-picker": Chrome,
      FoldersComponent: foldersComponent,
    },
    props: {
      idPatientTherapeute: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        color: { hex: "#4579B2" },
        editorOption: {
          placeholder: "",
          theme: "snow",
        },
        file: null,
        urlFront: URL_FRONT,
        textureSelectionnee: {},
        userGroup: {},
        error: false,
        foldersBrique: [],
        themes: [],
        foldersProtocolLoad: false,
        linksToAdd: [],
        hasImage: false,
        image2: null,
        isCardAjoutTexture: false,
        isCardAjoutBackground: false,
        loaderFolderActive: false,
        isStepFolder: false,
        num: 0,
      };
    },
    computed: {
      ...User.mapGetters(["group", "user", "folders"]),
    },
    methods: {
      async popUpContact() {
        console.log("pop-up-contact 1");
        this.$emit("pop-up-contact");
      },
    },
    async mounted() {
      this.loaderFolderActive = true;
      if (this.folders === null) await this.actionsLoadFolder();
  
      this.foldersBrique = cloneDeep(
        this.folders.filter((e) => e.domaine == "Santé")
      );
  
      const Domaine = Parse.Object.extend("domaine");
      const queryDomaine = new Parse.Query(Domaine);
      queryDomaine.equalTo("nom", "Santé");
      const domaineSante = await queryDomaine.first();
      const oTheme = Parse.Object.extend("theme");
      const queryTheme = new Parse.Query(oTheme);
      queryTheme.equalTo("domaine", domaineSante);
      queryTheme.ascending("ordre");
      this.themes = await queryTheme.find();
      this.foldersProtocolLoad = true;
      this.loaderFolderActive = false;
  
      this.error = false;
      this.bgColor = this.group.couleur;
    },
  };
  </script>
  
  <style>
  .is-main-content {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  
  .thisproto-lg {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 540px) {
    .thisproto-lg {
      height: 120px !important;
      width: 360px !important;
    }
  }
  @media screen and (max-width: 585px) and (min-width: 540px) {
    .thisproto-lg {
      height: 120px !important;
      width: 185px !important;
    }
  }
  @media screen and (max-width: 720px) and (min-width: 585px) {
    .thisproto-lg {
      height: 120px !important;
      width: 200px !important;
    }
  }
  @media screen and (max-width: 768px) and (min-width: 720px) {
    .thisproto-lg {
      height: 120px !important;
      width: 260px !important;
    }
  }
  @media screen and (min-width: 768px) {
    .thisproto-lg {
      height: 200px !important;
      width: 303px !important;
    }
  }
  /* .ResponsiveBody {
    min-height: 60vh;
  }
  @media (min-width: 1800px) {
    .ResponsiveBody {
      min-height: 70vh;
    }
  } */
  </style>
  