<template>
<div>
  <div class="subscribe-banner">
    <div class="banner-content">
      <span class="banner-text">Profitez d'un essai gratuit de 15 jours pour découvrir nos outils dédiés à votre pratique !</span>
      <button class="banner-button" v-on:click="goToSuscribe">Je profite !</button>
    </div>
  </div>
  <div class="main-connexion">
    <div class="connexion-content">
      <div class="column is-4 is-offset-4 is-offset-2-mobile is-8-mobile">
        <a
          class="card-content is-flex is-horizontal-center"
          a
          href="https://symbiocenter.fr/"
          target="_blank"
        >
          <img :src="logo.src" alt="Logo Symbiofi"  lazy="loading" />
        </a>
      </div>
      <div class="">
        <div class="has-text-centered"></div>
        <div
          class="column is-offset-4-desktop is-three-fifths-tablet is-offset-one-fifth-tablet is-4-desktop"
          v-if="!isMaintenance"
        >
          <div class="has-text-centered is-margin-10">
            <div class="">
              <form @submit.prevent="connexion()">
                <b-field>
                  <b-input
                    type="email"
                    placeholder="Email"
                    v-model="email"
                    size="is-large"
                  >
                  </b-input>
                </b-field>
                <b-field>
                  <b-input
                    type="password"
                    placeholder="Mot de passe"
                    icon-pack="fas"
                    v-model="password"
                    password-reveal
                    size="is-large"
                  >
                  </b-input>
                </b-field>
                <div>
                  <p class="has-text-weight-light has-text-danger is-size-6"
                    v-if="errorMail"
                  >
                    Votre email est incorrect, veuillez réessayer
                  </p>
                </div>
                <div>
                  <p
                    class="has-text-weight-light has-text-danger is-size-6"
                    v-if="error && check"
                  >
                    {{ error }}
                  </p>
                </div>
                <button
                  type="submit"
                  class="button is-primary is-fullwidth is-large"
                >
                  {{ $t("CONNEXION") }}
                </button>
              </form>
            </div>
            <!-- <div style="margin-top: 3%">
              <button
                class="button is-primary is-fullwidth is-large"
                v-on:click="wantSubscribe = !wantSubscribe"
              >
                {{ $t("Pas encore inscrit") }}
              </button>
            </div>
            <div style="margin-top: 2%">
              <b-switch v-model="isPro" v-if="wantSubscribe">
                <span> {{ "Je certifie être un professionnel" }} </span>
              </b-switch>
            </div> -->
            <p style="margin-top: 10px">
              <router-link :to="'/reinitialisation-mot-de-passe'">
                {{ $t("motDePasseOublie") }}
              </router-link>
            </p>
          </div>
        </div>
        <!--Maintenance-->
        <div v-else>
          <div class="column is-6 is-offset-3 has-text-centered">
            <div class="box">
              <p>NOTRE SITE EST EN MAINTENANCE POUR LE MOMENT</p>
              <p>
                mais nous mettons tout en oeuvre pour que vous le retrouviez
                très vite.
              </p>
              <p class="title has-text-grey is-size-6 has-text-centered">
                {{ $t("") }}
              </p>
            </div>
          </div>
        </div>

        <!---->
      </div>
    </div>
    <div class="footerM">
      <div class="footer-connexion is-fullwidth">
        <a
          class="button is-blue is-margin-10"
          href="https://www.symbiocenter.com/assets/Mentions_legales.pdf"
          target="_blank"
          ><u>Mentions légales</u></a
        >
        <a
          class="button is-blue is-margin-10"
          href="https://www.symbiofi.com"
          target="_blank"
          ><u>Centre de formation</u></a
        >
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";

const User = createNamespacedHelpers("user");

export default {
  name: "login",
  data() {
    return {
      isMaintenance: false,
      email: "",
      password: "",
      check: false,
      avecRedirectionUser: false,
      isPro: false,
      wantSubscribe: false,
      errorMail: false,
      logo: {
        src: require("../../../assets/img/logo_symbiofi.png"),
      },
    };
  },
  computed: {
    ...User.mapGetters(["error", "user"]),
  },
  methods: {
    ...User.mapActions({
      actionsLogIn: "logIn",
    }),
    async connexion() {
      ParseConfig();
      const loadingComponent = this.$buefy.loading.open();
      try {
        var userQuery = Parse.Object.extend("User");
        var query = new Parse.Query(userQuery);
        query.equalTo("email", this.email);
        const user = await query.first();
        if(user)
        {
        await this.actionsLogIn({
          email: this.email,
          password: this.password,
          avecRedirectionUser: this.avecRedirectionUser,
        });
        loadingComponent.close();
          this.errorMail = false;
        }
        else
        {
          this.errorMail = true;
          loadingComponent.close();
        }

        // location.reload();
      } catch(error) {
        //console.log(error);
        // loadingComponent.close();
      }
      this.check = true;
    },
    goToSuscribe() {
      this.$router.push("/inscription-credits");
    },
  },
  mounted() {
    if (
      this.$route.query !== undefined &&
      this.$route.query.utilisateur !== undefined &&
      this.$route.query.utilisateur
    )
      this.avecRedirectionUser = true;
  },
  watch: {
    isPro(newValue) {
      if (newValue) {
        // navigate to the regular registration page
        this.$router.push("/inscription-credits");
      }
    },
  },
};
</script>

<style>
html {
  background-color: #3c3c3c;
  overflow-y: auto;
}

.boutonInscription {
  color: #fff;
}

.boutonInscription:hover {
  color: #fff;
}

.containers {
  min-height: 60vh;
  margin-bottom: -50px;
  padding-bottom: 50px;
}

.footerM {
  position: absolute;
  bottom: 0;
  width: 100%;
}

html,
body {
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app {
  flex: 1;
  height: 100%;
}
/* Style de la banderole */
.subscribe-banner {
  background-color: #239380;
  color: white;
  text-align: center;
  padding: 15px 0;
  font-size: 18px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.banner-content {
  display: flex;
  flex-direction: row;  /* Par défaut, mais bon de préciser */
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Permet aux éléments de passer à la ligne en dessous si nécessaire */
}

.banner-text {
  margin: 0 20px;
  font-weight: 600;
  text-align: center; /* Pour que le texte soit centré même quand il passe à la ligne */
}

.banner-button {
  background-color: white;
  color: #239380;
  padding: 10px 20px;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease;
}

.banner-button:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

/* Media queries pour la responsivité */
@media (max-width: 768px) {
  .banner-content {
    flex-direction: column; /* Les éléments s'empilent verticalement sur les petits écrans */
    align-items: center; /* Centre les éléments verticalement */
  }
  
  .banner-text {
    margin: 10px 0; /* Augmente l'espacement en haut et en bas pour la respiration visuelle */
  }

  .banner-button {
    margin: 10px 0; /* Augmente l'espacement en haut et en bas pour la respiration visuelle */
  }
}
</style>
