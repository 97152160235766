<template>
  <div class="user-details">
    <button
      v-if="selectedUser.typePratique === 'cabinet'"
      @click="setToDistanciel()"
      class="button-to-distanciel"
    >
      <i class="fas fa-circle color-adistance"></i> Passer l'utilisateur en
      distanciel <i class="fas fa-arrow-right"></i>
    </button>
    <button
      v-else-if="selectedUser.typePratique === 'licence'"
      @click="setToDistanciel()"
      class="button-to-distanciel"
    >
      <i class="fas fa-circle color-enseance"></i> Passer l'utilisateur en
      séance <i class="fas fa-arrow-right"></i>
    </button>
    <button @click="deleteUser()" class="button-delete-user-modif">
      Supprimer l'utilisateur
    </button>

    <h2 class="titleModificationUser">
      <i class="fas fa-user"></i> Informations sur l'utilisateur :
    </h2>
    <div class="user-basic-info">
      <b-field label="Nom" class="element">
        <b-input v-model="selectedUser.nom" icon="user"></b-input>
      </b-field>
      <b-field label="Prénom" class="element">
        <b-input v-model="selectedUser.prenom" icon="user"></b-input>
      </b-field>
      <b-field label="Email" class="element">
        <b-input v-model="selectedUser.mail" icon="envelope"></b-input>
      </b-field>
    </div>

    <div class="user-additional-info">
      <div class="info-item">
        <i class="fas fa-calendar-alt"></i>
        <p>
          <span>Date de création :</span>
          {{ formatDate(selectedUser.createdAt) }}
        </p>
      </div>
      <div class="info-item" v-if="selectedUser.typePratique === 'licence'">
        <i class="fas fa-clock"></i>
        <b-field label="Durée d'accès :">
          <b-select v-model="selectedDuration">
            <option value="1">1 mois</option>
            <option value="2">2 mois</option>
            <option value="3">3 mois</option>
            <option value="4">4 mois</option>
          </b-select>
        </b-field>
      </div>
      <div class="info-item" v-if="selectedUser.typePratique === 'licence'">
        <i class="fas fa-calendar-check"></i>
        <p>
          <span>Date de début de licence :</span>
          {{ formatDate(selectedUser.date_debut_licence) }}
        </p>
      </div>
      <div class="info-item" v-if="selectedUser.typePratique === 'licence'">
        <i class="fas fa-calendar-times"></i>
        <p>
          <span>Date de fin de licence :</span>
          {{ formatDate(selectedUser.fin_acces_plateforme) }}
        </p>
      </div>
    </div>
    <!-- <button @click="UpdateUser(selectedUser)">Update User</button> -->
  </div>
</template>
<script>
import Parse from "parse";
import Swal from "sweetalert2/dist/sweetalert2.js";
import codeGenerator from "@/services/general/codeGenerator.js";

export default {
  name: "NewModificationUtilisateur",
  data() {
    return {
      selectedDuration: null,
    };
  },
  props: {
    selectedUser: {
      required: true,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    "selectedUser.nom": function (newVal, oldVal) {
      console.log("newVal:", newVal, "oldVal:", oldVal);
      this.$emit("updateUser", this.selectedUser);
    },
    "selectedUser.prenom": function (newVal, oldVal) {
      console.log("newVal:", newVal, "oldVal:", oldVal);
      this.$emit("updateUser", this.selectedUser);
    },
    "selectedUser.mail": function (newVal, oldVal) {
      console.log("newVal:", newVal, "oldVal:", oldVal);
      this.$emit("updateUser", this.selectedUser);
    },
    selectedDuration: function (newVal, oldVal) {
      console.log("newVal:", newVal, "oldVal:", oldVal);
      this.selectedUser.dureeAcces = newVal;
      this.$emit("updateUser", this.selectedUser);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      return d.toLocaleDateString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    test() {
      Swal.fire({
        title: "Error!",
        text: "Do you want to continue",
        icon: "error",
        confirmButtonText: "Cool",
      });
    },
    async deleteUser() {
      const result = await Swal.fire({
        title: "Êtes-vous sûr de vouloir supprimer cette utilisateur?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
      });

      if (result.isConfirmed) {
        Parse.Cloud.run("deleteUser", {
          objectId: this.selectedUser.objectId,
        })
          .then((response) => {
            console.log("Successfully deleted user:", response);
            this.$emit("refreshListUser");
            Swal.fire("Supprimé !", "L'utilisateur a été supprimé.", "success");
          })
          .catch((error) => {
            console.log("Failed to delete user:", error);
            Swal.fire(
              "Erreur",
              "La suppression de l'utilisateur a échoué.",
              "error"
            );
          });
      }
    },
    isValidEmail(email) {
      // Expression régulière pour la validation d'une adresse e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async setToDistanciel() {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 9000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      if (this.selectedUser.typePratique === "cabinet") {
        const user = { mail: this.selectedUser.mail };
        console.log("Mail:", user.mail);

        // if (!this.isValidEmail(user.mail)) {
        //   console.log("Adresse e-mail non valide");
        //   return;
        // }

        const { value: email, isConfirmed: isEmailConfirmed } = await Swal.fire(
          {
            title:
              "Êtes-vous sûr de vouloir changer le statut de votre utilisateur ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui, continuer !",
            input: "email",
            inputValue: user.mail,
            inputPlaceholder: "Saisissez l'adresse-mail de l'utilisateur",
            inputValidator: (value) => {
              if (!value) {
                return "Veuillez saisir une adresse e-mail valide";
              }
            },
          }
        );

        if (!isEmailConfirmed) {
          return;
        }

        const { value: months, isConfirmed: isMonthsConfirmed } =
          await Swal.fire({
            title: "Étape 2 : Choisissez la durée d'accès",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Valider",
            input: "select",
            inputOptions: {
              1: "1 mois",
              2: "2 mois",
              3: "3 mois",
              4: "4 mois",
            },
            inputPlaceholder: "Sélectionnez la durée d'accès",
            inputValidator: (value) => {
              if (!value) {
                return "Veuillez sélectionner la durée d'accès";
              }
            },
          });

        if (!isMonthsConfirmed) {
          return;
        }

        Swal.fire("Confirmé !", "Votre action a été confirmée.", "success");

        try {
          const passwordGenerate = codeGenerator("xxxxxxxx");

          const DateDebut = new Date();
          const dureeAcces = parseInt(months);
          const dureeJour = 30;
          const dateFin = new Date();
          dateFin.setDate(dateFin.getDate() + dureeAcces * dureeJour);

          await Parse.Cloud.run("setDateDebutLicence", {
            userObjectId: this.selectedUser.objectId,
            dateDebut: DateDebut,
            dureeAcces: dureeAcces,
            dateFin: dateFin,
            email: email,
          });
          await Parse.Cloud.run("passwordUser2", {
            objectId: this.selectedUser.objectId,
            password: passwordGenerate,
          });
          this.$emit("refreshListUser");
        } catch (error) {
          console.log("error:", error);
        }
      } else {
        const result = await Swal.fire({
          title:
            "Êtes-vous sûr de vouloir changer le statut de votre utilisateur ?",
          text: "Vous ne pourrez pas revenir en arrière !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, continuer !",
        });

        if (!result.isConfirmed) {
          Toast.fire({
            icon: "error",
            title: "Le statut de l'utilisateur n'a pas été changé !",
          });
          return;
        }

        this.selectedUser.typePratique = "cabinet";
        Toast.fire({
          icon: "success",
          title: "Le statut de l'utilisateur a été changé avec succès !",
        });
        this.$emit("refreshListUser");
      }

      try {
        await Parse.Cloud.run("setToDistanciel", {
          userObjectId: this.selectedUser.objectId,
        });
        location.reload();
        console.log("Successfully updated user");
      } catch (error) {
        console.log("Failed to update user:", error);
      }
    },
  },
  created() {
    //console.log("Modification, selectedUser:", this.selectedUser);
    if (this.selectedUser.dureeAcces) {
      this.selectedDuration = this.selectedUser.dureeAcces;
    }
  },
};
</script>

<style scoped>
.user-details {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  margin-left: 50px;
  padding-top: 50px;
}
.user-basic-info {
  margin-top: 20px;
}
.user-basic-info,
.user-additional-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.element {
  flex: 1;
  margin-right: 20px;
}

.info-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 20px;
}

.info-item i {
  margin-right: 8px;
  font-size: 24px;
  color: #239380;
}

.info-item span {
  font-weight: 500;
  display: block;
}
.button-to-distanciel {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #239380;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  border: 2px solid #239380;
}

.button-to-distanciel:hover {
  background-color: #1d7f65; /* Couleur un peu plus foncée au survol */
  color: white;
}

.color-adistance {
  color: rgba(184, 61, 153, 1) !important;
}
.color-enseance {
  color: rgba(77, 160, 255, 1) !important;
}

.titleModificationUser {
  font-size: 24px;
  font-weight: 600;
}
.titleModificationUser i {
  margin-right: 10px;
  font-size: 24px;
  color: #239380;
}

/* CSS */
.button-delete-user-modif {
  position: absolute;
  top: -5px;
  background-color: #f44336;
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
  left: -50px;
  border-radius: 5px;
}

.button-delete-user-modif:hover {
  background-color: white;
  color: black;
  border: 2px solid #f44336;
}
</style>
