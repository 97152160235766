<template lang="html">
  <div>
    <div class="card" style="background-color: white">
      <header class="card-header card-header-cyan">
        <p class="has-text-white is-size-4">
          {{ $t("offrePulse") }}
        </p>
      </header>
      <div class="columns is-multiline">
        <!-- <div
          class="card-image column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen"
          style="width: 100%"
        >
          <figure
            class="image is-2by1"
            style="display: flex; padding-top: 25% !important"
          >
            <img
              style="
                max-width: 400px;
                max-height: 600px;
                margin-left: auto;
                margin-right: auto;
              "
              src="../../../../assets/img/device_symbiocenter.png"
              alt="Image abonnement pulse"
            />
          </figure>
        </div> -->

        <div class="column is-12 columns is-multiline" v-if="misAJour">
          <div
            class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen has-text-centered"
            v-if="group.type_abonnement !== 'pulse'"
          >
            <a
              style="padding: 4rem"
              class="button is-cyan is-large button-offre-abonnement"
              :class="{ isGrey: !abonnement.engagementAnnuel }"
              @click="setEngagement(true)"
            >
              <div class="columns is-multiline">
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-6">{{ $t("avecEngagementAnnuel") }}</p>
                </div>
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-4">
                    {{ (this.pulseAnnuel / 1).toFixed(2) }}€ / ttc
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen has-text-centered"
            v-if="group.type_abonnement !== 'pulse'"
          >
            <a
              style="padding: 4rem"
              class="button is-cyan is-large button-offre-abonnement"
              :class="{ isGrey: abonnement.engagementAnnuel }"
              @click="setEngagement(false)"
            >
              <div class="columns is-multiline">
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-6">{{ $t("sansEngagement") }}</p>
                </div>
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-4">
                    {{ this.pulseMensuel.toFixed(2) }}€ / ttc
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-7-desktop is-8-widescreen"
          >
            <div class="is-margin-l-20 is-size-6">
              <div
                v-if="
                  group.type_abonnement === 'pulse' &&
                  !modificationAbonnement &&
                  !group.engagement_annuel
                "
                class="has-text-centered"
              >
                <p>
                  {{ $t("evolutionAbonnement") }}
                </p>
                <br />
                <div class="columns">
                  <div
                    class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen column-mon-abonnement"
                  >
                    <p class="is-size-6">{{ $t("votreAbonnement") }}</p>
                    <br />
                    <p class="is-size-6">{{ $t("tarifMensuelDe") }}</p>
                    <p class="is-size-6">
                      {{ this.pulseMensuel.toFixed(2) }}€ ttc
                    </p>
                  </div>
                  <div
                    class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen"
                  >
                    <p class="is-size-6">
                      {{ $t("abonnementAvecEngagement") }}
                    </p>
                    <br />
                    <p>
                      {{ $t("prelevementAnnuelDe") }}
                      {{ this.pulseAnnuel.toFixed(2) }} € ttc.
                    </p>
                    <p>{{ $t("debiteEn") }} 1 {{ $t("fois") }}.</p>
                  </div>
                </div>
                <a
                  class="button is-primary"
                  @click="editInformationAbonnement()"
                >
                  {{ $t("passageAbonnementAnnuel") }}
                </a>
              </div>
              <p
                v-if="
                  group.type_abonnement === 'pulse' && modificationAbonnement
                "
              >
                {{ $t("modificationAbonnementConditionGeneral") }}
              </p>
              <div
                class="field"
                v-if="
                  abonnement.engagementAnnuel &&
                  (group.type_abonnement !== 'pulse' || modificationAbonnement)
                "
              >
                <adresseLectureComponent
                  :propsInformationAdresse="abonnement.adressePostal"
                  :error="error"
                />
                <!-- <b-checkbox
                  v-model="adresseLivraisonDifferente"
                  @input="
                    checkValue();
                    contratAccepte = false;
                  "
                >
                  {{ $t("adresseLivraisonDifferente") }}
                </b-checkbox> -->
              </div>
              <span
                class="has-text-danger"
                v-if="
                  errorAdresseUtilisateurUndefined &&
                  abonnement.engagementAnnuel &&
                  (group.type_abonnement !== 'pulse' || modificationAbonnement)
                "
              >
                {{ $t("adresseFacturationNonRenseigne") }}
              </span>
              <div
                v-if="
                  ((group.type_abonnement !== 'pulse' ||
                    modificationAbonnement) &&
                    adresseLivraisonDifferente) ||
                  !userHasBillingAdress
                "
              >
                <p v-if="group.type_abonnement === 'pulse' && modificationAbonnement" class="subtitle subtitleFacturation">
                  {{ $t("modifierInformationFacturation") }}
                </p>
                <adresseLivraisonComponent
                  v-if="abonnement.adresse && modificationAbonnement"
                  @updateAdresse="updateAdresse"
                  :propsInformationAdresse="abonnement.adresse"
                  :error="error"
                />
              </div>
              <formulaire-paiement-component
                v-if="
                  check &&
                  abonnement &&
                  abonnement.adresse &&
                  abonnement.adresse.codePostal
                "
                :transactionData2="transactionData"
                :amount="this.transactionData.amount"
                :code_societe="code_societe"
                :page="'abonnement'"
                @confirmerAbonnement="confirmerAbonnement"
              />
              <span
                class="has-text-danger"
                v-if="
                  check &&
                  !abonnement &&
                  !abonnement.adresse &&
                  !abonnement.adresse.codePostal
                "
              >
                Pour passer au paiement, veuillez remplir votre adresse.
              </span>
              <br />
              <b-field
                v-if="
                  group.type_abonnement !== 'pulse' || modificationAbonnement
                "
                :type="{ 'is-danger': !contratAccepte && errorContratAccepte }"
                :message="{
                  'Veuillez accepter le contrat afin de procéder au paiement.':
                    !contratAccepte && errorContratAccepte,
                }"
              >
                <b-checkbox
                  v-if="
                    group.type_abonnement !== 'pulse' || modificationAbonnement
                  "
                  v-model="contratAccepte"
                  @input="checkValue()"
                >
                  {{ $t("cgu") }} (<a
                    href="https://www.symbiocenter.com/assets/CGV_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgv") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/CP_Pulse_SymbioSensor.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cp") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgu") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf"
                    target="_blank"
                  >
                    {{ $t("cgu2_rgpd") }}</a
                  >)
                </b-checkbox>
              </b-field>
            </div>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-5-desktop is-4-widescreen"
          >
            <figure
              class="image is-2by1"
              style="display: flex;"
            >
              <img
                style="
                  max-width: 400px;
                  max-height: 600px;
                  margin-left: auto;
                  margin-right: auto;
                "
                src="../../../../assets/img/device_symbiocenter.png"
                alt="Image abonnement pulse"
              />
            </figure>
            <div class="box has-text-centered box-information box-offre">
              <p class="has-text-weight-normal is-size-3">
                {{ $t("offrePulse") }}
              </p>
              <p
                class="is-size-5 is-margin-b-10"
                v-if="abonnement.engagementAnnuel"
              ></p>
              <p class="is-size-5 is-margin-b-10" v-else>
                {{ this.pulseMensuel.toFixed(2) }} € ttc
              </p>
              <p
                class="is-size-7 is-margin-b-10"
                v-if="abonnement.engagementAnnuel"
              >
                {{ $t("prelevementAnnuelDe") }}
                {{ this.pulseAnnuel.toFixed(2) }} € ttc. <br />
                {{ $t("debiteEn") }} 1 {{ $t("fois") }}.
              </p>
              <p
                class="is-size-6 is-margin-b-10 has-text-success"
                v-if="abonnement.engagementAnnuel"
              ></p>
              <a
                v-if="
                  group.type_abonnement === 'pulse' && !modificationAbonnement
                "
                class="button is-danger buttonRadius"
                @click="
                  (isCardResiliationOpen = true),
                    (raisonResiliation = raisonResiliationDefault)
                "
              >
                {{ $t("resilier") }}
              </a>
              <!--a
                v-else-if="group.type_abonnement !== 'pulse' && !check"
                class="button is-primary buttonRadius"
                @click="confirmerAbonnement()"
              >
                Confirmer & Payer
              </a-->
              <div v-else>
                <!-- <formulaire-paiement-component
                  v-if="showTransactionData"
                  :transactionData2="transactionData"
                  :amount="this.transactionData.amount"
                  @confirmerAbonnement="confirmerAbonnement"
                /> -->
              </div>
            </div>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen is-margin-b-20"
          >
            <a
              class="button is-light is-margin-l-20"
              @click="
                modificationAbonnement = false;
                $emit('retour');
              "
            >
              {{ $t("retour") }}
            </a>
            <a
              class="button is-pulled-right is-cyan"
              v-if="group.type_abonnement === 'pulse'"
              @click="modifierAbonnement()"
            >
              {{ $t("confirmer") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :width="1200"
      :active.sync="isCardResiliationOpen"
      @close="
        (isCardResiliationOpen = false),
          (raisonResiliation = raisonResiliationDefault)
      "
    >
      <div class="card card-resiliation" v-if="raisonResiliation">
        <div class="card-content">
          <div class="content">
            <h3 class="title has-text-black is-size-6">
              {{ $t("demandeResiliation") }}
            </h3>
            <p>
              {{ $t("motivationResiliation") }}
            </p>
            <b-checkbox
              v-model="raisonResiliation.problemes_techniques"
              native-value="Problèmes techniques"
            >
              {{ $t("resiliationRaison1") }}
            </b-checkbox>
            <br />
            <b-checkbox
              v-model="raisonResiliation.inutilise"
              native-value="Inutilisé"
            >
              {{ $t("resiliationRaison2") }}
            </b-checkbox>
            <br />
            <b-checkbox v-model="raisonResiliation.cout" native-value="Coût">
              {{ $t("resiliationRaison3") }}
            </b-checkbox>
            <br />
            <b-checkbox
              v-model="raisonResiliation.complexe"
              native-value="Complexe"
            >
              {{ $t("resiliationRaison4") }}
            </b-checkbox>
            <br />
            <b-checkbox v-model="raisonResiliation.autre" native-value="Autres">
              {{ $t("resiliationRaison5") }}
            </b-checkbox>
            <textarea
              v-if="raisonResiliation.autre"
              v-model="raisonResiliation.texte"
              class="textarea"
              rows="2"
            ></textarea>
            <br />
            <br />
            <p>{{ $t("confirmationAnnualationAbonnement") }}</p>
            <div class="is-margin-b-50">
              <a
                class="button is-light is-pulled-left"
                @click="
                  (isCardResiliationOpen = false),
                    (raisonResiliation = raisonResiliationDefault)
                "
              >
                {{ $t("conserverOffre") }}
              </a>
              <a
                v-if="!validerResiliation"
                class="button is-cyan is-pulled-right"
                @click="resilier()"
              >
                {{ $t("confirmer") }}
              </a>
              <formulaire-resiliation-component
                v-else
                :resiliationData="resiliationData"
                @resilier="resilier()"
              />
            </div>
            <b-field
              :type="{
                'is-danger': errorValiderResiliation && !validerResiliation,
              }"
              :message="{
                'Veuillez accepter avant de confirmer la résiliation.':
                  !validerResiliation && errorValiderResiliation,
              }"
            >
              <b-checkbox class="is-margin-t-20" v-model="validerResiliation">
                {{ $t("informationResiliation") }}
              </b-checkbox>
            </b-field>
            <p class="has-text-justified">
              {{ $t("informationResiliation2") }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
import { responseQuestionnaryResiliation } from "@/services/userGroup/responseQuestionnaryResiliation.js";
import FormulairePaiement from "../general/FormulairePaiement.vue";
import FormulaireResiliation from "../general/FormulaireResiliation.vue";
import codeGenerator from "@/services/general/codeGenerator.js";
import { editUserGroupAbonnement } from "@/services/userGroup/editUserGroupAbonnement.js";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { checkAddressInformation } from "@/services/general/checkAddressInformation.js";
import AdresseLivraison from "@/components/general/AdresseLivraison.vue";
import AdresseLecture from "@/components/general/AdresseLecture.vue";
import getDateRenouvellement from "@/services/abonnement/getDateRenouvellement.js";
import { CONFIG_PAIEMENT } from "@/configuration/paiementConf.js";
import asyncForEach from "@/services/general/asyncForEach.js";
//import jsPDF from "jspdf";

const User = createNamespacedHelpers("user");

export default {
  name: "offre-pulse",
  components: {
    AdresseLivraisonComponent: AdresseLivraison,
    AdresseLectureComponent: AdresseLecture,
    FormulairePaiementComponent: FormulairePaiement,
    FormulaireResiliationComponent: FormulaireResiliation,
  },
  data() {
    return {
      userHasBillingAdress: false,
      errorAdresseUtilisateurUndefined: false,
      adresseLivraisonDifferente: false,
      code_societe: CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL,
      amount: null,
      showTransactionData: false,
      transactionData: null,
      abonnement: {},
      misAJour: true,
      error: false,
      contratAccepte: false,
      errorContratAccepte: false,
      erreurPaiement: false,
      isCardResiliationOpen: false,
      raisonResiliation: null,
      raisonResiliationDefault: {
        complexe: false,
        autre: false,
        inutilise: false,
        problemes_techniques: false,
        cout: false,
        texte: null,
      },
      validerResiliation: false,
      errorValiderResiliation: false,
      check: false,
      datePaiement: null,
      resiliationData: null,
      modificationAbonnement: false,
      pulseMensuel: 0,
      pulseAnnuel: 0,
      pulseEconomie: "",
    };
  },
  computed: {
    ...User.mapGetters(["group", "user", "abonnementTemp"]),
  },
  methods: {
    ...User.mapActions({
      actionsUpdateUserGroup: "updateUserGroup",
      actionsEditAbonnementTemp: "editAbonnementTemp",
    }),
    async confirmerAbonnement() {
      /*if (
        this.abonnement.engagementAnnuel &&
        !this.user.adresse &&
        !this.adresseLivraisonDifferente
      ) {
        this.errorAdresseUtilisateurUndefined = true;
        return;
      }
      const loadingComponent = this.$buefy.loading.open();
      this.error = false;
      this.errorAdresseUtilisateurUndefined = false;
      if (
        this.abonnement.engagementAnnuel &&
        !checkAddressInformation(this.abonnement.adresse)
      ) {
        this.error = true;
        loadingComponent.close();
        return;
      }
      this.actionsEditAbonnementTemp({
        abonnement: this.abonnement,
        transactionData: this.transactionData
      });
      loadingComponent.close();*/
    },
    async editInformationAbonnement() {
      this.modificationAbonnement = true;
      this.abonnement.cartesPostales = true;
      this.setEngagement(true);
    },
    async modifierAbonnement() {
      const loadingComponent = this.$buefy.loading.open();
      this.error = false;
      loadingComponent.close();
      await editUserGroupAbonnement(this.abonnement, this.group.objectId);
      this.actionsUpdateUserGroup();
      loadingComponent.close();
      this.$buefy.toast.open({
        duration: 3000,
        message: "Modification de l'abonnement effectué avec succès.",
        type: "is-success",
      });
      this.$emit("retour");
    },
    updateAdresse(e) {
      this.misAJour = false;
      this.abonnement.adresse = e;
      this.misAJour = true;
      this.contratAccepte = false;
      this.checkValue();
    },
    async resilier() {
      ParseConfig();
      const loadingComponent = this.$buefy.loading.open();
      this.errorValiderResiliation = false;
      if (!this.validerResiliation) {
        this.errorValiderResiliation = true;
        loadingComponent.close();
        return;
      }
      try {
        await responseQuestionnaryResiliation(
          this.raisonResiliation,
          this.group
        );
        this.actionsUpdateUserGroup();
        loadingComponent.close();
        this.isCardResiliationOpen = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Résiliation de l'abonnement effectué avec succès.",
          type: "is-success",
        });
        const dateResiliation = getDateRenouvellement(this.group);
        console.log("this.user", this.user);
        console.log("this.group", this.group);
        Parse.Cloud.run("confirmationResiliationAbonnementPro", {
          destinataire: this.user.username,
          offre: this.group.type_abonnement,
          duree: this.group.engagement_annuel ? "annuel" : "trimestriel",
          date_fin_acces: moment(new Date(dateResiliation)).format(
            "DD/MM/YYYY"
          ),
          utilisateur: this.user.email,
        })
          .then((response) => {
            console.log("response", response);
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch {
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Erreur rencontrée.",
          type: "is-danger",
        });
      }
      this.$emit("retour");
    },
    setEngagement(value) {
      this.check = false;
      this.abonnement.engagementAnnuel = value;
      this.code_societe = value
        ? CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL
        : CONFIG_PAIEMENT.CODE_SOCIETE_ANNUEL;
      this.editTransactionData(value);
      if (this.contratAccepte) {
        this.check = true;
      }
    },
    checkValue() {
      this.check = false;
      this.error = false;
      this.errorAdresseUtilisateurUndefined = false;
      if (!this.contratAccepte) {
        this.errorContratAccepte = true;
        return;
      }
      if (
        this.abonnement.engagementAnnuel &&
        !this.user.adresse &&
        !this.adresseLivraisonDifferente
      ) {
        this.errorAdresseUtilisateurUndefined = true;
        return;
      }
      if (
        this.abonnement.engagementAnnuel &&
        !checkAddressInformation(this.abonnement.adresse)
      ) {
        this.error = true;
        return;
      }
      this.check = true;
      this.editTransactionData(this.abonnement.engagementAnnuel);
      this.datePaiement = new Date();
    },
    async generationFacture() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;

      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = dd + "/" + mm + "/" + yyyy;

      ParseConfig();
      var QueryGroup = new Parse.Query(Parse.Object.extend("user_group"));
      QueryGroup.equalTo("objectId", this.group.objectId);
      var ResponseGroup = await QueryGroup.first();
      var QueryFacture = new Parse.Query(Parse.Object.extend("facture"));
      QueryFacture.equalTo("type_abonnement", "modele"); // Permet de récupérer l'image de fond de la facture
      //var ResponseFacture = await QueryFacture.first();
      var transactionData = this.transactionData;
      //var group = this.group;

      var QueryFactures = new Parse.Query(Parse.Object.extend("facture"));
      QueryFactures.equalTo("user", await Parse.User.current());
      var ResponseFactures = await QueryFactures.find();

      await asyncForEach(ResponseFactures, async (facture) => {
        if (!facture.attributes.valide) {
          await facture.destroy();
        }
      });

      var init_ref_facture =
        "SC" +
        yyyy.toString().charAt(2) +
        yyyy.toString().charAt(3) +
        "" +
        mm +
        "";
      var QueryAllFactures = new Parse.Query(Parse.Object.extend("facture"));
      QueryAllFactures.startsWith("reference_facture", init_ref_facture);
      var ResponseAllFactures = await QueryAllFactures.find();

      var initReFactureOk = false;
      var number = ResponseAllFactures.length + 1;
      var finalRef = null;
      while (!initReFactureOk) {
        if (number <= 9) {
          finalRef = init_ref_facture + "000" + number.toString();
        } else if (number <= 99) {
          finalRef = init_ref_facture + "00" + number.toString();
        } else if (number <= 999) {
          finalRef = init_ref_facture + "0" + number.toString();
        } else {
          finalRef = init_ref_facture + number.toString();
        }
        var QueryFactureByRefFacture = new Parse.Query(
          Parse.Object.extend("facture")
        );
        QueryFactureByRefFacture.equalTo("reference_facture", finalRef);
        var ResponseFactureByRefFacture = await QueryFactureByRefFacture.find();
        if (!ResponseFactureByRefFacture.length) {
          initReFactureOk = true;
        } else {
          number++;
        }
      }
      let duration = null;
      let dateRenouvellement = null;
      if (transactionData.engagementAnnuel) duration = "years";
      else duration = "trimesters";
      dateRenouvellement = new Date(moment().add(1, duration));
      while (dateRenouvellement < new Date()) {
        dateRenouvellement = new Date(
          moment(dateRenouvellement).add(1, duration)
        );
      }

      var ObjectFacture = Parse.Object.extend("facture");
      var Facture = new ObjectFacture();
      var monUser = await Parse.User.current();
      Facture.set("type_abonnement", "pulse");
      Facture.set("avec_engagement", transactionData.engagementAnnuel);
      Facture.set("user", monUser);
      Facture.set("montant", transactionData.amount);
      Facture.set("credit", 0);
      Facture.set("reference_paiement", transactionData.reference);
      Facture.set("reference_facture", finalRef);
      Facture.set("valide", false);
      Facture.set("group", ResponseGroup);
      await Facture.save();
    },
    toDataURL(url, currentUser, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result, currentUser);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    editTransactionData(value) {
      this.showTransactionData = false;
      if (this.transactionData) {
        this.transactionData.engagementAnnuel = value;
      }
      if (this.transactionData && this.transactionData.engagementAnnuel) {
        this.transactionData.reference =
          this.transactionData.referenceCode + "-engagement";
        this.transactionData.amount = this.pulseAnnuel.toFixed(2);
      } else if (this.transactionData) {
        this.transactionData.reference =
          this.transactionData.referenceCode + "-sansengagement";
        this.transactionData.amount = this.pulseMensuel.toFixed(2);
      }
      this.code_societe = value
        ? CONFIG_PAIEMENT.CODE_SOCIETE_ANNUEL
        : CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL;
      this.showTransactionData = true;
      this.generationFacture();
    },
    async chargeParametre() {
      var queryAppParam = new Parse.Query(Parse.Object.extend("app_param"));
      var appParam = await queryAppParam.first();
      this.pulseMensuel = appParam.attributes.pulseMensuel;
      this.pulseAnnuel = appParam.attributes.pulseAnnuel;
      this.pulseEconomie = appParam.attributes.pulseEconomie;
    },
  },
  async mounted() {
    await this.chargeParametre();
    this.raisonResiliation = this.raisonResiliationDefault;
    let engagementAnnuel = false;
    let adresse = {};
    let adressePostal = {};
    if (this.user.adresse) {
      this.userHasBillingAdress = true;
    }
    if (this.group) {
      if (!this.group.adresse_livraison) {
        adresse.nom = this.user.lastname;
        adresse.prenom = this.user.firstname;
        adresse.adresse = this.user.adresse;
        adresse.complementAdresse = this.user.complement_adresse;
        if (this.user.ville) {
          adresse.ville = this.user.ville;
        }
        if (this.user.code_postal) {
          adresse.codePostal = this.user.code_postal;
          adressePostal.codePostal = this.user.code_postal;
        }
        adresse.codeAcces = null;
        adressePostal.nom = this.user.lastname;
        adressePostal.prenom = this.user.firstname;
        adressePostal.adresse = this.user.adresse;
        adressePostal.complementAdresse = this.user.complement_adresse;
        adressePostal.ville = this.user.ville;
        adressePostal.codeAcces = null;
      } else {
        adresse.nom = this.group.adresse_livraison_nom
          ? this.group.adresse_livraison_nom
          : this.user.lastname;
        adresse.prenom = this.group.adresse_livraison_prenom
          ? this.group.adresse_livraison_prenom
          : this.user.firstname;
        adresse.adresse = this.group.adresse_livraison;
        adresse.complementAdresse = this.group.adresse_livraison_complement;
        adresse.ville = this.group.adresse_livraison_ville
          ? this.group.adresse_livraison_ville
          : this.user.ville;
        adresse.codePostal = this.group.adresse_livraison_code_postal
          ? this.group.adresse_livraison_code_postal
          : this.user.code_postal;
        adresse.codeAcces = this.group.adresse_livraison_code_acces_residence;

        adressePostal.nom = this.group.adresse_livraison_nom
          ? this.group.adresse_livraison_nom
          : this.user.lastname;
        adressePostal.prenom = this.group.adresse_livraison_prenom
          ? this.group.adresse_livraison_prenom
          : this.user.firstname;
        adressePostal.adresse = this.group.adresse_livraison;
        adressePostal.complementAdresse =
          this.group.adresse_livraison_complement;
        adressePostal.ville = this.group.adresse_livraison_ville
          ? this.group.adresse_livraison_ville
          : this.user.ville;
        adressePostal.codePostal = this.group.adresse_livraison_code_postal
          ? this.group.adresse_livraison_code_postal
          : this.user.code_postal;
        adressePostal.codeAcces =
          this.group.adresse_livraison_code_acces_residence;
      }

      if (
        !this.group.engagement_annuel &&
        this.group.engagement_annuel !== false
      ) {
        engagementAnnuel = true;
      } else {
        engagementAnnuel = this.group.engagement_annuel;
      }
    }

    this.abonnement = {
      typeAbonnement: "pulse",
      engagementAnnuel: engagementAnnuel,
      cartesPostales: false,
      adresse: adresse,
      adressePostal: adressePostal,
    };

    this.transactionData = {
      email: this.user.email,
      referenceCode:
        this.abonnement.typeAbonnement +
        "_" +
        this.group.objectId +
        "_" +
        codeGenerator("xxxx") +
        "-therapeute",
    };
    this.editTransactionData(engagementAnnuel);
  },
  watch: {
    async contratAccepte() {
      if (this.contratAccepte) {
        this.confirmerAbonnement();
        this.check = true;
        var queryUser = new Parse.Query("User");
        queryUser.equalTo("objectId", this.user.objectId);
        var monUser = await queryUser.first();

        //await this.user.fetch();

        var linkRelationUser = monUser.relation("link");
        var i = 0;

        for (i = 0; i < linkRelationUser.length; i = i + 1) {
          linkRelationUser[i].destroy();
        }
        Parse.masterKey = "myMasterKey";
        await monUser.save({}, { useMasterKey: true });

        const QueryLinks = new Parse.Query(Parse.Object.extend("link"));
        QueryLinks.equalTo("type_abonnement", "gratuit");
        QueryLinks.limit(1000);
        const ResponseLinks = await QueryLinks.find();

        const QueryLinksPlay = new Parse.Query(Parse.Object.extend("link"));
        QueryLinksPlay.equalTo("type_abonnement", "play");
        QueryLinksPlay.limit(1000);
        const ResponseLinksPlay = await QueryLinksPlay.find();

        const QueryLinksPulse = new Parse.Query(Parse.Object.extend("link"));
        QueryLinksPulse.equalTo("type_abonnement", "pulse");
        QueryLinksPulse.limit(1000);
        const ResponseLinksPulse = await QueryLinksPlay.find();

        const RelationLinkFromUser = monUser.relation("link");
        for (i = 0; i < ResponseLinks.length; i = i + 1) {
          RelationLinkFromUser.add(ResponseLinks[i]);
        }

        for (i = 0; i < ResponseLinksPlay.length; i = i + 1) {
          RelationLinkFromUser.add(ResponseLinksPlay[i]);

          for (i = 0; i < ResponseLinksPulse.length; i = i + 1) {
            RelationLinkFromUser.add(ResponseLinksPulse[i]);
          }

          await monUser.save({}, { useMasterKey: true });
          const ObjectGroup = Parse.Object.extend("user_group");
          const QueryGroup = new Parse.Query(ObjectGroup);
          QueryGroup.equalTo("objectId", this.group.objectId);
          const ResponseGroup = await QueryGroup.first();
          if (
            this.adresseLivraisonDifferente &&
            this.abonnement.engagementAnnuel
          ) {
            ResponseGroup.set(
              "adresse_livraison_nom",
              this.abonnement.adresse.nom
            );
            ResponseGroup.set(
              "adresse_livraison_prenom",
              this.abonnement.adresse.prenom
            );
            ResponseGroup.set(
              "adresse_livraison",
              this.abonnement.adresse.adresse
            );
            ResponseGroup.set(
              "adresse_livraison_complement",
              this.abonnement.adresse.complementAdresse
            );
            ResponseGroup.set(
              "adresse_livraison_code_postal",
              this.abonnement.adresse.codePostal
            );
            ResponseGroup.set(
              "adresse_livraison_ville",
              this.abonnement.adresse.ville
            );
            ResponseGroup.set(
              "adresse_livraison_code_acces_residence",
              this.abonnement.adresse.codeAcces
            );
          } else {
            ResponseGroup.set("adresse_livraison_nom", this.user.lastname);
            ResponseGroup.set("adresse_livraison_prenom", this.user.firstname);
            ResponseGroup.set("adresse_livraison", this.user.adresse);
            ResponseGroup.set(
              "adresse_livraison_complement",
              this.user.complementAdresse
            );
            ResponseGroup.set(
              "adresse_livraison_code_postal",
              this.user.codePostal
            );
            ResponseGroup.set("adresse_livraison_ville", this.user.ville);
          }
          ResponseGroup.set("essai_gratuit", false);
          ResponseGroup.set("subscription_end_date", new Date(2100, 12, 1));
          await ResponseGroup.save();
        }
      }
    },
    async isCardResiliationOpen() {
      this.resiliationData = {
        reference: this.group.reference_paiement,
        date_commande: moment(new Date(this.group.date_paiement)).format(
          "DD/MM/YYYY"
        ),
        montant_deja_capture: this.group.montant_deja_preleve_therapeute,
        amount: this.group.montant_initial_therapeute,
      };
    },
  },
};
</script>

<style scoped>
.subtitleFacturation
{
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
}
</style>
