<template>
  <div>
    <div
      v-if="
        (FindFilesDones && accesTherapeute && isTherapeute) ||
        (FindFilesDones && !isTherapeute) ||
        (FindFilesDones && isTherapeute && RelectureRessourcesLibres)
      "
    >
      <div>
        <button class="chart-button" v-on:click="findDataCC()">
          Afficher le graphique du suivi de la cohérence cardiaque
          <i
            :class="[
              'fas',
              displayChartAverageCC ? 'fa-chevron-up' : 'fa-chevron-down',
            ]"
          ></i>
        </button>
        <div v-if="loadingAverage" class="loader"></div>
        <div v-if="displayChartAverageCC" id="chartResultId">
          <button
            class="chart-button-fullscreen my-button"
            v-on:click="toggleFullScreen()"
          >
            Afficher en plein écran le graphique
          </button>
          <button
            class="chart-button-fullscreen my-button"
            v-on:click="printChart()"
          >
            Imprimer le graphique
          </button>
          <chart-result
            @click="emitClick"
            v-if="ArrayDates.length > 0"
            :dates="ArrayDates"
            :scores="ArrayAverageCCs"
          />
        </div>
        <div class="exercise-type-selector" v-if="!isForRessourcesLibres">
          <label for="exercise-type">Filtrer par type d'exercice: </label>
          <select id="exercise-type" v-model="selectedExerciseType">
            <option value="">Tous</option>
            <option value="Questionnaire">
              <img
                src="../../../../assets/img/IconQuestionnaire.png"
                class="exercise-type-icon"
              />
              Questionnaire
            </option>
            <option value="Exercice vidéo">
              <img
                src="../../../../assets/img/IconVideo.png"
                class="exercise-type-icon"
              />
              Exercice vidéo
            </option>
            <option value="Exercice audio">
              <img
                src="../../../../assets/img/IconAudio.png"
                class="exercise-type-icon"
              />
              Exercice audio
            </option>
            <option value="Module Expert">
              <img
                src="../../../../assets/img/IconMoniteur.png"
                class="exercise-type-icon"
              />
              Module Expert
            </option>
            <option value="Jeux">
              <img
                src="../../../../assets/img/IconJeu.png"
                class="exercise-type-icon"
              />
              Jeux
            </option>
          </select>
        </div>
        <div class="exercise-type-selector" v-else>
          <label for="exercise-type">Filtrer par type d'exercice: </label>
          <select id="exercise-type" v-model="selectedExerciseType">
            <option value="">Tous</option>
            <option value="Module Expert">
              <img
                src="../../../../assets/img/IconMoniteur.png"
                class="exercise-type-icon"
              />
              Module Expert
            </option>
            <option value="Jeux">
              <img
                src="../../../../assets/img/IconJeu.png"
                class="exercise-type-icon"
              />
              Jeux
            </option>
          </select>
        </div>
        <table class="wide-table">
          <thead>
            <tr class="headerTable">
              <th class="headerTableTH">Type</th>

              <th class="headerTableTH">Programme</th>

              <th class="headerTableTH">Module</th>

              <th class="headerTableTH">Exercice</th>

              <th class="headerTableTH">Nombre de réalisation</th>

              <th class="headerTableTH">Dernière réalisation</th>

              <th class="headerTableTH">Temps passé</th>

              <th class="headerTableTH">Détail</th>
            </tr>
          </thead>
          <tbody>
            <!-- <button @click="test(paginatedFilesDones)">test</button> -->
            <template v-for="(value, key) in paginatedFilesDones">
              <tr :key="key">
                <td>
                  <img
                    class="ImgType"
                    v-if="value.typeExercice === 'Questionnaire'"
                    src="../../../../assets/img/IconQuestionnaire.png"
                  />
                  <img
                    class="ImgType"
                    v-else-if="value.typeExercice === 'Exercice vidéo'"
                    src="../../../../assets/img/IconVideo.png"
                  />
                  <img
                    class="ImgType"
                    v-else-if="value.typeExercice === 'Exercice audio'"
                    src="../../../../assets/img/IconAudio.png"
                  />
                  <img
                    class="ImgType"
                    v-else-if="
                      value.typeExercice === 'Module Expert' ||
                      value.typeExercice ===
                        'Module Expert : Indice de lâcher-prise'
                    "
                    src="../../../../assets/img/IconMoniteur.png"
                  />
                  <img
                    class="ImgType"
                    v-else-if="value.typeExercice === 'Jeux'"
                    src="../../../../assets/img/IconJeu.png"
                  />
                </td>
                <td>
                  <!-- <button @click="test(value)"></button> -->
                  <span v-if="value.Ani_Boolean">Indice de lâcher-prise</span>
                  <span
                    v-else-if="
                      value.protocole && value.protocole.attributes.name
                    "
                  >
                    {{ value.protocole.attributes.name }}
                  </span>
                  <span v-else
                    >RENFORCER LE TONUS VAGAL par la cohérence cardiaque</span
                  >
                </td>
                <td>
                  <span v-if="value.module && value.module.attributes.name">
                    {{ value.module.attributes.name }}
                  </span>
                </td>
                <td>
                  <!-- <button @click="test(value)">test</button> -->
                  <span v-if="value.Ani_Boolean"
                    >Moniteur Expert : Indice de lâcher-prise</span
                  >
                  <span
                    v-else-if="value.file && value.file.attributes.sub_name"
                  >
                    {{ value.file.attributes.sub_name }}
                  </span>
                  <span v-else-if="value.file && value.file.attributes.name">
                    {{ value.file.attributes.name }}
                  </span>
                </td>
                <td style="text-align: center">{{ value.count }}</td>
                <td>le {{ formatDate(value.LastDate) }}</td>
                <td>
                  <span v-if="isValidTime(value.timeElapsed)">
                    {{ formatTime(value) }}
                  </span>
                </td>
                <td class="centered-button-relecture">
                  <button
                    v-if="
                      value.typeExercice === 'Questionnaire' ||
                      value.typeExercice === 'Module Expert' ||
                      value.typeExercice === 'Jeux' ||
                      value.typeExercice ===
                        'Module Expert : Indice de lâcher-prise'
                    "
                    style="color: white"
                    class="button is-small button-custom-relecture"
                    v-on:click="MoreInformations(key)"
                  >
                    <i class="icon fas fa-info-circle"></i>
                    <p>détail</p>
                  </button>
                </td>
                <!-- Vous pouvez ajouter plus de cellules ici en fonction de votre structure de données -->
              </tr>
              <tr
                class="divRelecture full-width"
                v-if="detailsVisible === key"
                :key="key + '-details'"
              >
                <td :colspan="8" style="padding: 20px">
                  <relectureDetailsUserVue
                    :fileDoneId="key"
                    :selectedUser="selectedUser"
                    :typeExercice="value.typeExercice"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="pagination">
          <button
            class="button-pages"
            @click="prevPage"
            :disabled="currentPage <= 0"
          >
            Précédent
          </button>
          <div class="page-numbers">
            <span
              v-for="n in totalPages"
              :key="n"
              @click="goToPage(n - 1)"
              :class="{ 'active-page': currentPage === n - 1 }"
              >{{ n }}</span
            >
          </div>
          <!-- <span>Page {{currentPage + 1}} de {{totalPages}}</span> -->
          <button
            class="button-pages"
            @click="nextPage"
            :disabled="currentPage >= totalPages - 1"
          >
            Suivant
          </button>
        </div>
      </div>
    </div>

    <div v-else>
      <div
        class="no-exercises-done-message"
        v-if="isTherapeute && accesTherapeute"
      >
        L'utilisateur n'a pas encore réalisé d'exercices.
      </div>

      <div
        class="no-exercises-relance-message"
        v-else-if="
          isTherapeute && !accesTherapeute && !RelectureRessourcesLibres
        "
      >
        <!-- {{ isTherapeute + " " + accesTherapeute + " " + RelectureRessourcesLibres }} -->
        <div class="card editUser cardRelanceUser">
          <div class="card-content">
            <div class="content">
              <div class="overflow">
                <h3 class="title has-text-black is-size-5">
                  L'utilisateur ne vous a pas donné accès à ses exercices.
                </h3>
                <div class="columns">
                  <div class="column is-6">
                    <b-field custom-class="is-small" label="Nom">
                      <b-input
                        disabled
                        size="is-small"
                        v-model="selectedUser.nom"
                      ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field custom-class="is-small" label="Prénom">
                      <b-input
                        disabled
                        size="is-small"
                        v-model="selectedUser.prenom"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
                <div>
                  <p>Personnaliser votre message :</p>
                  <b-field>
                    <b-input
                      v-model="mail"
                      maxlength="2000"
                      type="textarea"
                    ></b-input>
                  </b-field>
                </div>
                <div>
                  <div class="is-pulled-left">
                    <a class="button is-cyan" v-on:click="sendMail()">
                      Envoyer la demande d'accès
                    </a>
                  </div>
                  <div class="is-pulled-right">
                    <a class="button is-grey" @click="$emit('closeModal')">
                      {{ $t("fermer") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-exercises-done-message" v-else>
        <div class="exercise-type-selector" v-if="!isForRessourcesLibres">
          <label for="exercise-type">Filtrer par type d'exercice: </label>
          <select id="exercise-type" v-model="selectedExerciseType">
            <option value="">Tous</option>
            <option value="Questionnaire">
              <img
                src="../../../../assets/img/IconQuestionnaire.png"
                class="exercise-type-icon"
              />
              Questionnaire
            </option>
            <option value="Exercice vidéo">
              <img
                src="../../../../assets/img/IconVideo.png"
                class="exercise-type-icon"
              />
              Exercice vidéo
            </option>
            <option value="Exercice audio">
              <img
                src="../../../../assets/img/IconAudio.png"
                class="exercise-type-icon"
              />
              Exercice audio
            </option>
            <option value="Module Expert">
              <img
                src="../../../../assets/img/IconMoniteur.png"
                class="exercise-type-icon"
              />
              Module Expert
            </option>
            <option value="Jeux">
              <img
                src="../../../../assets/img/IconJeu.png"
                class="exercise-type-icon"
              />
              Jeux
            </option>
          </select>
        </div>
        <div class="exercise-type-selector" v-else>
          <label for="exercise-type">Filtrer par type d'exercice: </label>
          <select id="exercise-type" v-model="selectedExerciseType">
            <option value="">Tous</option>
            <option value="Module Expert">
              <img
                src="../../../../assets/img/IconMoniteur.png"
                class="exercise-type-icon"
              />
              Module Expert
            </option>
            <option value="Jeux">
              <img
                src="../../../../assets/img/IconJeu.png"
                class="exercise-type-icon"
              />
              Jeux
            </option>
          </select>
        </div>
        Vous n'avez pas encore réalisé d'exercices.
        <div />
      </div>
    </div>
    <planActionBfp
      :filesDonesBFP="filesDonesBFP"
      v-if="filesDonesBFP.length > 0"
    />
  </div>
</template>

<script>
import { format } from "date-fns";
import planActionBfp from "../bfp/planActionBfp.vue";
import { fr } from "date-fns/locale";
import relectureDetailsUserVue from "./relectureDetailsUser.vue";
import { getFilesDonesBFP } from "@/services/bfp/getFilesDonesBFP.js";
import Parse from "parse";
import { fetchRelectureData } from "@/services/relecture/relectureService";
import ChartResult from "../chart/chartResultComparaison.vue";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 6,
      selectedExerciseType: "",
      detailsVisible: null,
      isTherapeute: false,
      accesTherapeute: false,
      mail: "",
      filesDonesBFP: [],
      selectedUserIsTherapeute: false,
      ArrayDates: [],
      ArrayAverageCCs: [],
      displayChartAverageCC: false,
      loadingAverage: false,
      isFullScreen: false,
      ArrayDatesAndAverageCCs: [],
    };
  },
  props: {
    filesDones: {
      type: Object,
      required: true,
    },
    selectedUser: {
      type: Object,
      required: true,
    },
    RelectureRessourcesLibres: {
      type: Boolean,
      required: false,
      default: false,
    },
    isForRessourcesLibres: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    relectureDetailsUserVue,
    planActionBfp,
    ChartResult,
  },
  computed: {
    FindFilesDones() {
      return (
        this.filteredFilesDones &&
        Object.keys(this.filteredFilesDones).length > 0
      );
    },
    filteredFilesDones() {
      if (!this.selectedExerciseType) {
        return this.filesDones;
      }
      return Object.entries(this.filesDones)
        .filter(([, value]) => value.typeExercice === this.selectedExerciseType)
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
    },
    totalItems() {
      return Object.keys(this.filteredFilesDones).length;
    },
    paginatedFilesDones() {
      const start = this.currentPage * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      const sortedFilesDones = Object.entries(this.filteredFilesDones).sort(
        ([, valueA], [, valueB]) => {
          const dateA = new Date(valueA.LastDate);
          const dateB = new Date(valueB.LastDate);
          return dateB - dateA; // Tri par ordre décroissant des dates
        }
      );

      return sortedFilesDones.slice(start, end).reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    MoreInformations(key) {
      this.detailsVisible = this.detailsVisible === key ? null : key;
    },
    emitClick(data) {
      console.log("Emitting click event with data:", data);
      // récupérer le ArrayDatesAndAverageCCs ayant le data.date et data.score correspondant
      console.log("ArrayDatesAndAverageCCs:", this.ArrayDatesAndAverageCCs);

      // Formatter la date reçue en format FR (jour/mois/année)
      const dateObj = new Date(data.date);
      const formattedDate = dateObj.toLocaleDateString("fr-FR"); // Format 15/12/2024

      // Trouver l'élément correspondant avec la date formatée et le score correspondant
      var dataToSend = this.ArrayDatesAndAverageCCs.find((element) => {
        // Convertir chaque date de element.date au format français pour la comparaison
        const elementDateFR = new Date(element.date).toLocaleDateString(
          "fr-FR"
        );
        console.log("Element date FR:", elementDateFR);
        return (
          elementDateFR === formattedDate && element.averageCC === data.score
        );
      });
      console.log("Data to send:", dataToSend);
    },

    toggleFullScreen() {
      let element = document.getElementById("chartResultId");
      if (!document.fullscreenElement) {
        element.requestFullscreen().catch((err) => {
          alert(
            `Erreur lors de la tentative de passage en plein écran: ${err.message} (${err.name})`
          );
        });
        this.isFullScreen = true; // Mettre à jour l'état en plein écran
      } else {
        document.exitFullscreen();
      }
    },
    exitFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.isFullScreen = false; // Mettre à jour l'état lors de la sortie du plein écran
      }
    },

    async printChart() {
      const element = document.getElementById("chartResultId");

      // Ajoutez la classe 'no-print' aux éléments à cacher
      const buttons = element.querySelectorAll(".my-button");
      buttons.forEach((btn) => btn.classList.add("no-print"));

      const canvas = await html2canvas(element);

      // Création et sauvegarde du PDF comme avant
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        0,
        0,
        canvas.width,
        canvas.height
      );
      pdf.save("resultat.pdf");

      // Retirez la classe 'no-print' après la création du PDF
      buttons.forEach((btn) => btn.classList.remove("no-print"));
    },
    async findDataCC() {
      if (this.displayChartAverageCC) {
        this.displayChartAverageCC = false;
        return;
      }
      this.loadingAverage = true;

      const filteredFilesDones = Object.entries(this.filteredFilesDones)
        .filter(
          (
            [, value] // Utilisez [, value] pour ignorer la clé
          ) =>
            value.typeExercice === "Module Expert" ||
            value.typeExercice === "Jeux" ||
            value.typeExercice === "Module Expert : Indice de lâcher-prise"
        )
        .map(([, value]) => ({ ...value })); // Supprimez "key" de l'objet retourné

      filteredFilesDones.sort(
        (a, b) => new Date(b.LastDate) - new Date(a.LastDate)
      );
      console.log(
        "Fichiers filtrés pour la cohérence cardiaque :",
        filteredFilesDones
      );
      let dates = [];
      let averageCCs = [];
      let dateAveragePairs = [];

      for (const file of filteredFilesDones) {
        console.log("FILE:", file);
        try {
          const response = await fetchRelectureData(
            file.file.id,
            this.selectedUser,
            file.typeExercice
          );
          console.log("/!\ Réponse pour", file.file.id, ":", response);
          Object.entries(response).forEach(([responseKey, responseObject]) => {
            console.log(
              "Valeur de responseObject pour la clé",
              responseKey,
              ":",
              responseObject
            );
            Object.entries(responseObject).forEach(([key, value]) => {
              console.log("Valeur de value :", value, "pour la clé", key, ".");
              if (value.attributes && value.attributes.resultatJeux) {
                const resultatJeuxParsed = JSON.parse(
                  value.attributes.resultatJeux
                );
                console.log(
                  "AverageCC",
                  resultatJeuxParsed.AverageCC,
                  "Date",
                  value.attributes.createdAt
                );
                const averageCC = resultatJeuxParsed.AverageCC;
                const date = new Date(value.attributes.createdAt);
                if (averageCC > 0) {
                  dateAveragePairs.push({ date, averageCC });
                }
              }
            });
          });
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données pour",
            file.key,
            ":",
            error
          );
        }
      }
      console.log("DateAveragePairs", dateAveragePairs);

      // Trier par date en ordre croissant
      dateAveragePairs.sort((a, b) => a.date - b.date);
      dates = dateAveragePairs.map((pair) => pair.date.toLocaleDateString());
      averageCCs = dateAveragePairs.map((pair) => pair.averageCC);
      this.ArrayDatesAndAverageCCs = dateAveragePairs;
      console.log("Tableau des dates :", dates);
      console.log("Tableau des AverageCC :", averageCCs);
      this.ArrayDates = dates;
      this.ArrayAverageCCs = averageCCs;
      this.displayChartAverageCC = true;
      this.loadingAverage = false;
    },

    async sendMail() {
      var userCourant = await Parse.User.current();
      await Parse.Cloud.run("demandeAutorisationAcces", {
        destinataire: this.selectedUser.mail,
        pro:
          userCourant.attributes.firstname && userCourant.attributes.lastname
            ? userCourant.attributes.firstname +
              " " +
              userCourant.attributes.lastname
            : userCourant.attributes.username,
        mail: this.selectedUser.mail,
        utilisateur: this.selectedUser.prenom + " " + this.selectedUser.nom,
      });
      this.$buefy.toast.open({
        duration: 5000,
        message: "Demande d'autorisation envoyée avec succès.",
        type: "is-success",
      });
      this.$emit("closeModal");
    },
    formatDate(date) {
      return format(new Date(date), "dd/MM/yyyy à HH:mm:ss", { locale: fr });
    },
    nextPage() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    isValidTime(time) {
      return time !== undefined && !isNaN(time);
    },
    formatTime(value) {
      if (value.typeExercice === "Jeux") {
        // Retourner la valeur de timeElapsed sans conversion
        return value.timeElapsed > 60
          ? `${Math.floor(value.timeElapsed / 60)} heures`
          : `${value.timeElapsed} min`;
      } else {
        var seconds = value.timeElapsed;
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        let timeString = "";
        if (mins > 0) {
          timeString += `${mins} min `;
        }
        if (secs > 0) {
          timeString += `${secs} sec`;
        }
        return timeString;
      }
    },
  },

  async created() {
    this.filesDonesBFP = [];
    if (this.selectedUser.therapeute) {
      this.selectedUserIsTherapeute = true;
    }
    if (
      this.selectedUser.therapeute ||
      Parse.User.current().attributes.therapeute
    ) {
      this.isTherapeute = true;
      if (
        !this.selectedUser.acces_donnees_therapeute &&
        this.selectedUser.typePratique !== "cabinet"
      ) {
        this.accesTherapeute = false;
      } else {
        this.accesTherapeute = true;
      }
    } else {
      this.isTherapeute = false;
    }
    this.filesDonesBFP = await getFilesDonesBFP(this.selectedUser.objectId);
    //console.log('FilesDonesBFP',this.filesDonesBFP);
  },
};
</script>
<style scoped>
.wide-table {
  max-width: 100%;
  border-collapse: collapse;
}
.wide-table td,
.wide-table th {
  border: 1px solid rgb(126 177 166) !important;
  padding: 8px;
}

.headerTable {
  background: #239380;
  color: white !important;
}
.headerTableTH {
  background: #239380;
  color: white !important;
  padding: 10px;
}
.button-custom-relecture {
  background-color: #218977;
  border-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  padding: 10px 15px; /* Make button a bit bigger */
}

.button-pages {
  background-color: #218977;
  border-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  padding: 10px 15px; /* Make button a bit bigger */
  cursor: pointer;
}
.exercise-type-selector {
  margin-bottom: 1rem;
}
.page-numbers {
  display: flex;
  gap: 0.5rem;
}

.page-numbers .active-page {
  font-weight: bold;
  color: #218977;
  text-decoration: underline;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.centered-button-relecture {
  text-align: center;
}
.centered-td {
  text-align: center;
}
.button-custom:hover {
  background-color: #4d978b;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.icon {
  margin-right: 0.25rem;
}
.ImgType {
  width: 30px;
  height: 30px;
}
.exercise-type-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.no-exercises-done-message {
  padding: 2rem;
  text-align: center;
  color: #888;
  font-size: 1.25rem;
}
.no-exercises-relance-message {
  text-align: center;
  color: #888;
  font-size: 1.25rem;
}
.button-pages:disabled,
.button-custom-relecture:disabled {
  background-color: #888;
  border-color: #888;
  cursor: not-allowed;
}
.cardRelanceUser {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.chart-button {
  background-color: #239380; /* Green background */
  color: white; /* White text */
  padding: 12px 24px; /* Padding around the text */
  font-size: 16px; /* Button text size */
  border: none; /* No borders */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  display: inline-flex; /* Flexbox for inline alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center text and icon horizontally */
  margin-bottom: 1rem; /* Add some space below the button */
}

.chart-button:hover {
  background-color: #1e7c6d; /* Darker shade of green on hover */
  transform: translateY(2px); /* Slightly move the button down */
}
.chart-button-fullscreen {
  background-color: #239380; /* Green background */
  color: white; /* White text */
  padding: 6px 12px; /* Padding around the text */
  font-size: 12px; /* Button text size */
  border: none; /* No borders */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  display: inline-flex; /* Flexbox for inline alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center text and icon horizontally */
  margin-bottom: 1rem; /* Add some space below the button */
  margin-left: auto;
  display: flex;
  margin-right: 1rem;
  margin-top: 1rem;
}

.chart-button:hover {
  background-color: #1e7c6d; /* Darker shade of green on hover */
  transform: translateY(2px); /* Slightly move the button down */
}
.chart-button i {
  margin-left: 8px; /* Space between text and icon */
  margin-right: -8px; /* Space between text and icon */
}

.loadingAverage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey background */
  border-top: 8px solid #239380; /* Blue color for the spinner part */
  border-radius: 50%; /* Circular border */
  width: 40px; /* Size of the loader */
  height: 40px; /* Size of the loader */
  animation: spin 2s linear infinite; /* Animation to spin the loader */
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#chartResultId {
  background-color: white;
}
.exit-fullscreen {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  background-color: #239380;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 16px;
}

@media print {
  /* body * {
    visibility: hidden;
  }
  #chartResultId, #chartResultId * {
    visibility: visible;
  }
  #chartResultId {
    position: absolute;
    left: 0;
    top: 0;
  } */
}

.no-print {
  display: none !important;
}
</style>
