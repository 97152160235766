<template>
  <div ref="element">
    <div>
      <div>
        <transition name="slide-fade">
          <div class="notification-container2" v-if="showNotification">
            <div class="notification-box">
              <button @click="closeNotification" class="notification-close">
                &times;
              </button>
              <div class="notification-text">Exercice terminé</div>
            </div>
          </div>
        </transition>
      </div>
      <!-- <div
          v-html="descriptionPro"
          class="modules-list dir-col-mobile descriptionPro"
          v-if="descriptionPro"
        ></div> -->
      <!-- <button @click="GoToTheGame()">Allez vers le jeu</button> -->
      <template v-for="theme in themes">
        <!-- Pour avoir la CC dans mes programme sante : Modifier son themeId et changer la ligne suivante par "theme.attributes.ordre === 1" sinon ""=== 4" -->
        <div v-if="theme.attributes.ordre === 3">
          <CarouselFolders
            :folderBrique="getFoldersByTheme(theme.id)"
            @recupId="handleRecupId"
            name="Ressources libres"
            :idPatientTherapeute="idPatientTherapeute"
            key="2"
          />
        </div>
        <!-- 
  
            PROGRAMME COURT ET PROGRAMME LONG
  
          -->
        <div v-if="showModalSubscription">
          <subscriptionPopUp @close="closeModalSubscription" />
        </div>
        <div v-if="DisplaySeances && DisplaySeances" :key="theme.id">
          <div v-if="theme.attributes.ordre === 3">
            <!-- BOUTON PROGRAMME COURT / PROGRAMME LONG-->
            <div
              v-if="
                idProtocol != '4ydeuBgcuu' &&
                idProtocol != 'VOrYKcDSo0' &&
                idProtocol != '6UrWEHmx2k' &&
                idProtocol != 'Ei2QLEat9q' &&
                idProtocol != 'YfMDVvTM5w' &&
                idProtocol != 'v53Vfs19Me'
              "
              class="buttonRessources"
            >
              <button
                class="buttonProg"
                v-on:click="show_exercice_court()"
                v-bind:class="{ colorButton: isExercicecourt }"
              >
                Exercice Court
              </button>
              <button
                class="buttonProg"
                v-on:click="show_exercice_long()"
                v-bind:class="{ colorButton: isExercicelong }"
              >
                Exercice Long
              </button>
            </div>
            <div v-if="idProtocol === '4ydeuBgcuu'" style="display: flex">
              <button
                class="buttonChart buttonResultatRessources"
                v-on:click="openSuivi()"
              >
                Revoir mes résultats
              </button>
            </div>
            <div v-if="idProtocol === 'v53Vfs19Me'" style="display: flex">
              <button
                class="buttonChart buttonResultatRessources"
                v-on:click="openSuiviAni()"
              >
                Revoir mes résultats
              </button>
            </div>
            <div class="DivRelectureLibre" v-if="DisplayResult">
              <div
                v-if="!displaySuiviData"
                class="loader-detail-relecture"
              ></div>
              <relectureUser
                v-else
                :RelectureRessourcesLibres="RelectureRessourcesLibres"
                :filesDones="suiviData"
                :selectedUser="selectedUser"
                :isForRessourcesLibres="true"
              ></relectureUser>
            </div>
            <div class="DivRelectureLibre" v-if="showRelectureAni">
              <relectureAni />
            </div>
            <div
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
              class="is-margin-t-10"
            >
              <div v-if="idProtocol == folderBrique.id">
                <div
                  v-if="
                    folderBrique &&
                    !folderBrique.name.includes('Tests') &&
                    !folderBrique.name.includes('Soft') &&
                    !folderBrique.name.includes('Vie')
                  "
                >
                  <div
                    v-if="
                      folderBrique.protocoles[3] || folderBrique.protocoles[2]
                    "
                  >
                    <div>
                      <div>
                        <!--
  
                          SEANCE   
  
                        -->
                        <!-- <div id="SeanceLocalisation">
                            <div>
                              <div v-on:click="background = !background">
                                <div>
                                  <div
                                    id="boxRessources"
                                    v-on:click="recupIdSeance('')"
                                    v-if="idSeance == exoP.id"
                                    style="
                                      cursor: pointer !important;
                                      background-color: #489181 !important;
                                      color: white;
                                    "
                                  >
                                    <div class="test1">
                                      {{ exoP.name }}
                                      <img
                                        class="arrowDown2"
                                        src="../../../assets/img/playButton.png"
                                        style="transform: rotate(90deg)"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    v-else
                                    id="boxRessources"
                                    v-on:click="recupIdSeance(exoP)"
                                    style="cursor: pointer !important"
                                  >
                                    <div class="test1">
                                      {{ exoP.name }}
                                      <img
                                        class="arrowDown2"
                                        src="../../../assets/img/playButton.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->

                        <!--
  
                          EXERCICES
  
                        -->
                        <!-- {{ folderBrique.id }} -->
                        <div
                          id="SeanceLocalisation"
                          v-if="folderBrique.id === 'v53Vfs19Me'"
                        >
                          <new-ani-chart></new-ani-chart>
                        </div>
                        <div id="SeanceLocalisation" v-else>
                          <div
                            class="AudioList"
                            style="background-color: transparent"
                          >
                            <!-- {{folderBrique.id}} -->
                            <div v-if="folderBrique && !isAndroid">
                              <!-- (folderBrique.name.includes('cardiaque') ||
                                    folderBrique.name.includes('hypnotique'))-->
                              <div>
                                <!--v-if="folderBrique.protocoles[3]"-->
                                <span class="has-text-black is-size-7">
                                  <div
                                    v-for="moduleExpertExercices in moduleExpertExercices"
                                    :key="moduleExpertExercices.id"
                                  >
                                    <div
                                      v-if="
                                        moduleExpertExercices.attributes
                                          .nomfichieraudio
                                      "
                                    >
                                      <div
                                        v-on:click="
                                          recupIdAudio(
                                            moduleExpertExercices.id === idAudio
                                              ? null
                                              : moduleExpertExercices
                                          ),
                                            startCounterFreeAccount()
                                        "
                                        class="infoBoxExo"
                                        v-bind:style="{
                                          backgroundColor:
                                            moduleExpertExercices.id === idAudio
                                              ? '#489181'
                                              : 'white',
                                          color:
                                            moduleExpertExercices.id === idAudio
                                              ? 'white'
                                              : 'black',
                                        }"
                                      >
                                        <div class="flexWrapper">
                                          <div class="textContent">
                                            <p class="TextSeanceResponsive">
                                              <span>
                                                <img
                                                  v-if="
                                                    moduleExpertExercices.id ===
                                                    idAudio
                                                  "
                                                  class="IconExercice IconAudioExercice"
                                                  src="../../../assets/img/IconAudioWhite.png"
                                                />
                                                <img
                                                  v-else
                                                  class="IconExercice IconAudioExercice"
                                                  src="../../../assets/img/IconAudio.png"
                                                />
                                              </span>
                                              <span
                                                v-bind:style="{
                                                  color:
                                                    moduleExpertExercices.id ===
                                                    idAudio
                                                      ? 'white'
                                                      : '',
                                                }"
                                                class="session-title"
                                                >{{
                                                  moduleExpertExercices
                                                    .attributes.name
                                                }}</span
                                              >
                                            </p>
                                          </div>
                                          <div class="iconContent">
                                            <svg
                                              class="toggleArrow"
                                              v-if="
                                                moduleExpertExercices.id ===
                                                idAudio
                                              "
                                              width="32"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                              fill="#239380"
                                            >
                                              <path
                                                fill="#fff"
                                                d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                                              />
                                              <path
                                                fill="#fff"
                                                d="M14.293 14.293a.999.999 0 01-1.414 0L10 11.414l-2.879 2.879a.999.999 0 11-1.414-1.414L8.586 10 5.707 7.121a.999.999 0 111.414-1.414L10 8.586l2.879-2.879a.997.997 0 011.414 0 .999.999 0 010 1.414L11.414 10l2.879 2.879a.999.999 0 010 1.414z"
                                              />
                                            </svg>
                                            <img
                                              v-else
                                              class="toggleArrow"
                                              src="../../../assets/img/playButton.png"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="PlacementMoniteurExpert"
                                        v-if="
                                          moduleExpertExercices.id === idAudio
                                        "
                                      >
                                        <ChartFull
                                          id="chart-component"
                                          v-on:moniteur-finish="
                                            MoniteurFinished
                                          "
                                          :Inspi="2750"
                                          :flux="
                                            moduleExpertExercices.attributes
                                              .flux
                                          "
                                          :avecGuide="
                                            moduleExpertExercices.attributes
                                              .avecGuide
                                          "
                                          :Expi="2750"
                                          :avecCapteur="
                                            moduleExpertExercices.attributes
                                              .avecCapteur
                                          "
                                          :avecParametres="
                                            moduleExpertExercices.attributes
                                              .avecParametres
                                          "
                                          :pcm="
                                            moduleExpertExercices.attributes.pcm
                                          "
                                          :MoniteurForRessources="true"
                                          :tableau="[]"
                                          :temps="5000"
                                          :game_type="false"
                                          :titleAudioMoniteur="
                                            moduleExpertExercices.attributes
                                              .name
                                          "
                                          :sourceAudioMoniteur="
                                            moduleExpertExercices.attributes
                                              .nomfichieraudio
                                          "
                                          :timerAudioMoniteur="
                                            moduleExpertExercices.attributes
                                              .duration
                                          "
                                          :fileReçu="moduleExpertExercices"
                                          :folderIdReçu="moduleExpertExercices"
                                        >
                                        </ChartFull>
                                        <!-- <div style="display: flex">
                                            <button
                                              style="
                                                margin-left: auto;
                                                margin-right: auto;
                                                color: white;
                                                background-color: #e0ab4a !important;
                                                width: 30%;
                                              "
                                              class="buttonChart"
                                              v-on:click="
                                                DisplayResult = !DisplayResult
                                              "
                                            >
                                              Voir mes résultats
                                            </button>
                                          </div>
                                          <app-suivieCc
                                            v-if="DisplayResult"
                                            :utilisateurs="UserForRelecture"
                                            :userSelected="tous_variable"
                                            :ResultatsRessources="true"
                                            :folders="folders"
                                            :IUserSelected="UserSelected"
                                            :userParticulier="
                                              ouvertureSuiviCcUtilisateur
                                            "
                                            :fileCCId="moduleExpertExercices.id"
                                            :idprotocole="
                                              moduleExpertExercices.attributes
                                                .sub_name
                                            "
                                          /> -->
                                      </div>
                                    </div>
                                    <div v-else-if="moduleExpertExercices">
                                      <div
                                        v-on:click="
                                          recupIdAudio(
                                            moduleExpertExercices.id === idAudio
                                              ? null
                                              : moduleExpertExercices
                                          ),
                                            startCounterFreeAccount()
                                        "
                                        class="infoBoxExo"
                                        v-bind:style="{
                                          backgroundColor:
                                            moduleExpertExercices.id === idAudio
                                              ? '#489181'
                                              : 'white',
                                          color:
                                            moduleExpertExercices.id === idAudio
                                              ? 'white'
                                              : 'black',
                                        }"
                                      >
                                        <div class="flexWrapper">
                                          <div class="textContent">
                                            <p class="TextSeanceResponsive">
                                              <span>
                                                <img
                                                  class="IconExercice IconMoniteur"
                                                  src="../../../assets/img/IconMoniteur.png"
                                              /></span>
                                              <span
                                                v-bind:style="{
                                                  color:
                                                    moduleExpertExercices.id ===
                                                    idAudio
                                                      ? 'white'
                                                      : '',
                                                }"
                                                class="session-title"
                                                >{{
                                                  moduleExpertExercices
                                                    .attributes.name
                                                }}</span
                                              >
                                            </p>
                                          </div>
                                          <div class="iconContent">
                                            <svg
                                              class="toggleArrow"
                                              v-if="
                                                moduleExpertExercices.id ===
                                                idAudio
                                              "
                                              width="32"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                              fill="#239380"
                                            >
                                              <path
                                                fill="#fff"
                                                d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                                              />
                                              <path
                                                fill="#fff"
                                                d="M14.293 14.293a.999.999 0 01-1.414 0L10 11.414l-2.879 2.879a.999.999 0 11-1.414-1.414L8.586 10 5.707 7.121a.999.999 0 111.414-1.414L10 8.586l2.879-2.879a.997.997 0 011.414 0 .999.999 0 010 1.414L11.414 10l2.879 2.879a.999.999 0 010 1.414z"
                                              />
                                            </svg>
                                            <img
                                              v-else
                                              class="toggleArrow"
                                              src="../../../assets/img/playButton.png"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="PlacementMoniteurExpert"
                                        v-if="
                                          moduleExpertExercices.id === idAudio
                                        "
                                      >
                                        <ChartFull
                                          id="chart-component"
                                          v-on:moniteur-finish="
                                            MoniteurFinished
                                          "
                                          :Inspi="2750"
                                          :flux="
                                            moduleExpertExercices.attributes
                                              .flux
                                          "
                                          :avecGuide="
                                            moduleExpertExercices.attributes
                                              .avecGuide
                                          "
                                          :Expi="2750"
                                          :avecCapteur="
                                            moduleExpertExercices.attributes
                                              .avecCapteur
                                          "
                                          :avecParametres="
                                            moduleExpertExercices.attributes
                                              .avecParametres
                                          "
                                          :pcm="
                                            moduleExpertExercices.attributes.pcm
                                          "
                                          :tableau="[]"
                                          :temps="0"
                                          :MoniteurForRessources="true"
                                          :idPatientTherapeute="
                                            idPatientTherapeute
                                          "
                                          :fileReçu="moduleExpertExercices"
                                          :folderIdReçu="moduleExpertExercices"
                                        ></ChartFull>
                                        <!-- <div style="display: flex">
                                            <button
                                              style="
                                                margin-left: auto;
                                                margin-right: auto;
                                                color: white;
                                                background-color: #e0ab4a !important;
                                                width: 30%;
                                              "
                                              class="buttonChart"
                                              v-on:click="
                                                DisplayResult = !DisplayResult
                                              "
                                            >
                                              Voir mes résultats
                                            </button>
                                          </div> -->
                                        <!-- <app-suivieCc
                                            v-if="DisplayResult"
                                            :utilisateurs="UserForRelecture"
                                            :userSelected="tous_variable"
                                            :ResultatsRessources="true"
                                            :folders="folders"
                                            :IUserSelected="UserSelected"
                                            :userParticulier="
                                              ouvertureSuiviCcUtilisateur
                                            "
                                            :fileCCId="moduleExpertExercices.id"
                                            :idprotocole="
                                              moduleExpertExercices.attributes
                                                .sub_name
                                            "
                                          /> -->
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    v-if="jeuxExercices"
                                    v-for="(n, i) in jeuxExercices"
                                    :key="i"
                                  >
                                    <div
                                      v-on:click="
                                        recupIdGame(
                                          jeuxExercices[i].id === idGame
                                            ? null
                                            : jeuxExercices[i].id,
                                          jeuxExercices[i]
                                        ),
                                          startCounterFreeAccount()
                                      "
                                      class="infoBoxExo"
                                      v-bind:style="{
                                        backgroundColor:
                                          jeuxExercices[i].id === idGame
                                            ? '#489181'
                                            : 'white',
                                        color:
                                          jeuxExercices[i].id === idGame
                                            ? 'white'
                                            : 'black',
                                      }"
                                      style="cursor: pointer !important"
                                    >
                                      <div class="flexWrapper">
                                        <div class="textContent">
                                          <p class="TextSeanceResponsive">
                                            <span>
                                              <img
                                                v-if="
                                                  jeuxExercices[i].id === idGame
                                                "
                                                class="IconExercice IconJeu"
                                                src="../../../assets/img/IconJeuWhite.png" />
                                              <img
                                                v-else
                                                class="IconExercice IconJeu"
                                                src="../../../assets/img/IconJeu.png"
                                            /></span>
                                            <span
                                              v-bind:style="{
                                                color:
                                                  jeuxExercices[i].id === idGame
                                                    ? 'white'
                                                    : '',
                                              }"
                                              class="session-title"
                                              >{{
                                                jeuxExercices[i].attributes
                                                  .sub_name
                                              }}</span
                                            >
                                            <span
                                              class="date-text"
                                              v-if="displaySuiviDataUser"
                                            >
                                              {{
                                                suiviDataUser[
                                                  jeuxExercices[i].id
                                                ]
                                                  ? "( fait le : " +
                                                    new Date(
                                                      suiviDataUser[
                                                        jeuxExercices[i].id
                                                      ].LastDate
                                                    ).toLocaleString("fr-FR", {
                                                      dateStyle: "full",
                                                      timeStyle: "short",
                                                    }) +
                                                    " )"
                                                  : ""
                                              }}
                                            </span>
                                            <span
                                              v-else
                                              class="date-text loading-dots"
                                            >
                                              .</span
                                            >
                                          </p>
                                        </div>
                                        <div class="iconContent">
                                          <svg
                                            class="toggleArrow"
                                            v-if="jeuxExercices.id === idAudio"
                                            width="32"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="#239380"
                                          >
                                            <path
                                              fill="#fff"
                                              d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                                            />
                                            <path
                                              fill="#fff"
                                              d="M14.293 14.293a.999.999 0 01-1.414 0L10 11.414l-2.879 2.879a.999.999 0 11-1.414-1.414L8.586 10 5.707 7.121a.999.999 0 111.414-1.414L10 8.586l2.879-2.879a.997.997 0 011.414 0 .999.999 0 010 1.414L11.414 10l2.879 2.879a.999.999 0 010 1.414z"
                                            />
                                          </svg>
                                          <img
                                            v-else
                                            class="toggleArrow"
                                            src="../../../assets/img/playButton.png"
                                          />
                                        </div>
                                      </div>
                                      <!-- <button>{{ jeuxExercices[i].id }}</button> -->
                                    </div>
                                    <div
                                      v-if="idGame == jeuxExercices[i].id"
                                      id="GameUnity"
                                    >
                                      <div
                                        ref="'JeuUnity'"
                                        class="center-content"
                                        :key="jeuxExercices[i].id"
                                      >
                                        <!--  <JeuUnityComponentVue  :idUserForSave="idUserForSave"
                                  :idPatientTherapeute="idPatientTherapeute"
                                          v-on:game-finish="GameFinished"
                                          :Version="
                                            jeuxExercices[i].attributes
                                              .game_type.attributes.version
                                          "
                                          :Difficulte="
                                            jeuxExercices[i].attributes
                                              .difficulte
                                          "
                                          :Distance="
                                            jeuxExercices[i].attributes.distance
                                          "
                                          :Timer="
                                            jeuxExercices[i].attributes.temps
                                          "
                                          :pcm="jeuxExercices[i].attributes.pcm"
                                          :Flux="
                                            jeuxExercices[i].attributes.flux
                                          "
                                          :Parametre="
                                            jeuxExercices[i].attributes
                                              .avecParametres
                                          "
                                          :Musique="
                                            jeuxExercices[i].attributes
                                              .repertoireMusique
                                          "
                                          :tableauModification="
                                            jeuxExercices[i].attributes
                                              .tableauModification
                                          "
                                          :Capteur="
                                            jeuxExercices[i].attributes
                                              .avecCapteur
                                          "
                                          :Guide="
                                            jeuxExercices[i].attributes
                                              .avecGuide
                                          "
                                        > 
                                        </JeuUnityComponentVue> -->
                                        <JeuUnityComponentVue2
                                          :jeux="jeuxExercices[i]"
                                          v-on:game-finish="GameFinished"
                                          :idUserForSave="idUserForSave"
                                          :idPatientTherapeute="
                                            idPatientTherapeute
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div
                              v-else-if="folderBrique.id === '4ydeuBgcuu'"
                              class="notification-bubble-android"
                            >
                              <p>
                                Android n'est pas pris en charge pour le
                                biofeedback de cohérence cardiaque, veuillez
                                utiliser un appareil Windows ou Mac si vous
                                souhaitez l'utiliser
                              </p>
                            </div>
                            <div v-if="folderBrique">
                              <!--!folderBrique.name.includes('hypnotique') && !folderBrique.name.includes('cardiaque') &&
                                  !folderBrique.name.includes('Tests') &&
                                  !folderBrique.name.includes('Soft') &&
                                  !folderBrique.name.includes('Vie')-->
                              <div>
                                <div>
                                  <div v-if="isExercicecourt">
                                    <div>
                                      <div ref="targetDiv">
                                        <div
                                          :class="{}"
                                          v-for="shortExercises in shortExercises"
                                          :key="shortExercises.id"
                                        >
                                          <div>
                                            <div
                                              v-on:click="
                                                recupIdAudio(
                                                  shortExercises.id === idAudio
                                                    ? null
                                                    : shortExercises
                                                ),
                                                  startCounterFreeAccount()
                                              "
                                              class="infoBoxExo"
                                              v-bind:style="{
                                                backgroundColor:
                                                  shortExercises.id === idAudio
                                                    ? '#489181'
                                                    : 'white',
                                                color:
                                                  shortExercises.id === idAudio
                                                    ? 'white'
                                                    : 'black',
                                              }"
                                              style="cursor: pointer !important"
                                            >
                                              <div class="flexWrapper">
                                                <div class="textContent">
                                                  <p
                                                    class="TextSeanceResponsive"
                                                  >
                                                    <span
                                                      ><img
                                                        v-if="
                                                          shortExercises.id ===
                                                          idAudio
                                                        "
                                                        class="IconExercice IconAudioExercice"
                                                        src="../../../assets/img/IconAudioWhite.png" />
                                                      <img
                                                        v-else
                                                        class="IconExercice IconAudioExercice"
                                                        src="../../../assets/img/IconAudio.png"
                                                    /></span>
                                                    <span
                                                      v-bind:style="{
                                                        color:
                                                          shortExercises.id ===
                                                          idAudio
                                                            ? 'white'
                                                            : '',
                                                      }"
                                                      class="session-title"
                                                      >{{
                                                        shortExercises
                                                          .attributes.sub_name
                                                      }}</span
                                                    >
                                                    <span
                                                      v-bind:style="{
                                                        color:
                                                          shortExercises.id ===
                                                          idAudio
                                                            ? 'white'
                                                            : '',
                                                      }"
                                                    >
                                                      {{
                                                        shortExercises
                                                          .attributes.duration
                                                      }}
                                                    </span>
                                                  </p>
                                                </div>
                                                <div class="iconContent">
                                                  <svg
                                                    class="toggleArrow"
                                                    v-if="
                                                      shortExercises.id ===
                                                      idAudio
                                                    "
                                                    width="32"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="#239380"
                                                  >
                                                    <path
                                                      fill="#fff"
                                                      d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                                                    />
                                                    <path
                                                      fill="#fff"
                                                      d="M14.293 14.293a.999.999 0 01-1.414 0L10 11.414l-2.879 2.879a.999.999 0 11-1.414-1.414L8.586 10 5.707 7.121a.999.999 0 111.414-1.414L10 8.586l2.879-2.879a.997.997 0 011.414 0 .999.999 0 010 1.414L11.414 10l2.879 2.879a.999.999 0 010 1.414z"
                                                    />
                                                  </svg>
                                                  <img
                                                    v-else
                                                    class="toggleArrow"
                                                    src="../../../assets/img/playButton.png"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              class="PlacementAudioRessourcesLibres"
                                              v-if="
                                                idAudio == shortExercises.id
                                              "
                                            >
                                              <audioPlayer
                                                @close="handleCloseAudio"
                                                v-on:audio-finish="
                                                  AudioFinished
                                                "
                                                :titleAudio="
                                                  shortExercises.attributes
                                                    .sub_name
                                                "
                                                :sourceAudio="
                                                  shortExercises.attributes
                                                    .nomfichieraudio
                                                "
                                                :timerAudio="
                                                  shortExercises.attributes
                                                    .duration
                                                "
                                                :timerForRessources="false"
                                                v-on:current-time-update="
                                                  updateCurrentTime
                                                "
                                                @update-number="update"
                                              ></audioPlayer>
                                              <div class="PlacementBoutonAudio">
                                                <button
                                                  style="
                                                    background-color: #489181;
                                                  "
                                                  class="buttonVideo"
                                                  v-on:click="
                                                    recupInfo(shortExercises.id)
                                                  "
                                                >
                                                  + d'infos
                                                </button>
                                                <button
                                                  class="buttonVideo"
                                                  id="TerminerExercice"
                                                  v-on:click="
                                                    TerminerExercice(
                                                      shortExercises.id
                                                    )
                                                  "
                                                >
                                                  Terminer
                                                </button>
                                                <a
                                                  class="buttonVideo"
                                                  style="color: #008000"
                                                  v-if="
                                                    shortExercises.attributes
                                                      .resource_file
                                                  "
                                                  :href="
                                                    shortExercises.attributes
                                                      .resource_file._name
                                                  "
                                                  download
                                                >
                                                  télécharger la fiche
                                                </a>
                                              </div>
                                              <div
                                                v-if="
                                                  idProtocol ==
                                                    folderBrique.id &&
                                                  shortExercises.id == idInfo &&
                                                  displayInfo
                                                "
                                                class="bubblediv"
                                              >
                                                <div class="bubble">
                                                  <button
                                                    v-on:click="
                                                      displayInfo = !displayInfo
                                                    "
                                                    style="
                                                      margin-left: auto;
                                                      margin-right: 1%;
                                                      margin-top: 1%;
                                                    "
                                                    type="button"
                                                    class="btn-close"
                                                  >
                                                    <span
                                                      class="icon-cross"
                                                    ></span>
                                                    <span
                                                      class="visually-hidden"
                                                    ></span>
                                                  </button>
                                                  <p
                                                    v-html="
                                                      shortExercises.attributes
                                                        .text
                                                    "
                                                    style="margin: 2%"
                                                  ></p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-if="folderBrique.protocoles[3]">
                                  <div v-if="isExercicelong">
                                    <div>
                                      <div>
                                        <div
                                          :class="{}"
                                          v-for="longExercises in longExercises"
                                          :key="longExercises.id"
                                          class="is-margin-t-10"
                                        >
                                          <div v-if="longExercises">
                                            <div>
                                              <div
                                                v-on:click="
                                                  recupIdAudio(
                                                    longExercises.id === idAudio
                                                      ? null
                                                      : longExercises
                                                  ),
                                                    startCounterFreeAccount()
                                                "
                                                class="infoBoxExo"
                                                v-bind:style="{
                                                  backgroundColor:
                                                    longExercises.id === idAudio
                                                      ? '#489181'
                                                      : 'white',
                                                  color:
                                                    longExercises.id === idAudio
                                                      ? 'white'
                                                      : 'black',
                                                }"
                                                style="
                                                  cursor: pointer !important;
                                                "
                                              >
                                                <div class="flexWrapper">
                                                  <div class="textContent">
                                                    <p
                                                      class="TextSeanceResponsive"
                                                    >
                                                      <span
                                                        ><img
                                                          class="IconExercice IconAudioExercice"
                                                          v-if="
                                                            longExercises.id ===
                                                            idAudio
                                                          "
                                                          src="../../../assets/img/IconAudioWhite.png" />
                                                        <img
                                                          v-else
                                                          class="IconExercice IconAudioExercice"
                                                          src="../../../assets/img/IconAudio.png"
                                                      /></span>
                                                      <span
                                                        v-bind:style="{
                                                          color:
                                                            longExercises.id ===
                                                            idAudio
                                                              ? 'white'
                                                              : '',
                                                        }"
                                                        class="session-title"
                                                        >{{
                                                          longExercises
                                                            .attributes
                                                            .sub_name +
                                                          " - " +
                                                          longExercises
                                                            .attributes.duration
                                                        }}</span
                                                      >
                                                    </p>
                                                  </div>
                                                  <div class="iconContent">
                                                    <svg
                                                      class="toggleArrow"
                                                      v-if="
                                                        longExercises.id ===
                                                        idAudio
                                                      "
                                                      width="32"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 20 20"
                                                      fill="#239380"
                                                    >
                                                      <path
                                                        fill="#fff"
                                                        d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                                                      />
                                                      <path
                                                        fill="#fff"
                                                        d="M14.293 14.293a.999.999 0 01-1.414 0L10 11.414l-2.879 2.879a.999.999 0 11-1.414-1.414L8.586 10 5.707 7.121a.999.999 0 111.414-1.414L10 8.586l2.879-2.879a.997.997 0 011.414 0 .999.999 0 010 1.414L11.414 10l2.879 2.879a.999.999 0 010 1.414z"
                                                      />
                                                    </svg>
                                                    <img
                                                      v-else
                                                      class="toggleArrow"
                                                      src="../../../assets/img/playButton.png"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="PlacementAudioRessourcesLibres"
                                                v-if="
                                                  idAudio == longExercises.id
                                                "
                                              >
                                                <!-- <div>{{ currentTime }}</div> -->

                                                <audioPlayer
                                                  @close="handleCloseAudio"
                                                  v-on:audio-finish="
                                                    AudioFinished
                                                  "
                                                  :titleAudio="
                                                    longExercises.attributes
                                                      .sub_name
                                                  "
                                                  :sourceAudio="
                                                    longExercises.attributes
                                                      .nomfichieraudio
                                                  "
                                                  :timerAudio="
                                                    longExercises.attributes
                                                      .duration
                                                  "
                                                  :timerForRessources="false"
                                                  @update-number="update"
                                                  v-on:current-time-update="
                                                    updateCurrentTime
                                                  "
                                                ></audioPlayer>
                                                <div
                                                  class="PlacementBoutonAudio"
                                                >
                                                  <button
                                                    style="
                                                      background-color: #489181;
                                                    "
                                                    class="buttonVideo"
                                                    v-on:click="
                                                      recupInfo(
                                                        longExercises.id
                                                      )
                                                    "
                                                  >
                                                    + d'infos
                                                  </button>
                                                  <button
                                                    class="buttonVideo"
                                                    id="TerminerExercice"
                                                    v-on:click="
                                                      TerminerExercice(
                                                        longExercises.id
                                                      )
                                                    "
                                                  >
                                                    Terminer
                                                  </button>
                                                  <a
                                                    class="buttonVideo"
                                                    style="color: #008000"
                                                    v-if="
                                                      longExercises.attributes
                                                        .resource_file
                                                    "
                                                    :href="
                                                      longExercises.attributes
                                                        .resource_file._name
                                                    "
                                                    download
                                                  >
                                                    télécharger la fiche
                                                  </a>
                                                </div>
                                                <div
                                                  v-if="
                                                    idProtocol ==
                                                      folderBrique.id &&
                                                    longExercises.id ==
                                                      idInfo &&
                                                    displayInfo
                                                  "
                                                  class="bubblediv"
                                                >
                                                  <div class="bubble">
                                                    <button
                                                      v-on:click="
                                                        displayInfo =
                                                          !displayInfo
                                                      "
                                                      style="
                                                        margin-left: auto;
                                                        margin-right: 1%;
                                                        margin-top: 1%;
                                                      "
                                                      type="button"
                                                      class="btn-close"
                                                    >
                                                      <span
                                                        class="icon-cross"
                                                      ></span>
                                                      <span
                                                        class="visually-hidden"
                                                      ></span>
                                                    </button>
                                                    <p
                                                      v-html="
                                                        longExercises.attributes
                                                          .text
                                                      "
                                                      style="margin: 2%"
                                                    ></p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import informationActiviteComponent from "./activites/informationActivite.vue";

import NewAniChart from "../components/ani/NewAniChart.vue";

import audioPlayer from "@/components/audioPlayer.vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import ChartFull from "@/components/ChartFull.vue";
// import JeuUnityComponentVue from "./JeuUnityComponent.vue";
import { createNamespacedHelpers } from "vuex";
const User = createNamespacedHelpers("user");
import { getExercicesRessources } from "@/services/folders/getExercicesRessources.js";
import suiviUtilisateurComponent from "./utilisateur/suiviUtilisateur.vue";
import { getFilesDoneByUserId } from "@/services/folders/getFilesDoneByUserId.js";
import suivieCc from "./tableauDeBord/SuivieCc.vue";
import { isAndroid } from "@/services/device/deviceService";
import { getSuiviForUser } from "@/services/utilisateurs/getSuiviLibreForUser.js";
import { getMostRecentExerciseOfTypeForUser } from "@/services/utilisateurs/getMostRecentExerciseOfTypeForUser.js";

import relectureUser from "./relecture/relectureUser.vue";
import subscriptionPopUp from "@/components/subscriptionPopUp.vue";
import CarouselFolders from "@/components/accueilV2/CarouselFolders.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

import relectureAni from "./relecture/relectureAni.vue";
export default {
  name: "folders",
  props: [
    "foldersBrique",
    "isPageUser",
    "isPageSessionGroup",
    "themes",
    "idPatientTherapeute",
    "ParamJeu",
  ],
  components: {
    informationActiviteComponent: informationActiviteComponent,
    audioPlayer,
    ChartFull,
    JeuUnityComponentVue: () => import("@/components/JeuUnityComponent"),
    JeuUnityComponentVue2: () => import("@/components/JeuUnityComponent2"),
    JeuUnityComponentVue3: () => import("@/components/JeuUnityComponent3"),
    suiviUtilisateurComponent,
    appSuivieCc: suivieCc,
    relectureUser,
    subscriptionPopUp,
    CarouselFolders,
    newAniChart: NewAniChart,
    relectureAni,
  },
  data() {
    return {
      displaySuiviDataUser: false,
      displayInfo: false,
      keyGame: 0,
      isAndroid: false,
      tous_variable: "tous",
      isCourtClicked: false,
      isLongClicked: false,
      isDisplay: false,
      DisplayResult: false,
      descriptionSelected: "",
      isExercicecourt: true,
      isExercicelong: false,
      descriptionPro: null,
      idProtocol: null,
      idSeance: "",
      idExercice: "",
      showNotification: false,
      idInfo: "",
      IntroSelected: "",
      idBriqueSelected: "",
      Active: false,
      isDisableText: false,
      audioURL: "",
      zoom: false,
      AudioAffichage: false,
      background: false,
      element_folder2: [],
      element_link2: [],
      element_file2: [],
      element_composite_file2: [],
      suiviDataAni: [],
      element_game_type: [],
      tableau_folder2: [],
      tableau_game_type: [],
      currentAudio: null,
      tableau_link2: [],
      modalOpened: false,
      tableau_file2: [],
      tableau_composite_file2: [],
      titleAudio: "",
      sourceAudio: "",
      timerAudio: "",
      ModuleRecupByBrique: [],
      idGame: "",
      idAudio: "",
      objectIdPatient: "",
      affichagePatientBloc: false,
      DisplaySeances: false,
      SeanceSelected: "",
      element_folder_for_exercice: [],
      compositeFile: [],
      elapsedTime: 0,
      isLoading: false,
      timeInGame: 0,
      ModuleByBriqueSelected: [],
      shortExercises: [],
      longExercises: [],
      moduleExpertExercices: [],
      jeuxExercices: [],
      parentFolderId: "",
      idUserForSave: null,
      UtilisateurEnCours: "",
      UserSelected: "",
      folders_for_relecture: [],
      ouvertureSuiviCcUtilisateur: "",
      UserForRelecture: "",
      currentTime: 0,
      suiviData: [],
      selectedUser: null,
      displaySuiviData: false,
      RelectureRessourcesLibres: true,
      showModalSubscription: false,
      indexPopUpAbonnement: 0,
      abonnementFinish: false,
      indexRessources: 2,
      timeGame: 0,
      firstGameChoose: null,
      showRelectureAni: false,
      suiviDataUser: [],
    };
  },
  async created() {
    //console.log('Création du composant RessourcesFolders2')
    console.log("ParamJeuReçu : ", this.ParamJeu);
    if (this.ParamJeu) {
      this.getParamJeu(this.ParamJeu);
    }
    this.isAndroid = await isAndroid();
    this.openLoading();
    this.verifAbonnement();
    this.idUserForSave = this.getCookie("idUserForSave");
    this.findDataForRelecture();
    // const objectUser = Parse.Object.extend("User");
    // const queryUser = new Parse.Query(objectUser);
    // queryUser.equalTo("objectId", this.idPatientTherapeute);
    // queryUser.include("link");
    // const user = queryUser.find().then((e) => {
    //   this.element_user_local = e;
    //   if (
    //     this.element_user_local[0] &&
    //     this.element_user_local[0].attributes.link
    //   ) {
    //     const relation = this.element_user_local[0].get("link");
    //     const relationQuery = relation.query();
    //     relationQuery.exists("parent_folder");
    //     relationQuery.include("folder");
    //     //console.log("In Ressources..");
    //     // relationQuery.exists('folder.attributes.theme');
    //     relationQuery.find().then((linkedObjects) => {
    //       linkedObjects.forEach((linkedObject) => {
    //         if (
    //           (linkedObject.attributes.folder &&
    //             linkedObject.attributes.folder.attributes.name ===
    //               "Entraînement libre") ||
    //           (linkedObject.attributes.folder &&
    //             linkedObject.attributes.folder.attributes.name ===
    //               "DÉCOUVRIR la COHÉRENCE CARDIAQUE")
    //         ) {
    //           //console.log(linkedObject.attributes.folder.attributes.name);
    //           //console.log(linkedObject.attributes.folder);
    //           //console.log(linkedObject);
    //           //console.log(
    //             linkedObject.attributes.parent_folder.attributes.name
    //           );
    //           //console.log("*********************************");
    //           this.linkedObjectPatient.push(
    //             linkedObject.attributes.parent_folder.id
    //           );
    //         }
    //       });
    //     });
    //   }
    // });
    //console.log("Fin de la création des ressources...");
  },
  methods: {
    update(titleAudio, sourceAudio, timerAudio) {
      this.titleAudio = titleAudio;
      this.sourceAudio = sourceAudio;
      this.timerAudio = timerAudio;
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    async getParamJeu(game) {
      try {
        console.log("ParamJeu in getParamJeu : ", game);

        // Recherchez le folder avec le name "Biofeedback de cohérence cardiaque"
        const folder = this.folders.find(
          (folder) => folder.name === "Biofeedback de cohérence cardiaque"
        );

        if (folder) {
          console.log("Folder trouvé : ", folder);
          this.recupId(folder);
          // Maintenant, vous pouvez continuer avec le reste de votre code ici en utilisant le folder trouvé.
          if (game === "le_jardin") {
            var NameOfTheGame = "Le jardin";
          } else if (game === "la_plage") {
            var NameOfTheGame = "La plage";
          } else if (game === "le_safari") {
            var NameOfTheGame = "Le safari";
          } else if (game === "l_aviron") {
            var NameOfTheGame = "L'aviron";
          }
          console.log("NameOfTheGame : ", NameOfTheGame);

          setTimeout(() => {
            console.log(this.jeuxExercices);
            //récupérer le jeux Exercices ayant le nom "NameOfTheGame"
            const jeuxExercice = this.jeuxExercices.find(
              (jeuxExercice) =>
                jeuxExercice.attributes.sub_name === NameOfTheGame
            );
            console.log(jeuxExercice);
            this.recupIdGame(jeuxExercice.id, jeuxExercice);
            setTimeout(() => {
              console.log("goTo");
              this.GoToTheGame();
            }, 1000);
          }, 3000);
        } else {
          console.log("Le folder n'a pas été trouvé.");
        }
      } catch (error) {
        // Gérer les erreurs si elles se produisent
        console.error("Une erreur s'est produite : ", error);
      }
    },
    scrollToTarget() {
      // Récupérez la référence vers l'élément cible en utilisant "this.$refs"
      const targetElement = this.$refs.targetDiv;

      // Vérifiez si l'élément cible existe
      if (targetElement) {
        // Faites défiler jusqu'à l'élément cible en utilisant scrollIntoView()
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    updateCurrentTime(currentTime) {
      this.currentTime = currentTime;
    },
    updateKey(key) {
      this.indexRessources++;
    },
    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    },
    verifAbonnement() {
      var user = Parse.User.current();
      //console.log("User récupére: ", user);
      var userTypeAbonnement = user.get("group").get("type_abonnement");
      var userFinAbonnement = user.get("group").get("subscription_end_date");
      //console.log("userTypeAbonnement: ", userTypeAbonnement);
      //console.log("userFinAbonnement: ", userFinAbonnement);
      var today = new Date();
      if (userTypeAbonnement === "gratuit" && userFinAbonnement < today) {
        this.abonnementFinish = true;
      } else {
        this.abonnementFinish = false;
      }
    },
    async findDataForRelecture() {
      const objectUserForRelecture = Parse.Object.extend("User");
      const QueryUserForRelecture = new Parse.Query(objectUserForRelecture);
      if (this.idPatientTherapeute) {
        QueryUserForRelecture.equalTo("objectId", this.idPatientTherapeute);
      } else if (this.idUserForSave) {
        QueryUserForRelecture.equalTo("objectId", this.idUserForSave);
      } else {
        QueryUserForRelecture.equalTo("objectId", this.user.objectId);
      }
      this.UserForRelecture = await QueryUserForRelecture.find();
      this.UserForRelecture = this.UserForRelecture[0];
      this.UserSelected = this.user;
      this.folders_for_relecture = this.folders;

      this.UserSelected.filesDone = await getFilesDoneByUserId(
        this.user.objectId
      );
      if (this.UserSelected.therapeute) {
        this.UserSelected.typePratique = "Thérapeute";
        this.UserSelected.acces_donnees_therapeute = true;
      }
    },
    async openSuivi() {
      ParseConfig();
      if (this.DisplayResult) {
        this.DisplayResult = false;
        return;
      }
      this.DisplayResult = true;
      var User = Parse.User.current();
      this.selectedUser = null;
      this.displaySuiviData = false;
      this.suiviData = null;
      try {
        // Charger les données de suivi
        if (this.idPatientTherapeute) {
          this.suiviData = await getSuiviForUser(this.idPatientTherapeute);
        } else if (this.idUserForSave) {
          this.suiviData = await getSuiviForUser(this.idUserForSave);
        } else {
          this.suiviData = await getSuiviForUser(User.id);
        }
        this.selectedUser = User;
        this.displaySuiviData = true;
      } catch (error) {
        // Gérer les erreurs de chargement des données
        console.error("Erreur lors du chargement des données de suivi:", error);
      }
    },
    async openSuiviAni() {
      console.log("OpenSuiviAni");
      if (this.showRelectureAni) {
        this.showRelectureAni = false;
      } else {
        this.showRelectureAni = true;
      }
    },
    openModalSubscription() {
      console.log("openModalSubscriptionnnnnnnnnnnnn");
      this.showModalSubscription = true;
    },
    closeModalSubscription() {
      console.log("closeModalSubscription");
      this.showModalSubscription = false;
    },
    startCounterFreeAccount() {
      console.log("startCounterFreeAccount");

      // Utilisez setTimeout pour retarder l'exécution
      setTimeout(() => {
        // Vérifiez si la modale a déjà été ouverte
        if (!this.modalOpened) {
          const user = Parse.User.current();
          console.log("user-set-timeout", user);

          // Vérifiez les conditions spécifiques avant d'ouvrir la modale
          if (
            user.attributes.group.attributes.essai_gratuit === false &&
            user.attributes.group.attributes.type_abonnement === "gratuit"
          ) {
            this.recupIdAudio(null);
            this.recupIdGame(null, null);
            this.openModalSubscription();
            this.modalOpened = true; // Marquez que la modale a été ouverte
          }
        }
      }, 20000); // 20 secondes
    },
    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    TerminerExo() {},
    timerExercice() {
      this.exerciseStartTime = Date.now();
      setInterval(() => {
        this.elapsedTime = Math.floor(
          (Date.now() - this.exerciseStartTime) / 1000
        );
      }, 1000);
    },
    startTimer() {
      this.showNotification = true;
      this.progressWidth = 0;
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.progressWidth += 3.33;
        if (this.progressWidth >= 100) {
          clearInterval(this.intervalId);
        }
      }, 100);
      setTimeout(() => {
        this.closeNotification();
      }, 3000);
    },
    closeNotification() {
      this.showNotification = false;
      this.progressWidth = 0;
    },
    recupIdPatient(idLocal) {},
    getFoldersByTheme(monTheme) {
      // console.log("Thême,", monTheme);
      // this.dynamicKey += 1;
      return this.foldersBrique.filter((el) => {
        if (el.themes.length < 2) {
          return el.themes[0].objectId === monTheme;
        }
        return (
          el.themes[0].objectId === monTheme ||
          el.themes[1].objectId === monTheme
        );
      });
    },
    async recupId(folderBriqueSelected) {
      // console.log("folderBriqueSelected", folderBriqueSelected);
      if (folderBriqueSelected == null) {
        //console.log("folderBriqueSelected est null");
        this.idProtocol = null;
        this.shortExercises = [];
        this.longExercises = [];
        this.moduleExpertExercices = [];
        this.jeuxExercices = [];
        this.DisplaySeances = false;
        return;
      }
      this.DisplaySeances = true;
      this.idProtocol = folderBriqueSelected.id;
      // Stocker le résultat de la fonction getExercicesRessources
      //console.log('getExercicesRessources1')
      const exercises = await getExercicesRessources(folderBriqueSelected);
      //console.log('getExercicesRessources2')
      // Utiliser les exercices courts et longs
      this.shortExercises = exercises.shortExercises;
      this.longExercises = exercises.longExercises;
      this.moduleExpertExercices = exercises.moduleExpertExercises;

      this.jeuxExercices = exercises.jeuxExercises;
      console.log("this.jeuxExercices", this.jeuxExercices);
      //trier par order
      this.jeuxExercices.sort(
        (a, b) => a.attributes.order - b.attributes.order
      );
      //console.log("this.shortExercises", this.shortExercises);
      //console.log("this.longExercises", this.longExercises);

      this.scrollToSection();
    },
    callExerciceRessources(BriqueSelected) {
      this.ModuleByBriqueSelected = BriqueSelected.protocoles[3].modules;
    },
    scrollToSection() {
      // console.log('ScrollToSection')
      // setTimeout(() => {
      const section = document.querySelector("#SeanceLocalisation");
      section.scrollIntoView({ behavior: "smooth" });
      // }, 200);
    },
    recupIdSeance(SelectedSeance) {
      this.SeanceSelected = SelectedSeance;
      this.idSeance = SelectedSeance.id;
      if (
        this.SeanceSelected.name === "Indice de cohérence: Moniteur Expert" ||
        this.SeanceSelected.name === "Exercices Audio" ||
        this.SeanceSelected.name === "Serious Games" ||
        this.SeanceSelected.name === "Biofeedback de cohérence cardiaque" ||
        this.SeanceSelected.name === "Exercices Musicaux"
      ) {
        this.callExercice(this.SeanceSelected.id);
      } else {
        this.callData();
      }
    },
    async callExercice(idFolder) {
      this.compositeFile = [];
      const Folder = Parse.Object.extend("folder");
      const Link = Parse.Object.extend("link");
      const GameType = Parse.Object.extend("game_type");
      const queryFolder = new Parse.Query(Folder);
      const queryLink = new Parse.Query(Link);
      const queryGameType = new Parse.Query(GameType);

      queryFolder.equalTo("objectId", idFolder);
      queryLink.matchesQuery("parent_folder", queryFolder);
      queryLink.include("composite_file");

      try {
        const element_gametype = await queryGameType.find();
        const element_folder_for_exercice = await queryLink.find();
        for (const element of element_folder_for_exercice) {
          const composite_file = element.get("composite_file");
          this.compositeFile.push(composite_file);
        }
        this.element_folder_for_exercice = element_folder_for_exercice;
      } catch (error) {
        console.error(error);
      }
    },
    recupInfo(idSelectedInfo) {
      this.displayInfo = !this.displayInfo;
      return (this.idInfo = idSelectedInfo);
    },
    // async openSuivi() {
    //   const Users = this.user;
    //   this.UserSelected = Object.assign({}, Users);
    //   this.UserSelected.filesDone = await getFilesDoneByUserId(
    //     this.user.objectId
    //   );
    //   this.isCardSuiviOpen = true;
    // },
    show_exercice_court() {
      this.isExercicecourt = true;
      this.isExercicelong = false;
      this.isCourtClicked = !this.isCourtClicked;
      this.recupIdSeance("");
      this.recupIdAudio("");
    },
    show_exercice_long() {
      this.isExercicecourt = false;
      this.isExercicelong = true;
      this.isLongClicked = !this.isLongClicked;
      this.recupIdSeance("");
      this.recupIdAudio("");
    },
    AudioFinished(e) {
      this.TerminerExercice(this.idAudio, 0);
    },
    MoniteurFinished(e) {
      this.recupIdAudio(null);
    },
    handleCloseAudio() {
      this.recupIdAudio(null);
    },
    GameFinished(e) {
      // console.log("GameFinished",e);
      // si c'est e.TimeCompletion est égal à 0 il faut prendre e.Timer
      this.timeInGame = e.TimeCompletion === 0 ? e.Timer : e.TimeCompletion;
      //arrondir à l'unité
      this.timeInGame = Math.round(this.timeInGame);
      this.ResultatJeux = JSON.stringify(e);
      this.TerminerExerciceJeu(this.idGame, 0);
    },
    async handleRecupId(folderbrique) {
      this.DisplayResult = false;
      this.showRelectureAni = false;
      console.log("HandleRecup Id reçu..", folderbrique);
      this.recupId(folderbrique);
      this.suiviDataUser = await getMostRecentExerciseOfTypeForUser(
        Parse.User.current().id
      );
      this.displaySuiviDataUser = true;
      // console.log("SuiviDataUser:", this.suiviDataUser["K70jU7ctpU"]);
    },
    async TerminerExercice(fileId, note) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      const objectFolder = Parse.Object.extend("folder");
      const objectFile = Parse.Object.extend("file");
      const objectUser = Parse.Object.extend("User");
      const objetLink = Parse.Object.extend("link");
      const queryFolder = new Parse.Query(objectFolder);
      const queryFolder2 = new Parse.Query(objectFolder);
      const queryUser = new Parse.Query(objectUser);
      const queryFile = new Parse.Query(objectFile);
      const queryLink = new Parse.Query(objetLink);
      queryLink.include("file");
      queryFolder.equalTo("objectId", this.idProtocol);
      queryFolder2.equalTo("objectId", this.SeanceSelected);
      queryFile.equalTo("objectId", fileId);
      //find a link with file
      queryLink.matchesQuery("file", queryFile);
      let brique, module, file, user, link;
      if (this.idPatientTherapeute) {
        queryUser.equalTo("objectId", this.idPatientTherapeute);
      } else if (this.idUserForSave) {
        queryUser.equalTo("objectId", this.idUserForSave);
      } else {
        queryUser.equalTo("objectId", Parse.User.current().id);
      }
      [brique, module, file, user, link] = await Promise.all([
        queryFolder.find(),
        queryFolder2.find(),
        queryFile.find(),
        queryUser.find(),
        queryLink.find(),
      ]);

      const originalDuration = file[0].attributes.duration; // Sauvegardez la valeur d'origine de la duration

      const [minutes, secondes] = originalDuration.split(":");
      const duration = parseInt(minutes) * 60 + parseInt(secondes);
      file[0].set("duration", duration);

      const dataFileDone = {
        brique: brique[0],
        time_elapsed: this.elapsedTime,
        user: user[0],
        module: module[0],
        protocole: module[0],
        resultatJeux: undefined,
        note: note,
        file: file[0],
        comment: undefined,
        group: Parse.User.current().get("group"),
      };

      const Data = Parse.Object.extend("file_done");
      const dataDone = new Data();

      try {
        const result = await dataDone.save(dataFileDone);
        Toast.fire({
          icon: "success",
          title: "Votre exercice a bien été sauvegardé !",
        });
      } catch (error) {
        console.error("Error while creating Data: ", error);
        Toast.fire({
          icon: "error",
          title:
            "Une erreur est survenue lors de la sauvegarde de votre exercice !",
        });
      }
      this.$forceUpdate();
      file[0].set("duration", originalDuration); // Restaurez la valeur d'origine de la duration

      setTimeout(() => {
        if (this.currentAudio.attributes.name === file[0].attributes.name) {
          this.recupIdAudio(null); // this.recupId(this.folderBriqueRecup);
          this.recupIdSeance(this.SeanceSelected);
        }
      }, 2000);
    },
    GoToTheGame() {
      var element = document.getElementById("GameUnity");
      // console.log('élément reçu:',element)
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    testData(e) {
      console.log("testData", e);
    },
    async TerminerExerciceJeu(fileId, note) {
      var queryFile = Parse.Object.extend("file");
      var queryFile_ = new Parse.Query(queryFile);
      queryFile_.equalTo("objectId", fileId);
      queryFile_.include("game_type");
      var file = await queryFile_.first();
      console.log("FILE ID:", fileId, "FILE TROUVE:", file);
      // console.log('file:',file);
      // console.log("TerminerExerciceJeu");
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      this.timeInGame = this.timeInGame / 60;
      //arrondir
      this.timeInGame = Math.round(this.timeInGame);
      // console.log('Temps en jeu:',this.timeInGame);
      // this.timeInGame = Math.round(this.timeInGame);
      console.log("Id :", this.idPatientTherapeute, "Id2:", this.idUserForSave);
      try {
        Parse.Cloud.run(
          "saveExo",
          {
            idProtocol: this.idProtocol,
            SeanceSelected: "8hXg8RQPbd",
            fileId: fileId,
            IdLink: file.id,
            idPatientTherapeute: this.idPatientTherapeute,
            idUserForSave: this.idUserForSave
              ? this.idUserForSave
              : this.idPatientTherapeute,
            elapsedTime: this.timeInGame,
            resultat: this.ResultatJeux,
            note: 0,
            typeExercice: "Jeu",
          },

          {
            success: function (result) {
              //console.log("Sauvegarde effectué !");
            },
            error: function (error) {
              console.error(
                "Une erreur s'est produite lors de la sauvegarde :",
                error
              );
            },
          }
        );
        Toast.fire({
          icon: "success",
          title: "Votre exercice a bien été sauvegardé !",
        });
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Une erreur s'est produite lors de la sauvegarde",
          text: error,
        });
        console.error(
          "Une erreur s'est produite lors de la sauvegarde :",
          error
        );
      }
      setTimeout(() => {
        this.recupIdGame(null, null);
      }, 2000);
    },
    async TerminerExerciceJeu2(fileId, note) {
      const objectFolder = Parse.Object.extend("folder");
      const objectFile = Parse.Object.extend("file");
      const objectUser = Parse.Object.extend("User");
      const objetLink = Parse.Object.extend("link");
      const queryFolder = new Parse.Query(objectFolder);
      const queryFolder2 = new Parse.Query(objectFolder);
      const queryUser = new Parse.Query(objectUser);
      const queryFile = new Parse.Query(objectFile);
      const queryLink = new Parse.Query(objetLink);
      queryLink.include("file");
      queryFolder.equalTo("objectId", this.idProtocol);
      queryFolder2.equalTo("objectId", "8hXg8RQPbd");
      queryFile.equalTo("objectId", fileId);
      queryLink.matchesQuery("file", queryFile);
      // console.log('Temps en jeu:',this.timeInGame);
      //passer le temps en minutes
      this.timeInGame = this.timeInGame / 60;
      let brique, module, file, user, link;
      if (this.idPatientTherapeute) {
        // console.log('idPatientTherapeute:',this.idPatientTherapeute);
        queryUser.equalTo("objectId", this.idPatientTherapeute);
      } else if (this.idUserForSave) {
        // console.log('idUserForSave:',this.idUserForSave);
        queryUser.equalTo("objectId", this.idUserForSave);
      } else {
        // console.log('idCurrentUser:',Parse.User.current().id);
        queryUser.equalTo("objectId", Parse.User.current().id);
      }
      [brique, module, file, user, link] = await Promise.all([
        queryFolder.find(),
        queryFolder2.find(),
        queryFile.find(),
        queryUser.find(),
        queryLink.find(),
      ]);
      const dataFileDone = {
        brique: brique[0],
        time_elapsed: this.timeInGame,
        user: user[0],
        module: module[0],
        protocole: module[0],
        resultatJeux: this.ResultatJeux,
        note: note,
        file: file[0],
        comment: undefined,
        group: Parse.User.current().get("group"),
      };
      const Data = Parse.Object.extend("file_done");
      const dataDone = new Data();
      try {
        // console.log('dataFileDone:',dataFileDone)
        const result = await dataDone.save(dataFileDone);
        this.showNotification = true;
        this.startTimer();
      } catch (error) {
        console.error("Error while creating Data: ", error);
      }
      this.$forceUpdate();
      setTimeout(() => {
        this.recupIdGame(null, null);
      }, 2000);
    },
    isAppleDevice() {
      const userAgent = window.navigator.userAgent;
      const platforms = ["iPhone", "iPad"];
      return platforms.some((platform) => userAgent.includes(platform));
    },
    recupIdGame(idSelectedGame, SelectedGame) {
      if (this.idSelectedGame === null && this.SelectedGame === null) {
        return;
      }
      console.log(
        "Id sélectionné:",
        idSelectedGame,
        "Jeu sélectionné:",
        SelectedGame
      );
      var isApple = this.isAppleDevice();
      if (isApple) {
        console.log("SelectedGame", SelectedGame.attributes.sub_name);
        if (this.firstGameChoose === null) {
          this.firstGameChoose = SelectedGame.attributes.sub_name;
          console.log(
            "Voici le premier jeu sélectionné :",
            this.firstGameChoose
          );
        } else {
          console.log(SelectedGame);
          var secondGameChoose = SelectedGame.attributes.sub_name;
          console.log("Voici le second jeu sélectionné :", secondGameChoose);
          if (secondGameChoose === "La plage") {
            window.location.href = "/#/activites?jeu=la_plage";
            location.reload();
          } else if (secondGameChoose === "Le jardin") {
            window.location.href = "/#/activites?jeu=le_jardin";
            location.reload();
          } else if (secondGameChoose === "Le safari") {
            window.location.href = "/#/activites?jeu=le_safari";
            location.reload();
          } else if ((secondGameChoose = "L'aviron")) {
            window.location.href = "/#/activites?jeu=l_aviron";
            location.reload();
          }
        }
        if (idSelectedGame === null) {
          console.log("SelectedGame", SelectedGame);
          window.location.href = "/#/activites?jeu=la_plage";
          location.reload();
        }
      }
      this.keyGame++;
      this.idAudio = null;
      this.idGame = idSelectedGame;
      console.log("SelectedGame:", SelectedGame);
      //descendre automatiquement à la div ayant comme ref="JeuUnity"
      this.scrollToJeuUnity();
      if (SelectedGame !== null) {
        this.parentFolderId = SelectedGame.attributes.parent_folder_id;
      }
      // this.recupIdAudio(null);
    },
    scrollToJeuUnity() {
      setTimeout(() => {
        const section = document.querySelector("#GameUnity");
        // Aligner la section au haut de la page
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    },
    recupIdAudio(SelectedAudio) {
      //console.log('Voici le selectedAudio:', SelectedAudio)
      this.keyGame++;
      if (this.currentAudio !== null && this.currentTime > 60) {
        this.currentTime = 0;
        this.TerminerExercice(this.currentAudio.id);
      }

      if (SelectedAudio !== null) {
        console.log("SelectedAudio:", SelectedAudio);
        this.SeanceSelected = SelectedAudio.attributes.parent_folder_id;
        this.idAudio = SelectedAudio.id;
        this.currentAudio = SelectedAudio; // Mettez à jour currentAudio avec le nouvel exercice sélectionné
      } else {
        this.idAudio = null;
        this.currentAudio = null; // Réinitialisez currentAudio si aucun exercice n'est sélectionné
      }
      this.idGame = null;
      this.timerExercice();
    },
    changeAudioPlaying(url) {
      this.audioURL = url;
    },
    toggleFullscreen(element) {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    chartFullScreen() {
      const myDiv = document.getElementById("chart-component");
      this.toggleFullscreen(myDiv);
    },
    callData() {
      ParseConfig();
      const ObjectFolder = Parse.Object.extend("folder");
      const ObjectLink = Parse.Object.extend("link");
      const ObjectFile = Parse.Object.extend("file");
      const ObjectComposite_File = Parse.Object.extend("composite_file");
      const ObjectGameType = Parse.Object.extend("game_type");

      const QueryFolder = new Parse.Query(ObjectFolder);
      const QueryLink = new Parse.Query(ObjectLink);
      const QueryFile = new Parse.Query(ObjectFile);
      const QueryComposite_File = new Parse.Query(ObjectComposite_File);
      const QueryGameType = new Parse.Query(ObjectGameType);

      try {
        QueryFolder.equalTo("objectId", this.idSeance);
        QueryLink.matchesQuery("parent_folder", QueryFolder);

        QueryFolder.find().then((e) => {
          return (this.element_folder2 = e);
        });
        QueryLink.find().then((e) => {
          // return (this.element_link2 = e);
        });
        QueryFile.limit(1000)
          .find()
          .then((e) => {
            return (this.element_file2 = e);
          });
        QueryComposite_File.limit(500)
          .find()
          .then((e) => {
            return (this.element_composite_file2 = e);
          });
        QueryGameType.find().then((e) => {
          return (this.element_game_type = e);
        });
        var tableau_game_type = [];
        var tableau_folder2 = [];
        var tableau_link2 = [];
        var tableau_file2 = [];
        var tableau_composite_file2 = [];
        var element_game_type = [];
        var element_folder2 = [];
        var element_link2 = [];
        var element_file2 = [];
        var element_composite_file2 = [];
        tableau_game_type.push(element_game_type);
        tableau_folder2.push(element_folder2);
        tableau_link2.push(element_link2);
        tableau_file2.push(element_file2);
        tableau_composite_file2.push(element_composite_file2);
        this.$forceUpdate();
      } catch (e) {
        //console.log(e);
        ("ERROR");
      }
    },
  },
  computed: {
    ...User.mapGetters(["group", "user", "folders"]),
    filteredModules() {
      if (this.isTherapeute) {
        // Si isTherapeute est true, renvoyer le tableau non filtré
        return this.folder_brique_protocole;
      } else {
        // Sinon, appliquer le filtre
        return this.folder_brique_protocole.filter(
          (moduleP) => !this.idModuleLock.includes(moduleP.id)
        );
      }
    },
  },
  mounted() {
    this.descriptionPro = this.group.description;
  },
};
</script>

<style scoped>
.colorButton {
  background-color: #489181 !important;
  color: white !important;
}

.protocolBloc {
  background-repeat: no-repeat;
  background-attachment: local;
  background-position: center center;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 50%);
  background-size: 100%;
}

.arrowDown2 {
  float: right;
  width: 40px;
  position: relative;
  bottom: 10px;
}

.buttonProg {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  background-color: lightgray;
  color: black;
  margin: 8px;
  font-size: 80%;
}

.zoom-container {
  transition: transform 0.5s;
}

.zoomed {
  transform: scale(1.2);
}

.AudioList {
  background-color: #f0f8f7;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  text-align: left;
  padding: 5px;
}
/* mettre en responsive audioList */
@media screen and (max-width: 1024px) {
  .AudioList {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .AudioList {
    width: 80%;
  }
}
@media screen and (max-width: 412px) {
  .AudioList {
    width: 125%;
    margin-left: -40px;
  }
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}

#boxRessources {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  border: 1px solid #00800069;
}

#boxRessources:hover {
  border: 1px solid green;
  background-color: #239380 !important;
  color: white;
}

@media (max-width: 1024px) {
  #boxRessources {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  #boxRessources {
    width: 80%;
  }
}
@media (min-width: 412px) {
  #boxRessources {
    width: 100%;
  }
}

#boxRessourcesExo {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  border: 1px solid #00800069;
  margin-top: 10px;
}

#boxRessourcesExo:hover {
  border: 1px solid green;
  background-color: #239380 !important;
  color: white !important;
}

@media (max-width: 1024px) {
  #boxRessourcesExo {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  #boxRessourcesExo {
    width: 100%;
  }
}
@media (max-width: 412px) {
  #boxRessourcesExo {
    width: 100%;
    /*margin-left: -40px;*/
  }
}

.background {
  background-color: #489181;
}

.briqueHover:hover {
  border: solid 2px #489181;
  border-radius: 10px;
}

.buttonRessources {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.DivRelectureLibre {
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  background: white;
  padding: 10px;
  border-radius: 10px;
}

.brique {
  flex: 0 0 33.33%;
}

.buttonProg {
  transition: transform 0.5s ease;
}

.buttonProg.colorButton {
  transform: scale(1.1);
}

.TexteExoRessources {
  /*font-size: 20px;*/
}
@media (min-width: 1024px) {
  .TexteExoRessources {
    font-size: medium;
  }
}
@media (max-width: 630px) {
  .TexteExoRessources {
    font-size: small;
  }
}
@media (max-width: 575px) {
  .TexteExoRessources {
    font-size: x-small;
  }
}
.PlacementMoniteurExpert {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .PlacementMoniteurExpert {
    width: 100%;
  }
}

.PlacementAudioRessourcesLibres {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .PlacementAudioRessourcesLibres {
    width: 100%;
  }
}
.PlacementBoutonAudio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1%;
}

.descriptionPro {
  margin: 0px 19% 0px 19%;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 50%);
  background-color: rgba(236, 231, 231, 0.5);
  padding: 15px;
}
.notification-container2 {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.buttonResultatRessources {
  margin-left: auto;
  margin-right: auto;
  color: white;
  background-color: #e0ab4a !important;
  width: 15%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.notification-bubble-android {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-radius: 25px;
  border: 1px solid #f5c2c7;
  font-size: 1em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.IconAudioDiv {
  float: left;
  vertical-align: middle;
  max-width: 30px;
  max-height: 30px;
  margin-right: 15px;
}
@media (max-width: 768px) {
  .IconAudioDiv {
    max-width: 15px;
    max-height: 15px;
  }
}

/* Couleurs lors du survol de infoBox */
.infoBoxExo:hover .session-number,
.infoBoxExo:hover .session-title,
.infoBoxExo:hover .session-details,
.infoBoxExo:hover .session-completion {
  color: white; /* Texte en blanc lors du survol */
}

/* Couleurs lorsque infoBox est en état "complété" ou "déverrouillé" */
.infoBoxExo[style*="background-color: #489181"] .session-number,
.infoBoxExo[style*="background-color: #489181"] .session-title,
.infoBoxExo[style*="background-color: #489181"] .session-details,
.infoBoxExo[style*="background-color: #489181"] .session-completion {
  color: white; /* Texte en blanc */
}
.infoBoxExo {
  width: 100%;
  margin: 1% auto 0 auto;
  padding: 1rem !important;
  background-color: #fff;
  border: 1px solid #00800069;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 1%;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.infoBoxExo:hover {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: #239380 !important;
  color: white !important;
}
.infoBoxExo:hover .TextSeanceResponsive {
  color: white !important;
}

@media (max-width: 768px) {
  .infoBoxExo {
    width: 100%; /* plus de largeur sur un petit écran */
    padding: 1rem; /* Moins d'espacement intérieur sur les petits écrans */
    margin-top: 3%;
  }
}
.textContent p {
  font-size: 16px;
  line-height: 1.4;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .infoBoxExo {
    width: 100%; /* largeur intermédiaire pour les tablettes */
    margin-top: 2%;
  }
}

@media (min-width: 1025px) {
  .infoBoxExo {
    width: 100%; /* largeur fixe sur un grand écran */
  }
}

.infoBoxExo.activeState,
.infoBoxExo:hover {
  background-color: #239380;
  color: white;
}

.infoBoxExo:hover .toggleArrow {
  content: url("../../../assets/img/ChevronDownBlancV2.png");
}
.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textContent,
.iconContent {
  display: flex;
  align-items: center;
}

.textContent p {
  font-size: 1.3rem;
}

@media (max-width: 768px) {
  .textContent p {
    font-size: 1.2rem; /* taille du texte plus petite pour les petits écrans */
  }
}

.toggleArrow {
  width: 40px;
  transition: all 0.3s ease;
}

.toggleArrow.activeArrow {
  transform: rotate(180deg);
}

/* Responsive */
@media (max-width: 768px) {
  .toggleArrow {
    width: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .toggleArrow {
    width: 30px; /* taille intermédiaire pour les tablettes */
  }
}

@media (min-width: 1025px) {
  .toggleArrow {
    width: 40px;
  }
}

@media (max-width: 768px) {
  .TextSeanceResponsive {
    width: 90%;
  }
}
/* Styliser le texte */
.TextSeanceResponsive {
  margin: 0;
  font-size: 1em;
  color: #333; /* couleur du texte */
}

/* Styles spécifiques pour les éléments individuels */
.session-number {
  font-weight: bold; /* gras */
  color: #444;
}

.session-title {
  margin-left: 10px;
  color: #777;
}

.session-details {
  margin-left: 10px;
  font-size: 0.9em;
  color: #999;
}

.session-completion {
  margin-left: 10px;
  color: #239380; /* couleur bleue pour indiquer le pourcentage d'achèvement */
}

/* Les icônes */
.toggleArrow {
  width: 32px;
  height: auto;
}

/* Media queries pour le responsive */
@media (max-width: 768px) {
  .TextSeanceResponsive {
    font-size: 0.9em;
  }
  .toggleArrow {
    width: 20px;
  }
}

.activeSession {
  background-color: #239380 !important;
  color: white !important;
}
.IconExercice {
  max-width: 30px;
  max-height: 30px;
  vertical-align: middle;
  margin-right: 10px;
}
/* Responsive pour les petits écrans */
@media (max-width: 768px) {
  .IconExercice {
    max-width: 20px;
    max-height: 20px;
  }
}

/* Responsive pour les écrans de taille intermédiaire */
@media (min-width: 769px) and (max-width: 1024px) {
  .IconExercice {
    max-width: 25px;
    max-height: 25px;
  }
}

/* Responsive pour les grands écrans */
@media (min-width: 1025px) {
  .IconExercice {
    max-width: 30px;
    max-height: 30px;
  }
}
.infoBoxExo:hover .IconAudioExercice {
  content: url("../../../assets/img/IconAudioWhite.png");
}
.infoBoxExo:hover .IconExerciceVideo {
  content: url("../../../assets/img/IconVideoWhite.png");
}
.infoBoxExo:hover .IconJeu {
  content: url("../../../assets/img/IconJeuWhite.png");
}
.infoBoxExo:hover .IconQuestionnaire {
  content: url("../../../assets/img/IconQuestionnaireWhite.png");
}
.infoBoxExo:hover .IconMoniteur {
  content: url("../../../assets/img/IconMoniteurWhite.png");
}

.date-text {
  font-style: italic;
  font-size: 0.7em;
}

.loading-dots::after {
  content: "...";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}
</style>
