<template>
  <div ref="element">
    <div>
      <div>
        <transition name="slide-fade">
          <div class="notification-container" v-if="showNotification">
            <div class="notification-box">
              <!-- <button @click="closeNotification" class="notification-close">
                &times;
              </button> -->
              <div class="notification-text">Exercice terminé</div>
            </div>
          </div>
        </transition>
      </div>
      <!-- <div
        v-html="descriptionPro"
        class="modules-list dir-col-mobile descriptionPro"
        v-if="descriptionPro"
      ></div> -->
      <!-- <SocketButtonConnexion /> -->
      <template v-for="theme in themes">
        <div v-if="theme.attributes.ordre === 1">
          <CarouselFolders
            :folderBrique="getFoldersByTheme(theme.id)"
            @recupId="handleRecupId"
            :linkedObjectPatient="linkedObjectPatient"
            name="Programmes"
            key="1"
            :idPatientTherapeute="idPatientTherapeute"
          />
        </div>
        <div v-if="theme.attributes.ordre === 1">
          <div v-if="isDisplay && folderBriqueRecup">
            <button-protocol
              :key="KeyButton"
              :idProtocol="idProtocol"
              :folderBriqueRecup="folderBriqueRecup"
              :linkedFilePatient="linkedFilePatient"
              :isExercicecourt="isExercicecourt"
              :isExercicelong="isExercicelong"
              @showExerciceCourt="show_exercice_court"
              @showExerciceLong="show_exercice_long"
              :idPatientTherapeute="idPatientTherapeute"
            >
            </button-protocol>
          </div>
          <div v-if="showModalSubscription">
            <subscriptionPopUp :key="indexPopUpAbonnement" />
          </div>
          <div id="protocol_list" v-if="isDisplay">
            <!-- <graphiqueAvancement :idBrique='idProtocol'  />  -->
            <!-- BLOC INTRODUCTION -->
            <div v-on:click="affichageText = !affichageText">
              <IntroductionComponent :affichageText="affichageText" />
            </div>
          </div>
          <!-- LORSQUE L'ON CLIQUE SUR INTRODUCTION, AFFICHE LE TEXTE DE L'INTRODUCTION -->

          <div class="textSeance" v-show="affichageText">
            <VideoIntroduction
              :uniqueKey="uniqueKey"
              :currentVideo="currentVideo"
              :videosBrique="videosBrique"
              @select-video="handleSelectVideo"
            />

            <span v-html="IntroSelected"></span>
          </div>
          <!--Liste des exercices-->
          <div>
            <div class="loading-spinner" v-if="loading"></div>

            <div v-if="!loading"></div>
            <div v-if="idProtocol">
              <div>
                <div
                  v-for="(moduleP, index) in moduleRecupAffichage"
                  :key="moduleP.id"
                >
                  <div>
                    <span>
                      <div>
                        <div
                          class="infoBox activeState"
                          v-on:click="recupIdSeance('', null)"
                          v-if="
                            SeanceSelected == moduleP.id &&
                            moduleP.link.folder.VisibleFront != true
                          "
                        >
                          <div class="flexWrapper">
                            <div class="textContent">
                              <p
                                class="TextSeanceResponsive"
                                style="color: white !important"
                              >
                                <span class="session-number activeSession"
                                  >SEANCE {{ index + 1 }} -
                                </span>
                                <span class="session-title activeSession">{{
                                  moduleP.name + " - "
                                }}</span>
                                <span class="session-details activeSession"
                                  >{{ moduleP.nbr_exo }} exercices</span
                                >
                                <span
                                  v-if="!isTherapeute"
                                  class="session-completion activeSession"
                                  >{{ moduleP.completionPercentage }}%</span
                                >
                              </p>
                            </div>
                            <div class="iconContent">
                              <img
                                v-if="moduleP.completed && !isTherapeute"
                                class="toggleArrow"
                                src="../../../assets/img/ChevronUpBlancV2.png"
                              />
                              <img
                                v-else-if="SeanceSelected == moduleP.id"
                                class="toggleArrow"
                                src="../../../assets/img/ChevronUpBlancV2.png"
                              />
                            </div>
                          </div>
                          <!-- <div
                            id="ProgressBarModuleActived"
                            v-if="
                              moduleP.completionPercentage < 100 &&
                              moduleP.completionPercentage > 0
                            "
                          >
                            <div
                              v-bind:style="{
                                width: moduleP.completionPercentage + '%',
                              }"
                            ></div>
                          </div> -->
                        </div>
                        <div
                          class="infoBox"
                          v-bind:class="{
                            disabled:
                              (index > 0 &&
                                !moduleRecup[index - 1].completed &&
                                affichagePatientBloc &&
                                moduleP.deverrouille == false) ||
                              (affichagePatientBloc &&
                                moduleP.deverouiller_for_user == false),
                          }"
                          v-bind:style="{
                            backgroundColor:
                              (moduleP.completed && !isTherapeute) ||
                              (moduleP.completionPercentage > 99 &&
                                !isTherapeute)
                                ? '#489181'
                                : 'white',
                            color:
                              (moduleP.completed && !isTherapeute) ||
                              (moduleP.completionPercentage > 99 &&
                                !isTherapeute)
                                ? 'white'
                                : 'black',
                          }"
                          v-on:click="recupIdSeance(moduleP.id, moduleP)"
                          v-else-if="moduleP.link.folder.VisibleFront != true"
                        >
                          <div class="flexWrapper">
                            <div class="textContent">
                              <!-- <span>{{
                                moduleP.completed +
                                " " +
                                moduleP.completionPercentage +
                                " " +
                                isTherapeute
                              }}</span> -->
                              <p class="TextSeanceResponsive">
                                <span class="session-number"
                                  >SEANCE {{ index + 1 }} -
                                </span>
                                <span class="session-title">{{
                                  moduleP.name + " - "
                                }}</span>
                                <span class="session-details"
                                  >{{ moduleP.nbr_exo }} exercices</span
                                >
                                <span
                                  v-if="!isTherapeute"
                                  class="session-completion"
                                  >{{ moduleP.completionPercentage }}%</span
                                >
                              </p>
                            </div>
                            <div class="iconContent">
                              <img
                                alt="Module cadenas"
                                v-if="
                                  (index > 0 &&
                                    !moduleRecup[index - 1].completed &&
                                    affichagePatientBloc &&
                                    moduleP.deverrouille == false) ||
                                  (affichagePatientBloc &&
                                    moduleP.deverouiller_for_user == false)
                                "
                                class="toggleArrow"
                                src="../../../assets/img/cadenas.png"
                              />
                              <img
                                alt="Module terminé"
                                v-else-if="
                                  (moduleP.completed && !isTherapeute) ||
                                  (moduleP.completionPercentage > 99 &&
                                    !isTherapeute)
                                "
                                class="toggleArrow"
                                src="../../../assets/img/ChevronDownBlancV2.png"
                              />
                              <img
                                alt="Module en cours"
                                v-else
                                class="toggleArrow"
                                src="../../../assets/img/ChevronDownV2.png"
                              />
                            </div>
                          </div>
                          <!-- <div
                            id="ProgressBarModule"
                            v-if="
                              moduleP.completionPercentage < 100 &&
                              moduleP.completionPercentage > 0
                            "
                          >
                            <div
                              v-bind:style="{
                                width: moduleP.completionPercentage + '%',
                              }"
                            ></div>
                          </div> -->
                        </div>

                        <div class="ListExercice" id="list-exercice">
                          <div
                            class="loading-spinner2"
                            v-if="loading2 && SeanceSelected == moduleP.id"
                          >
                            <!-- Your spinner goes here -->
                          </div>

                          <div v-if="!loading && SeanceSelected == moduleP.id">
                            <!-- Your data goes here -->
                          </div>

                          <div
                            v-if="SeanceSelected == moduleP.id"
                            v-for="(file, index) in filesLink"
                            :key="file.id"
                          >
                            <div
                              v-on:click="recupIdExercice(null, null)"
                              class="infoBoxExo activeState"
                              v-if="idExerciceRecup == file.id"
                            >
                              <div class="flexWrapper">
                                <div class="textContent">
                                  <p class="TextSeanceResponsive">
                                    <span class="session-number">
                                      {{ index + 1 }}
                                    </span>

                                    <span v-if="file.attributes.sub_name">
                                      {{
                                        " Exercice - " +
                                        file.attributes.sub_name
                                      }}
                                    </span>
                                    <span v-else>
                                      {{ file.attributes.name }}
                                    </span>
                                  </p>
                                </div>
                                <div class="iconContent">
                                  <svg
                                    width="32"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="#239380"
                                  >
                                    <path
                                      fill="#fff"
                                      d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                                    />
                                    <path
                                      fill="#fff"
                                      d="M14.293 14.293a.999.999 0 01-1.414 0L10 11.414l-2.879 2.879a.999.999 0 11-1.414-1.414L8.586 10 5.707 7.121a.999.999 0 111.414-1.414L10 8.586l2.879-2.879a.997.997 0 011.414 0 .999.999 0 010 1.414L11.414 10l2.879 2.879a.999.999 0 010 1.414z"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <!-- <div
                              id="boxExercices_Audio"
                              style="
                                -webkit-box-shadow: none;
                                box-shadow: none;
                                padding: 0 !important;
                              "
                              v-else-if="
                                idExerciceRecup == file.id &&
                                file.attributes.nomfichieraudio
                              "
                            >
                              <template v-if="file.attributes.nomfichieraudio">
                                <audioPlayer
                                  v-on:audio-finish="AudioFinished"
                                  :titleAudio="file.attributes.sub_name"
                                  :sourceAudio="file.attributes.nomfichieraudio"
                                  :timerAudio="file.attributes.duration"
                                  @update-number="update"
                                ></audioPlayer>
                              </template>
                            </div>
                            <div
                              v-else-if="
                                idExerciceRecup == file.id &&
                                file.attributes.nomfichiervideo
                              "
                            >
                              <div class="VideoPlacement">
                                <VideoPlayer
                                  v-on:video-finish="VideoFinished"
                                  :titleVideo="file.attributes.sub_name"
                                  :sourceVideo="file.attributes.nomfichiervideo"
                                  @update-number="update"
                                  :indexVideo="index + 1"
                                ></VideoPlayer>
                              </div>
                            </div> -->
                            <div
                              v-bind:class="{
                                disabled: file.to_completed === undefined,
                              }"
                              class="infoBoxExo"
                              v-bind:style="{
                                backgroundColor: file.completed
                                  ? '#239380'
                                  : 'white',
                                color: file.completed ? 'white' : '',
                              }"
                              v-on:click="recupIdExercice(file.id, file)"
                              v-else
                            >
                              <!-- NOM DE L'EXERCICE -->
                              <div
                                class="flexWrapper"
                                v-if="file.attributes.nomfichiervideo"
                              >
                                <div class="textContent">
                                  <p class="TextSeanceResponsive">
                                    <span>
                                      <img
                                        class="IconExercice IconExerciceVideo"
                                        v-if="file.completed"
                                        src="../../../assets/img/IconVideoWhite.png" />
                                      <img
                                        class="IconExercice IconExerciceVideo"
                                        v-else
                                        src="../../../assets/img/IconVideo.png" /></span
                                    ><span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-number"
                                    >
                                      {{ index + 1 }}
                                    </span>
                                    <span class="session-title">
                                      Exercice Vidéo -
                                    </span>
                                    <span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-details"
                                    >
                                      {{ file.attributes.sub_name }}
                                    </span>
                                  </p>
                                </div>
                                <div class="iconContent">
                                  <img
                                    v-if="
                                      file.completed === true ||
                                      file.to_completed === true
                                    "
                                    class="toggleArrow"
                                    src="../../../assets/img/playButton.png"
                                  />
                                  <img
                                    v-else
                                    class="toggleArrow"
                                    src="../../../assets/img/cadenas.png"
                                  />
                                </div>
                              </div>

                              <div
                                v-else-if="file.attributes.nomfichieraudio"
                                class="flexWrapper"
                              >
                                <div class="textContent">
                                  <p class="TextSeanceResponsive">
                                    <span>
                                      <img
                                        class="IconExercice IconAudioExercice"
                                        v-if="file.completed"
                                        src="../../../assets/img/IconAudioWhite.png"
                                      />
                                      <img
                                        class="IconExercice IconAudioExercice"
                                        v-else
                                        src="../../../assets/img/IconAudio.png"
                                      />
                                    </span>
                                    <span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-number"
                                    >
                                      {{ index + 1 }}
                                    </span>
                                    <span class="session-title">
                                      Exercice Audio -
                                    </span>
                                    <span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-details"
                                    >
                                      {{ file.attributes.sub_name }}
                                    </span>
                                  </p>
                                </div>
                                <div class="iconContent">
                                  <img
                                    v-if="
                                      file.completed === true ||
                                      file.to_completed === true
                                    "
                                    class="toggleArrow"
                                    src="../../../assets/img/playButton.png"
                                  />
                                  <img
                                    v-else
                                    class="toggleArrow"
                                    src="../../../assets/img/cadenas.png"
                                  />
                                </div>
                              </div>
                              <div
                                v-else-if="file.attributes.questionnaire_"
                                class="flexWrapper"
                              >
                                <div class="textContent">
                                  <p class="TextSeanceResponsive">
                                    <span>
                                      <img
                                        class="IconExercice IconQuestionnaire"
                                        v-if="file.completed"
                                        src="../../../assets/img/IconQuestionnaireWhite.png"
                                      />
                                      <img
                                        class="IconExercice IconQuestionnaire"
                                        v-else
                                        src="../../../assets/img/IconQuestionnaire.png"
                                      />
                                    </span>
                                    <span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-number"
                                    >
                                      {{ index + 1 }}
                                    </span>
                                    <span class="session-title">
                                      Questionnaire -
                                    </span>
                                    <span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-details"
                                    >
                                      {{ file.attributes.name }}
                                    </span>
                                  </p>
                                </div>
                                <div class="iconContent">
                                  <img
                                    v-if="
                                      file.completed === true ||
                                      file.to_completed === true
                                    "
                                    class="toggleArrow"
                                    src="../../../assets/img/playButton.png"
                                  />
                                  <img
                                    v-else
                                    class="toggleArrow"
                                    src="../../../assets/img/cadenas.png"
                                  />
                                </div>
                              </div>
                              <div
                                v-else-if="file.attributes.game_type"
                                class="flexWrapper"
                              >
                                <div class="textContent">
                                  <p class="TextSeanceResponsive">
                                    <span>
                                      <img
                                        class="IconExercice IconJeu"
                                        v-if="file.completed"
                                        src="../../../assets/img/IconJeuWhite.png"
                                      />
                                      <img
                                        class="IconExercice IconJeu"
                                        v-else
                                        src="../../../assets/img/IconJeu.png"
                                      /> </span
                                    ><span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-number"
                                    >
                                      {{ index + 1 }}
                                    </span>
                                    <span class="session-title">
                                      Exercice Jeu -
                                    </span>
                                    <span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-details"
                                    >
                                      {{ file.attributes.sub_name }}
                                    </span>
                                  </p>
                                </div>
                                <div class="iconContent">
                                  <img
                                    v-if="
                                      file.completed === true ||
                                      file.to_completed === true
                                    "
                                    class="toggleArrow"
                                    src="../../../assets/img/playButton.png"
                                  />
                                  <img
                                    v-else
                                    class="toggleArrow"
                                    src="../../../assets/img/cadenas.png"
                                  />
                                </div>
                              </div>
                              <div class="flexWrapper" v-else>
                                <div class="textContent">
                                  <p class="TextSeanceResponsive">
                                    <span>
                                      <img
                                        class="IconExercice IconMoniteur"
                                        v-if="file.completed"
                                        src="../../../assets/img/IconMoniteurWhite.png"
                                      />
                                      <img
                                        class="IconExercice IconMoniteur"
                                        v-else
                                        src="../../../assets/img/IconMoniteur.png"
                                      />
                                    </span>
                                    <span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-number"
                                    >
                                      {{ index + 1 }}
                                    </span>
                                    <span class="session-title">
                                      Exercice Moniteur Expert -
                                    </span>
                                    <span
                                      v-bind:style="{
                                        color: file.completed ? 'white' : '',
                                      }"
                                      class="session-details"
                                    >
                                      {{ file.attributes.sub_name }}
                                    </span>
                                  </p>
                                </div>
                                <div class="iconContent">
                                  <img
                                    v-if="
                                      file.completed === true ||
                                      file.to_completed === true
                                    "
                                    class="toggleArrow"
                                    src="../../../assets/img/playButton.png"
                                  />
                                  <img
                                    v-else
                                    class="toggleArrow"
                                    src="../../../assets/img/cadenas.png"
                                  />
                                </div>
                              </div>
                            </div>

                            <!-- AFFICHAGE DE L'EXERCICE -->
                            <div v-if="idExerciceRecup == file.id">
                              <!--BOUTON + D'INFOS ET BOUTONS TELECHARGER-->
                              <div
                                v-if="
                                  file.attributes.nomfichieraudio ||
                                  file.attributes.nomfichiervideo
                                "
                              >
                                <div id="boxExercices_Audio">
                                  <template
                                    v-if="file.attributes.nomfichieraudio"
                                  >
                                    <audioPlayer
                                      @close="handleCloseAudio"
                                      v-on:audio-finish="AudioFinished"
                                      :titleAudio="file.attributes.sub_name"
                                      :sourceAudio="
                                        file.attributes.nomfichieraudio
                                      "
                                      :timerAudio="file.attributes.duration"
                                      @update-number="update"
                                    ></audioPlayer>
                                  </template>
                                  <div
                                    class="VideoPlacement"
                                    v-if="file.attributes.nomfichiervideo"
                                  >
                                    <VideoPlayer
                                      v-on:video-finish="VideoFinished"
                                      :titleVideo="file.attributes.sub_name"
                                      :sourceVideo="
                                        file.attributes.nomfichiervideo
                                      "
                                      @update-number="update"
                                    ></VideoPlayer>
                                  </div>
                                </div>
                                <div class="buttonprog">
                                  <button
                                    style="background-color: #489181"
                                    class="buttonVideo"
                                    v-on:click="
                                      showIntroExercice = !showIntroExercice
                                    "
                                    v-if="showIntroExercice !== true"
                                  >
                                    Plus d'informations
                                  </button>
                                  <button
                                    class="buttonVideo"
                                    style="
                                      background-color: lightgray;
                                      color: black !important;
                                      text-decoration: none;
                                    "
                                    v-if="file.attributes.resource_file"
                                    v-on:click="
                                      buttonToFiche(
                                        file.attributes.resource_file._url
                                      )
                                    "
                                  >
                                    <a
                                      :href="file.attributes.resource_file._url"
                                      target="_blank"
                                    >
                                      télécharger la fiche</a
                                    >
                                  </button>
                                  <!-- <button
                                      style=""
                                      v-if="!file.completed"
                                      v-bind:class="{
                                        disabled: elapsedTime < 30,
                                      }"
                                      class="buttonVideo"
                                      id="TerminerExercice"
                                      v-on:click="TerminerExercice(file.id, 0)"
                                    >
                                      Terminer et Sauvegarder l'exercice
                                    </button>  -->
                                </div>
                              </div>
                              <div
                                class="MoniteurPlacement"
                                v-if="file.attributes.type.id === 'PnIg5kGR0p'"
                              >
                                <ChartFull
                                  id="chart-component"
                                  :idPatientTherapeute="idPatientTherapeute"
                                  @moniteur-finish="MoniteurFinished"
                                  :MoniteurInProgrammes="true"
                                  :Inspi="file.attributes.Inspi"
                                  :flux="file.attributes.flux"
                                  :avecGuide="file.attributes.avecGuide"
                                  :Expi="file.attributes.Expi"
                                  :avecCapteur="file.attributes.avecCapteur"
                                  :avecParametres="
                                    file.attributes.avecParametres
                                  "
                                  :pcm="file.attributes.pcm"
                                  :tableau="file.attributes.tableauModification"
                                  :temps="file.attributes.temps"
                                  :fileReçu="file"
                                  :folderIdReçu="SeanceSelected"
                                >
                                </ChartFull>
                                <div style="display: flex">
                                  <button
                                    style="
                                      margin-left: auto;
                                      margin-right: auto;
                                      color: white;
                                      background-color: #e0ab4a !important;
                                      width: 30%;
                                    "
                                    class="buttonChart"
                                    v-on:click="DisplayResult = !DisplayResult"
                                  >
                                    Voir mes résultats
                                  </button>
                                  <!-- <button
                                      style="
                                        margin-left: auto;
                                        margin-right: auto;
                                        background-color: #e0ab4a !important;
                                        color: white;
                                        width: 30%;
                                      "
                                      class="buttonChart"
                                      v-on:click="chartFullScreen()"
                                    >
                                      Plein écran
                                    </button> -->
                                </div>
                                <app-suivieCc
                                  v-if="DisplayResult"
                                  :utilisateurs="UserForRelecture"
                                  :userSelected="tous_variable"
                                  :ResultatsRessources="true"
                                  :folders="folders"
                                  :IUserSelected="UserSelected"
                                  :userParticulier="ouvertureSuiviCcUtilisateur"
                                  :fileCCId="file.id"
                                  :idprotocole="file.attributes.sub_name"
                                />
                              </div>
                              <div>
                                <!-- PLUS D'INFORMATIONS DE L'EXERCICE-->
                                <transition
                                  name="fade"
                                  v-if="!file.attributes.questionnaire_"
                                >
                                  <div
                                    v-if="showIntroExercice"
                                    class="bubblediv"
                                  >
                                    <div class="bubble">
                                      <button
                                        v-on:click="
                                          showIntroExercice = !showIntroExercice
                                        "
                                        style="
                                          margin-left: auto;
                                          margin-right: 1%;
                                          margin-top: 1%;
                                        "
                                        type="button"
                                        class="btn-close"
                                      >
                                        <span class="icon-cross"></span>
                                        <span class="visually-hidden"></span>
                                      </button>
                                      <p
                                        v-html="file.attributes.text"
                                        style="margin: 2%"
                                      ></p>
                                    </div>
                                  </div>
                                </transition>
                              </div>
                              <!--   JEU    -->

                              <div
                                class="center-content"
                                v-if="file.attributes.game_type"
                              >
                                <!-- <JeuUnityComponentVue
                                  v-on:game-finish="GameFinished"
                                  :idUserForSave="idUserForSave"
                                  :idPatientTherapeute="idPatientTherapeute"
                                  :Version="
                                    file.attributes.game_type.attributes.version
                                  "
                                  :Difficulte="file.attributes.difficulte"
                                  :Distance="file.attributes.distance"
                                  :Timer="file.attributes.temps"
                                  :pcm="file.attributes.pcm"
                                  :Flux="file.attributes.flux"
                                  :Parametre="file.attributes.avecParametres"
                                  :Musique="file.attributes.repertoireMusique"
                                  :tableauModification="
                                    file.attributes.tableauModification
                                  "
                                  :Capteur="file.attributes.avecCapteur"
                                  :Guide="file.attributes.avecGuide"
                                >
                                </JeuUnityComponentVue> -->
                                <JeuUnityComponentVue2
                                  :jeux="file"
                                  v-on:game-finish="GameFinished"
                                  :idUserForSave="idUserForSave"
                                  :idPatientTherapeute="idPatientTherapeute"
                                />
                                <app-suivieCc
                                  v-if="DisplayResult"
                                  :utilisateurs="UserForRelecture"
                                  :userSelected="tous_variable"
                                  :ResultatsRessources="true"
                                  :folders="folders"
                                  :IUserSelected="UserSelected"
                                  :userParticulier="ouvertureSuiviCcUtilisateur"
                                  :fileCCId="file.id"
                                  :idprotocole="file.attributes.sub_name"
                                />
                              </div>
                              <!-- QUESTIONNAIRE -->
                              <div
                                v-if="file.attributes.questionnaire_"
                                id="boxExercicesQCM"
                              >
                                <QuestionnaireComponent
                                  @recupIdLink="handleRecupIdLink"
                                  @PreviousQuestion="handlePreviousQuestion"
                                  @NextQuestion="handleNextQuestion"
                                  @FinalResultat="handleFinalResultat"
                                  @TerminerExerciceQCM="
                                    handleTerminerExerciceQCM
                                  "
                                  :presentation="presentation"
                                  :file="file"
                                  :Questionnairedate="Questionnairedate"
                                  :question="question"
                                  :element_questionnaire2="
                                    element_questionnaire2
                                  "
                                  :element2="element2"
                                  :element_answer2="element_answer2"
                                  :number="number"
                                  :picked="picked"
                                  :resultatQCM="resultatQCM"
                                  :resultat="resultat"
                                  :nameQuestionnaire="nameQuestionnaire"
                                  :resultatQCM_Object="resultatQCM_Object"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import CarouselFolders from "@/components/accueilV2/CarouselFolders.vue";
import { createNamespacedHelpers } from "vuex";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import audioPlayer from "@/components/audioPlayer.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import JeuUnityComponentVue from "./JeuUnityComponent.vue";
import ChartFull from "@/components/ChartFull.vue";
import { callDataForProgrammes } from "@/services/folders/callDataForProgrammes.js";
import { isAndroid } from "@/services/device/deviceService";
import suivieCc from "./tableauDeBord/SuivieCc.vue";
import { getFilesDoneByUserId } from "@/services/folders/getFilesDoneByUserId.js";
import SocketButtonConnexion from "@/components/SocketButtonConnexion.vue";
import subscriptionPopUp from "@/components/subscriptionPopUp.vue";
const User = createNamespacedHelpers("user");
import QuestionnaireComponent from "./exercice/QuestionnaireComponent.vue";
import { getInfoForNotif } from "@/services/notifications/getInfoForNotif.js";
import { createNotificationForHighScoreCritical } from "@/services/notifications/createNotificationForHighScoreCritical.js";
import { getLastExercice } from "../services/espace/getLastExercice";
import { verificationToken } from "@/services/token/verificationToken.js";
import router from "@/router";
import { getResultatFromQCM } from "@/services/folders/getResultatFromQCM.js";

import IntroductionComponent from "@/components/programmes/IntroductionComponent.vue";
import ButtonProtocol from "@/components/programmes/ButtonProtocol.vue";
import VideoIntroduction from "@/components/programmes/VideoIntroduction.vue";

import graphiqueAvancement from "./relecture/graphiqueAvancement.vue";

let intervalId = null;
export default {
  name: "folders",
  props: [
    "foldersBrique",
    //"group",
    "isPageUser",
    "isPageSessionGroup",
    "themes",
    "idPatientTherapeute",
    "ParamJeuGuidee",
    "SeanceSelectedUrl",
  ],
  data() {
    return {
      currentVideo: "",
      videosBrique: [],
      buttonDisabled: false,
      timerButtonTerminer: null,
      descriptionSelected: "",
      moduleRecup: [],
      isDisplay: false,
      isExercicecourt: true,
      isExercicelong: false,
      affichageText: false,
      imgArrow: null,
      idProtocol: "",
      IntroSelected: "",
      idBriqueSelected: "",
      SeanceSelected: null,
      idFile: null,
      Active: false,
      element: [],
      isAndroid: false,
      tableau: [],
      number: 0,
      isButtonClicked: false,
      element_link: [],
      tableau_link: [],
      tableau_folder: [],
      element_folder: [],
      answersQuestionnaire: [],
      fileRecup: "",
      showExercices: false,
      idExerciceRecup: "",
      showIntroExercice: false,
      testReload: false,
      numberExercice: 1,
      element_folder2: [],
      element_link2: [],
      element_file2: [],
      element_questionnaire2: [],
      element_answer2: [],
      element2: [],
      element_game2: [],
      tableau_folder2: [],
      tableau_link2: [],
      tableau_file2: [],
      tableau_questionnaire2: [],
      tableau2: [],
      tableau_answer2: [],
      tableau_game2: [],
      tableau_file_done2: [],
      element_file_done2: [],
      filteredFolderBrique: [],
      Questionnaire: "",
      IdLink: "",
      presentation: true,
      question: false,
      numberExercice: 1,
      checked: false,
      picked: 0,
      resultatQCM: false,
      resultat: 0,
      descriptionPro: null,
      DisplayResult: false,
      audioURL:
        "https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4",
      titleAudio: "",
      sourceAudio: "",
      timerAudio: "",
      titleVideo: "",
      sourceVideo: "",
      Version: "",
      Difficulte: "",
      Distance: "",
      Timer: "",
      pcm: "",
      Flux: "",
      Parametre: "",
      Musique: "",
      arrayModification: [],
      Capteur: "",
      Guide: "",
      Expi: "",
      Inspi: "",
      avecCapteur: false,
      avecGuide: false,
      flux: "",
      pcm: "",
      temps: "",
      avecParametres: "",
      element_user: [],
      tableau_user: [],
      element_gameType: [],
      fileDoneTest: "",
      filesLink: [],
      moduleRecupAffichage: [],
      loading: false,
      loading2: false,
      showNotification: false,
      progressWidth: 0,
      intervalId: null,
      isCourtClicked: false,
      answers: [],
      isLongClicked: false,
      startTime: null,
      intervalId: null,
      elapsedTime: 0,
      fileDoneQuestionnaire: [],
      tableauFileDoneQuestionnaire: [],
      noteQuestionnaire: 0,
      dateQuestionnaire: "",
      Questionnairedate: null,
      QuestionnaireCompleted: true,
      element_user_local: [],
      user_local: "",
      linkedObjectPatient: [],
      linkedFilePatient: [],
      isFullPage: true,
      affichagePatientBloc: false,
      isDisplayProgrammes: "",
      ResultatJeux: "",
      GameFinish: "",
      isDisplayFolderBrique: false,
      folderBriqueRecup: [],
      exerciseStartTime: null,
      isLoading: false,
      isFullPage: false,
      idUserForSave: null,
      nameQuestionnaire: "",
      categoryScores: {},
      VideoFoldersBrique: null,
      UserForRelecture: "",
      UtilisateurEnCours: "",
      UserSelected: "",
      folders_for_relecture: [],
      ouvertureSuiviCcUtilisateur: "",
      uniqueKey: 0,
      showModalSubscription: false,
      indexPopUpAbonnement: 0,
      lastExercice: null,
      folderBriqueObject: null,
      ExerciceLongVideo: false,
      resultatQCM_Object: [],
      abonnementFinish: false,
      isTherapeute: false,
      firstGameChoose: null,
      secondGameChoose: null,
      KeyButton: 0,
      SeanceSelectedObject: null,
    };
  },
  computed: {
    ...User.mapGetters(["user", "group", "folders"]),
  },
  beforeDestroy() {
    clearTimeout(this.timerButtonTerminer);
  },

  components: {
    audioPlayer,
    VideoPlayer,
    JeuUnityComponentVue,
    JeuUnityComponentVue2: () => import("@/components/JeuUnityComponent2"),
    ChartFull,
    appSuivieCc: suivieCc,
    SocketButtonConnexion,
    subscriptionPopUp,
    QuestionnaireComponent,
    CarouselFolders,
    IntroductionComponent,
    ButtonProtocol,
    VideoIntroduction,
    graphiqueAvancement,
  },

  methods: {
    ...User.mapActions({
      actionsLogOut: "logOut",
      resetPassword: "resetPassword",
    }),
    async verifToken() {
      var token = localStorage.getItem("token");
      var loginTimestamp = parseInt(localStorage.getItem("dateConnexion"), 10);
      var result = await verificationToken(token);
      if (Date.now() - loginTimestamp > 12 * 60 * 60 * 1000) {
        // si le token est trop vieux, on le supprime
        localStorage.removeItem("token");
        localStorage.removeItem("idUserForSave");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        Parse.User.logOut();
        //on supprime le user du store
        // this.$store.commit("user", null);
        setTimeout(() => {
          router.push("/connexion");
        }, 1000);
      }
      if (result == false) {
        //si le token est mauvais, on le supprime
        localStorage.removeItem("token");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        Parse.User.logOut();
        // this.$store.commit("user", null);
        setTimeout(() => {
          router.push("/connexion");
        }, 1000);
      }
    },
    handleCloseAudio() {
      this.recupIdExercice(null, null);
    },
    verifAbonnement() {
      var user = Parse.User.current();
      //console.log("User récupére: ", user);
      var userTypeAbonnement = user.get("group").get("type_abonnement");
      var userFinAbonnement = user.get("group").get("subscription_end_date");
      //console.log("userTypeAbonnement: ", userTypeAbonnement);
      //console.log("userFinAbonnement: ", userFinAbonnement);
      var today = new Date();
      if (userTypeAbonnement === "gratuit" && userFinAbonnement < today) {
        this.abonnementFinish = true;
      } else {
        this.abonnementFinish = false;
      }
    },
    handleRecupIdLink(id) {
      this.recupIdLink(id);
    },
    handleRecupId(folderbrique) {
      this.KeyButton++;
      console.log("KeyButton:", this.KeyButton);
      //console.log("folderbrique: ", folderbrique);
      if (folderbrique == null) {
        this.recupId(null);
        this.isDisplayFolderBrique = false;
        this.isDisplay = false;
      } else {
        this.recupId(folderbrique);
        this.isDisplayFolderBrique = true;
        this.isDisplay = true;
      }
    },
    handlePreviousQuestion(picked) {
      this.PrevQuestion(picked);
    },
    handleNextQuestion(picked, element2) {
      this.NextQuestion(picked, element2);
    },
    handleFinalResultat(element_questionnaire2) {
      this.FinalResultat(element_questionnaire2);
    },
    handleTerminerExerciceQCM(id, resultat) {
      this.TerminerExerciceQCM(id, resultat);
      //console.log("Id du questionnaire terminé :", id);
      //console.log("Résultat :", resultat);
    },
    openModalSubscription() {
      this.showModalSubscription = true;
    },
    closeModalSubscription() {
      this.showModalSubscription = false;
    },
    update(titleAudio, sourceAudio, timerAudio) {
      this.titleAudio = titleAudio;
      this.sourceAudio = sourceAudio;
      this.timerAudio = timerAudio;
    },
    startCounterFreeAccount() {
      setTimeout(() => {
        const user = Parse.User.current();
        if (
          user.attributes.group.attributes.essai_gratuit === false &&
          user.attributes.group.attributes.type_abonnement === "gratuit"
        ) {
          this.recupIdExercice(null, null);
          this.openModalSubscription();
          this.indexPopUpAbonnement++;
        }
      }, 20000); // 20 secondes
    },
    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    MoniteurFinished(e) {
      this.recupIdExercice(null, null);
      this.recupId(this.folderBriqueRecup);
      this.recupIdSeance(this.SeanceSelected, null);
    },
    GameFinished(e) {
      console.log("GameFinished e", e);
      this.ResultatJeux = JSON.stringify(e);

      this.TerminerExerciceJeu(this.fileRecup.id, 0);
    },
    AudioFinished(e) {
      //console.log("AudioFinished");
      this.TerminerExercice(this.fileRecup.id, 0);
    },
    VideoFinished(e) {
      if (this.isExercicelong) {
        this.ExerciceLongVideo = true;
      } else {
        this.ExerciceLongVideo = false;
      }
      this.TerminerExercice(this.fileRecup.id, 0);
    },
    updateVideo(titleVideo, sourceVideo) {
      this.titleVideo = titleVideo;
      this.sourceVideo = sourceVideo;
    },
    updateJeu(
      Version,
      Difficulte,
      Distance,
      Timer,
      pcm,
      Flux,
      Parametre,
      Musique,
      arrayModification,
      Capteur,
      Guide
    ) {
      this.Version = Version;
      this.Difficulte = Difficulte;
      this.Distance = Distance;
      this.Timer = Timer;
      this.pcm = pcm;
      this.Flux = Flux;
      this.Parametre = Parametre;
      this.Musique = Musique;
      this.arrayModification = arrayModification;
      this.Capteur = Capteur;
      this.Guide = Guide;
    },
    show_exercice_court() {
      this.isExercicecourt = true;
      this.isExercicelong = false;
      this.isCourtClicked = !this.isCourtClicked;

      return (
        (this.moduleRecup = this.folderBriqueRecup.protocoles[1].modules),
        this.callFolderDone()
      );
    },
    show_exercice_long() {
      this.isLongClicked = !this.isLongClicked;

      this.isExercicecourt = false;
      this.isExercicelong = true;
      return (
        (this.moduleRecup = this.folderBriqueRecup.protocoles[2].modules),
        this.callFolderDone()
      );
    },
    recupId(folderBrique) {
      //console.log("recupId folderBrique: ", folderBrique);
      if (folderBrique == null) {
        this.idBriqueSelected = "";
        this.folderBriqueRecup = "";
        this.isExercicecourt = false;
        this.isExercicelong = false;
        this.moduleRecup = [];
        this.idProtocol = "";
        this.IntroSelected = "";
        this.currentVideo = "";
        this.videosBrique = [];
        this.uniqueKey++;
        this.isDisplay = false;
        this.isDisplayFolderBrique = false;
      } else {
        this.VideoFoldersBrique = "";
        this.folderBriqueRecup = folderBrique;
        this.IntroSelected = folderBrique.protocoles[1].text;
        this.currentVideo =
          folderBrique.protocoles[1].videoIntro[0].sources[0].src;
        this.videosBrique = folderBrique.protocoles[1].videoIntro;
        this.uniqueKey++; // Incrémenter la clé unique
        if (
          folderBrique.protocoles[1].deverrouille &&
          (this.linkedFilePatient && this.linkedFilePatient.length > 0
            ? this.linkedFilePatient.find(
                (linkedObject) =>
                  linkedObject.id === folderBrique.id && linkedObject.court
              )
            : true)
        ) {
          this.isExercicecourt = true;
          this.isExercicelong = false;
          this.moduleRecup = folderBrique.protocoles[1].modules;
        } else {
          this.isExercicecourt = false;
          this.isExercicelong = true;
          this.moduleRecup = folderBrique.protocoles[2].modules;
        }

        this.idProtocol = folderBrique.id;
        this.callFolderDone();
      }
    },
    selectVideo(video) {
      this.currentVideo = video.sources[0].src;
      this.uniqueKey++;
    },
    handleSelectVideo(video) {
      this.selectVideo(video);
    },
    // ScrollToSeance() {
    //   var element = document.getElementById("protocol_list");
    //   element.scrollIntoView({ behavior: "smooth", block: "start" });
    // },
    startTimer() {
      this.showNotification = true;
      this.progressWidth = 0;
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.progressWidth += 3.33;
        if (this.progressWidth >= 100) {
          clearInterval(this.intervalId);
        }
      }, 100);
      setTimeout(() => {
        this.closeNotification();
      }, 3000);
    },
    closeNotification() {
      this.showNotification = false;
      this.progressWidth = 0;
    },
    async callFolderDone() {
      this.moduleRecupAffichage = [];
      this.loading = true;
      const moduleRecupIds = this.moduleRecup.map((module) => module.id);
      const QueryFileDone = new Parse.Query("file_done");
      const QueryUser = new Parse.Query("User");
      if (this.idPatientTherapeute) {
        //console.log('CallFolderDone idPatientTherapeute', this.idPatientTherapeute)
        const [user, fileDone] = await Promise.all([
          QueryUser.equalTo("objectId", this.idPatientTherapeute).find(),
          QueryFileDone.matchesQuery("user", QueryUser)
            .containedIn("module", moduleRecupIds)
            .find(),
        ]);
        //console.log('FileDone:', fileDone)
        // Utiliser la méthode reduce pour compter les résultats
        const moduleCounts = fileDone.reduce((counts, file) => {
          if (!counts[file.get("module").id]) {
            counts[file.get("module").id] = 0;
          }
          counts[file.get("module").id]++;
          return counts;
        }, {});
        //console.log('moduleCounts:', moduleCounts);
        //console.log('moduleRecup before PromiseAll:', this.moduleRecup)
        await Promise.all(
          this.moduleRecup.map(async (module) => {
            module.completionPercentage = moduleCounts[module.id]
              ? Math.round((moduleCounts[module.id] / module.nbr_exo) * 100)
              : 0;
            if (moduleCounts[module.id] === module.nbr_exo) {
              //console.log('moduleCounts[module.id] === module.nbr_exo', moduleCounts[module.id], module.nbr_exo)
              module.completed = true;
            }
            if (module.completionPercentage > 100) {
              module.completionPercentage = 100;
            }
          })
        );
        this.loading = false;
        //console.log('moduleRecup:', this.moduleRecup)

        return (this.moduleRecupAffichage = this.moduleRecup);
      } else if (this.idUserForSave) {
        //  console.log('CallFolderDone idUserForSave')

        const [user, fileDone] = await Promise.all([
          QueryUser.equalTo("objectId", this.idUserForSave).find(),
          QueryFileDone.matchesQuery("user", QueryUser)
            .containedIn("module", moduleRecupIds)
            .find(),
        ]);
        // console.log('FileDone:', fileDone)

        // Utiliser la méthode reduce pour compter les résultats
        const moduleCounts = fileDone.reduce((counts, file) => {
          if (!counts[file.get("module").id]) {
            counts[file.get("module").id] = 0;
          }
          counts[file.get("module").id]++;
          return counts;
        }, {});
        //console.log('moduleCounts:', moduleCounts)
        await Promise.all(
          this.moduleRecup.map(async (module) => {
            module.completionPercentage = moduleCounts[module.id]
              ? Math.round((moduleCounts[module.id] / module.nbr_exo) * 100)
              : 0;
            if (moduleCounts[module.id] === module.nbr_exo) {
              module.completed = true;
            }
            if (module.completionPercentage > 100) {
              module.completionPercentage = 100;
            }
          })
        );
        this.loading = false;
        //console.log('moduleRecup:', this.moduleRecup)

        return (this.moduleRecupAffichage = this.moduleRecup);
      } else {
        // console.log('CallFolderDone thérapeute')
        const [user, fileDone] = await Promise.all([
          QueryUser.equalTo("objectId", this.user.objectId).find(),
          QueryFileDone.matchesQuery("user", QueryUser)
            .containedIn("module", moduleRecupIds)
            .find(),
        ]);
        //console.log('FileDone:', fileDone)

        // Utiliser la méthode reduce pour compter les résultats
        const moduleCounts = fileDone.reduce((counts, file) => {
          if (!counts[file.get("module").id]) {
            counts[file.get("module").id] = 0;
          }
          counts[file.get("module").id]++;
          return counts;
        }, {});
        let previousModuleCompleted = true; // La variable pour suivre si le module précédent est complet
        // console.log('moduleCounts:', moduleCounts)
        // console.log('moduleRecup before PromiseAll:', this.moduleRecup)
        await Promise.all(
          this.moduleRecup.map(async (module, index) => {
            module.completionPercentage = moduleCounts[module.id]
              ? Math.round((moduleCounts[module.id] / module.nbr_exo) * 100)
              : 0;
            // console.log(moduleCounts[module.id], module.nbr_exo)
            // console.log(moduleCounts)
            if (moduleCounts[module.id] >= module.nbr_exo) {
              module.completed = true;
            }

            if (module.completionPercentage > 100) {
              module.completionPercentage = 100;
            }

            // Déverrouiller le module seulement si le module précédent est complet, sinon le verrouiller
            if (previousModuleCompleted) {
              module.deverouiller_for_user = true;
            } else {
              module.deverouiller_for_user = false;
            }

            previousModuleCompleted = module.completed;
          })
        );
        this.loading = false;
        // this.scrollToSection();
        //  console.log('moduleRecup:', this.moduleRecup)
        return (this.moduleRecupAffichage = this.moduleRecup);
      }
    },
    getFoldersByTheme(monTheme) {
      return this.foldersBrique.filter((el) => {
        if (el.themes.length < 2) {
          return el.themes[0].objectId === monTheme;
        }
        return (
          el.themes[0].objectId === monTheme ||
          el.themes[1].objectId === monTheme
        );
      });
    },
    recupIdSeance(idSeanceSelected, SeanceSelected) {
      this.SeanceSelected = idSeanceSelected;
      this.callData();
      this.SeanceSelectedObject = SeanceSelected;
      // if (SeanceSelected) {
      //   console.log("SeanceSelected:", SeanceSelected);
      //   var completionPercentage = SeanceSelected.completionPercentage;
      //   console.log("completionPercentage:", completionPercentage);
      //   if (completionPercentage === 100) {
      //     console.log("emitting pop-up-contact");
      //     this.$emit("pop-up-contact", SeanceSelected);
      //   }
      // }

      this.scrollToListExo();
    },
    recupIdParentFolder(idParentFolderSelected) {
      return (this.idFile = idParentFolderSelected);
    },
    RecupQuestionnaire(QuestionnaireLink) {
      return (this.Questionnaire = QuestionnaireLink);
    },
    recupScoreQuestionnaire(file) {
      const QueryFileDone = new Parse.Query("file_done");
      const QueryUser = new Parse.Query("User");
      const QueryFile = new Parse.Query("file");
      QueryFile.equalTo("objectId", file.id);
      if (this.idPatientTherapeute) {
        QueryUser.equalTo("objectId", this.idPatientTherapeute);
      } else if (this.idUserForSave) {
        QueryUser.equalTo("objectId", this.idUserForSave);
      } else {
        QueryUser.equalTo("objectId", this.user.objectId);
      }
      QueryFileDone.matchesQuery("user", QueryUser);
      QueryFileDone.matchesQuery("file", QueryFile);
      this.UserRecup = QueryUser.find();
      QueryFileDone.descending("createdAt");
      QueryFileDone.first().then((e) => {
        QueryFileDone.first().then((e) => {
          this.fileDoneQuestionnaire = e;
          if (e) {
            this.noteQuestionnaire = e.get("note");
            this.dateQuestionnaire = e.createdAt;
            let options = {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            };

            let dateQuestionnaire = this.dateQuestionnaire.toLocaleDateString(
              "fr-FR",
              options
            );
            this.Questionnairedate = dateQuestionnaire;
          } else {
            this.noteQuestionnaire = undefined;
          }
        });
      });
    },
    MethodForGame(file) {
      console.log("Voici la séance sélectionné:", this.SeanceSelected);
      if (this.firstGameChoose === null) {
        this.firstGameChoose = file.attributes.name;
        console.log("Voici le premier jeu sélectionné :", this.firstGameChoose);
      } else {
        this.secondGameChoose = file.attributes.name;
        console.log(
          "Voici le deuxième jeu sélectionné :",
          this.secondGameChoose
        );
        if (this.secondGameChoose) {
          var secondGameChooseName = this.secondGameChoose;
          if (
            secondGameChooseName === "Exercice interactif guidé : Le Jardin"
          ) {
            window.location.href = "/#/activites?jeu_guidee=le_jardin_guidee";
            location.reload();
          } else if (
            secondGameChooseName === "Exercice interactif guidé : La Plage"
          ) {
            window.location.href = "/#/activites?jeu_guidee=la_plage_guidee";
            location.reload();
          } else if (
            secondGameChooseName ===
            "Exercice musical guidé:  Les sons de la campagne"
          ) {
            window.location.href =
              "/#/activites?jeu_guidee=les_sons_de_la_campagne_guidee";
            location.reload();
          } else if (
            secondGameChooseName === "Exercice interactif guidé : Le safari"
          ) {
            window.location.href = "/#/activites?jeu_guidee=le_safari_guidee";
            location.reload();
          } else if (
            secondGameChooseName ===
            "Exercice musical guidé : Les dents de la mer"
          ) {
            window.location.href =
              "/#/activites?jeu_guidee=les_dents_de_la_mer_guidee";
            location.reload();
          }
        }
      }
    },
    async recupIdExercice(idExerciceSelected, file) {
      console.log("IdExerciceSelected:", idExerciceSelected);
      console.log("file:", file);
      //  if (file && file.attributes.game_type) {
      //    this.MethodForGame(file);
      //   }
      setTimeout(() => {
        if (this.abonnementFinish) {
          this.openModalSubscription();
          this.indexPopUpAbonnement++;
          return;
        }
      }, 10000);
      if (file && file.attributes.questionnaire_) {
        if (this.idPatientTherapeute) {
          this.resultatQCM_Object = await getResultatFromQCM(
            idExerciceSelected,
            this.idPatientTherapeute
          );
        } else if (this.idUserForSave) {
          this.resultatQCM_Object = await getResultatFromQCM(
            idExerciceSelected,
            this.idUserForSave
          );
        } else {
          this.resultatQCM_Object = await getResultatFromQCM(
            idExerciceSelected,
            this.user.objectId
          );
        }
      }
      this.answers = [];
      // this.showIntroExercice = true;
      this.timerExercice();
      if (idExerciceSelected == null && file == null) {
        this.idExerciceRecup = null;
        this.fileRecup = null;
        this.$forceUpdate();
        return;
      } else {
        if (file.attributes.questionnaire_) {
          this.recupScoreQuestionnaire(file);
        }
        return (
          ((this.idExerciceRecup = idExerciceSelected),
          (this.fileRecup = file)),
          this.$forceUpdate()
        );
      }
    },
    timerExercice() {
      this.exerciseStartTime = Date.now();
      setInterval(() => {
        this.elapsedTime = Math.floor(
          (Date.now() - this.exerciseStartTime) / 1000
        );
      }, 1000);
    },
    recupIdLink(IdLinkSelected) {
      this.presentation = false;
      this.question = true;
      this.QuestionnaireCompleted = false;
      return (
        (this.IdLink = IdLinkSelected),
        (this.number = 0),
        this.callQuestionnaire()
      );
    },
    async TerminerExercice(fileId, note) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      ParseConfig();
      // Continuer l'exécution du code ici
      try {
        //console.log('idPatientTherapeute:', this.idPatientTherapeute)
        //console.log('idUserForSave:', this.idUserForSave)
        Parse.Cloud.run(
          "saveExo",
          {
            idProtocol: this.idProtocol,
            SeanceSelected: this.SeanceSelected,
            fileId: fileId,
            IdLink: this.IdLink,
            idPatientTherapeute: this.idPatientTherapeute,
            idUserForSave: this.idUserForSave
              ? this.idUserForSave
              : this.idPatientTherapeute,
            elapsedTime: this.elapsedTime,
            note: 0,
            typeExercice: "AudioVideo",
          },

          {
            success: function (result) {
              //console.log("Sauvegarde effectué !");
            },
            error: function (error) {
              console.error(
                "Une erreur s'est produite lors de la sauvegarde :",
                error
              );
            },
          }
        );
        Toast.fire({
          icon: "success",
          title: "Votre exercice a bien été sauvegardé !",
        });
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Une erreur s'est produite lors de la sauvegarde",
          text: error,
        });
        console.error(
          "Une erreur s'est produite lors de la sauvegarde :",
          error
        );
      }
      var SeanceSelectedObject = this.SeanceSelectedObject;

      setTimeout(() => {
        this.recupIdExercice(null, null);
        this.recupId(this.folderBriqueRecup);
        this.recupIdSeance(this.SeanceSelected, SeanceSelectedObject);
        // if(typeof file !== 'undefined' && file && file[0])
        // {
        // this.recupScoreQuestionnaire(file[0]);
        // }
        console.log(this.SeanceSelectedObject);

        if (
          (file[0].attributes.name === "Exercice long" &&
            this.folderBriqueRecup.protocoles[3] !== undefined) ||
          this.ExerciceLongVideo
        ) {
          this.show_exercice_long();
        }
      }, 2000);
      setTimeout(() => {
        if (SeanceSelectedObject) {
          console.log("SeanceSelected:", SeanceSelectedObject);
          console.log(
            "completionPercentage:",
            SeanceSelectedObject.completionPercentage
          );
          console.log("Completed:", SeanceSelectedObject.completed);
          var completionPercentage = SeanceSelectedObject.completionPercentage;
          console.log("completionPercentage:", completionPercentage);
          if (completionPercentage === 100) {
            console.log("emitting pop-up-contact");
            this.$emit("pop-up-contact", SeanceSelectedObject);
          }
        }
      }, 6000);
      if (SeanceSelectedObject) {
        console.log("SeanceSelected:", SeanceSelectedObject);
        var completionPercentage = SeanceSelectedObject.completionPercentage;
        console.log("completionPercentage:", completionPercentage);
        if (completionPercentage === 100) {
          console.log("emitting pop-up-contact");
          this.$emit("pop-up-contact", SeanceSelectedObject);
        }
      }
    },
    async TerminerExerciceJeu(fileId, note) {
      console.log("TerminerExerciceJeu");
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      this.elapsedTime = this.elapsedTime / 60;
      //console.log('Voici le temps sauvegardé :', this.elapsedTime)
      //arrondir le temps à un nombre entier
      this.elapsedTime = Math.round(this.elapsedTime);
      try {
        Parse.Cloud.run(
          "saveExo",
          {
            idProtocol: this.idProtocol,
            SeanceSelected: this.SeanceSelected,
            fileId: fileId,
            IdLink: this.IdLink,
            idPatientTherapeute: this.idPatientTherapeute,
            idUserForSave: this.idUserForSave
              ? this.idUserForSave
              : this.idPatientTherapeute,
            elapsedTime: this.elapsedTime,
            resultat: this.ResultatJeux,
            note: 0,
            typeExercice: "Jeu",
          },

          {
            success: function (result) {
              //console.log("Sauvegarde effectué !");
            },
            error: function (error) {
              console.error(
                "Une erreur s'est produite lors de la sauvegarde :",
                error
              );
            },
          }
        );
        Toast.fire({
          icon: "success",
          title: "Votre exercice a bien été sauvegardé !",
        });
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Une erreur s'est produite lors de la sauvegarde",
          text: error,
        });
        console.error(
          "Une erreur s'est produite lors de la sauvegarde :",
          error
        );
      }

      setTimeout(() => {
        this.recupIdExercice(null, null);
        this.recupId(this.folderBriqueRecup);
        this.recupIdSeance(this.SeanceSelected, null);
      }, 2000);
    },
    toggleFullscreen(element) {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    chartFullScreen() {
      const myDiv = document.getElementById("chart-component");
      this.toggleFullscreen(myDiv);
    },
    async TerminerExerciceQCM(fileId, note) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      //console.log("Sauvegarde de l'exercice... 1");
      var noteQuestionnaire = note.toString();
      try {
        Parse.Cloud.run(
          "saveExo",
          {
            idProtocol: this.idProtocol,
            SeanceSelected: this.SeanceSelected,
            fileId: fileId,
            IdLink: this.IdLink,
            note: parseInt(noteQuestionnaire),
            idPatientTherapeute: this.idPatientTherapeute,
            idUserForSave: this.idUserForSave
              ? this.idUserForSave
              : this.idPatientTherapeute,
            elapsedTime: this.elapsedTime,
            answers: this.answers,
            note_detail: this.categoryScores,
            typeExercice: "Questionnaire",
          },
          {
            success: function (result) {
              //console.log("Sauvegarde effectué !");
            },
            error: function (error) {
              console.error(
                "Une erreur s'est produite lors de la sauvegarde :",
                error
              );
            },
          }
        );
        Toast.fire({
          icon: "success",
          title: "Votre exercice a bien été sauvegardé !",
        });
        this.categoryScores = {};
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Une erreur s'est produite lors de la sauvegarde",
          text: error,
        });
        console.error(
          "Une erreur s'est produite lors de la sauvegarde :",
          error
        );
      }

      this.$forceUpdate();
      setTimeout(() => {
        this.recupIdExercice(null, null);
        this.recupId(this.folderBriqueRecup);
        this.recupIdSeance(this.SeanceSelected, null);
        try{
        this.recupScoreQuestionnaire(file[0]);
      }
      catch{
        console.log("Erreur recupScoreQuestionnaire")
      }
        if (
          this.folderBriqueRecup.protocoles[2].name === "Entraînement libre"
        ) {
        } else {
          this.show_exercice_long();
        }
      }, 2000);
    },
    changeAudioPlaying(url) {
      this.audioURL = url;
    },
    NextQuestion(picked, question) {
      this.answers.push({
        question_id: question.id,
        question: question.attributes.text,
        note: picked,
      });
      this.resultat += picked;
      this.number++;
      this.answersQuestionnaire[this.number - 1] = picked;

      // Vérifier si la question a une catégorie
      if (question.attributes.category) {
        const categoryName = question.attributes.category.attributes.name;
        // Si un score cumulatif n'existe pas pour cette catégorie, le créer et l'initialiser à 0
        if (!this.categoryScores[categoryName]) {
          this.categoryScores[categoryName] = 0;
        }
        // Ajouter le score de la question au résultat cumulatif de la catégorie correspondante
        this.categoryScores[categoryName] += picked;
      }
      this.callQuestionnaire();
      this.picked = null;
    },
    PreviousQuestion(picked) {
      this.resultat -= picked;
      this.number--;
      this.answersQuestionnaire[this.number - 1] = picked;
      this.picked = this.answersQuestionnaire[this.number] || null;
      this.callQuestionnaire();
    },
    FinalResultat(e) {
      this.nameQuestionnaire = e.attributes.name;
      this.resultatQCM = true;
      this.question = false;
    },
    async callQuestionnaire() {
      const QueryQuestionnaire = new Parse.Query("questionnaire");
      const QueryQuestion = new Parse.Query("question");
      const QueryAnswer = new Parse.Query("question_answer");
      const QueryGameType = new Parse.Query("game_type");

      QueryQuestionnaire.equalTo("objectId", this.IdLink);
      QueryQuestion.equalTo("number", this.number);
      QueryQuestion.matchesQuery("questionnaire", QueryQuestionnaire);
      QueryAnswer.matchesQuery("question", QueryQuestion);
      QueryQuestion.include("category");
      this.element_questionnaire2 = await QueryQuestionnaire.find();
      this.element_answer2 = await QueryAnswer.find();
      this.element2 = await QueryQuestion.find();
      this.element_game2 = await QueryGameType.find();
    },
    buttonToFiche(fiche) {
      this.fiche = fiche;
    },
    scrollToSection() {
      setTimeout(() => {
        const section = document.querySelector("#protocol_list");
        section.scrollIntoView({ behavior: "smooth" });
      }, 200);
    },
    scrollToListExo() {
      setTimeout(() => {
        const section = document.querySelector("#list-exercice");
        section.scrollIntoView({ behavior: "smooth" });
      }, 400);
    },
    async callData() {
      //console.log('CallDataa')
      this.filesLink = [];
      this.checked = false;
      this.picked = null;
      this.loading2 = true;
      try {
        if (this.idPatientTherapeute) {
          const result = await callDataForProgrammes(
            this.idPatientTherapeute,
            this.SeanceSelected
          );
          this.filesLink = result.filesLink;
          this.loading2 = false;
          // console.log('IdPatientTherapeute', this.idPatientTherapeute, result.filesLink)
        } else if (this.idUserForSave) {
          const result = await callDataForProgrammes(
            this.idUserForSave,
            this.SeanceSelected
          );
          this.filesLink = result.filesLink;
          this.loading2 = false;
          // console.log('idUserForSave', this.idUserForSave, result.filesLink)
        } else {
          const result = await callDataForProgrammes(
            Parse.User.current().id,
            this.SeanceSelected
          );
          this.filesLink = result.filesLink;
          this.loading2 = false;
        }
      } catch {
        this.loading2 = false;
        console.error("ERROR");
      }
    },
    async findDataForRelecture() {
      const objectUserForRelecture = Parse.Object.extend("User");
      const QueryUserForRelecture = new Parse.Query(objectUserForRelecture);

      if (this.idPatientTherapeute) {
        QueryUserForRelecture.equalTo("objectId", this.idPatientTherapeute);
      } else if (this.idUserForSave) {
        QueryUserForRelecture.equalTo("objectId", this.idUserForSave);
      } else {
        QueryUserForRelecture.equalTo("objectId", this.user.objectId);
      }
      this.UserForRelecture = await QueryUserForRelecture.find();
      this.UserForRelecture = this.UserForRelecture[0];
      this.UserSelected = this.user;
      this.folders_for_relecture = this.folders;
      this.UserSelected.filesDone = await getFilesDoneByUserId(
        this.user.objectId
      );
      if (this.UserSelected.therapeute) {
        this.UserSelected.typePratique = "Thérapeute";
        this.UserSelected.acces_donnees_therapeute = true;
      }
    },
    recupIdPatient(idLocal) {
      var monTheme = "RFDxGKcZMj";
      var folderBrique = this.getFoldersByTheme(monTheme);
      const objectUser = Parse.Object.extend("User");
      const queryUser = new Parse.Query(objectUser);
      if (this.idPatientTherapeute) {
        queryUser.equalTo("objectId", this.idPatientTherapeute);
      } else {
        queryUser.equalTo("objectId", idLocal);
      }
      queryUser.include("link");

      const user = queryUser.find().then((e) => {
        this.element_user_local = e;
        if (this.element_user_local[0]) {
          const relation = this.element_user_local[0].get("link");
          const relationQuery = relation.query();
          relationQuery.exists("parent_folder");
          relationQuery.include("folder");
          relationQuery.exists("essai_gratuit");
          relationQuery.notEqualTo("folder.name", "protocole 1 vide");
          this.isDisplayProgrammes = false;
          relationQuery.find().then((linkedObjects) => {
            linkedObjects.forEach((linkedObject) => {
              if (
                linkedObject.attributes.order === 4 ||
                linkedObject.attributes.file ||
                (linkedObject.attributes.folder &&
                  linkedObject.attributes.folder.attributes.name ===
                    "protocole 1 vide") ||
                (linkedObject.attributes.folder &&
                  linkedObject.attributes.folder.attributes.VisibleFront ===
                    true) ||
                (linkedObject.attributes.folder &&
                  linkedObject.attributes.folder.attributes.name ===
                    "Entraînement libre") ||
                (linkedObject.attributes.parent_folder &&
                  linkedObject.attributes.parent_folder.attributes.name ===
                    "Entraînement libre") ||
                (linkedObject.attributes.parent_folder &&
                  linkedObject.attributes.parent_folder.attributes
                    .VisibleFront === false)
              ) {
                if (
                  linkedObject.attributes.folder &&
                  linkedObject.attributes.folder.attributes.VisibleFront ===
                    true
                ) {
                }
              } else {
                this.linkedObjectPatient.push(
                  linkedObject.attributes.parent_folder.id
                );
                this.isDisplayProgrammes = true;
                if (linkedObject.attributes.order === 2) {
                  var ProgrammeCourtFind = true;
                } else {
                  var ProgrammeCourtFind = false;
                }
                if (linkedObject.attributes.order === 3) {
                  var ProgrammeLongFind = true;
                } else {
                  var ProgrammeLongFind = false;
                }
                this.linkedFilePatient.push({
                  id: linkedObject.attributes.parent_folder.id,
                  court: ProgrammeCourtFind,
                  long: ProgrammeLongFind,
                });
              }
            });
            this.filteredFolderBrique = folderBrique.filter((folder) => {
              return this.linkedObjectPatient.includes(folder.id);
            });
            this.$emit("is-display-programmes", this.isDisplayProgrammes);
            this.isDisplayFolderBrique = true;
          });
        }
      });
    },
    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    },
    getParamJeu(param_jeu) {
      console.log("ParamJeuGuidee dans ProtocolFolders2:", this.ParamJeuGuidee);
      // Recherchez le folder avec le name "Biofeedback de cohérence cardiaque"
      const folder = this.folders.find(
        (folder) => folder.name === "Biofeedback de cohérence cardiaque"
      );
      // console.log('Seance Selected:', seance_selected);
      console.log("Folder dans ProtocolFolders2:", folder);
      if (folder) {
        this.recupId(folder);
      }
    },
  },
  async created() {
    if (this.ParamJeuGuidee) {
      this.getParamJeu(this.ParamJeuGuidee);
    }
    //console.log("User connecté:", this.user);
    this.verifToken();
    this.verifAbonnement();
    this.openLoading();
    this.isAndroid = await isAndroid();
    this.idUserForSave = this.getCookie("idUserForSave");
    if (this.idUserForSave || this.idPatientTherapeute) {
    } else if (Parse.User.current().attributes.therapeute) {
      this.isTherapeute = true;
    }
    //console.log("IdUserForSAVEEEEE:", this.idUserForSave);
    // let lastExercice;
    // if (this.idUserForSave) {
    //   lastExercice = await getLastExercice(this.idUserForSave);
    // }
    // else if (this.idPatientTherapeute) {
    //   lastExercice = await getLastExercice(this.idPatientTherapeute);
    // } else {
    //   lastExercice = await getLastExercice(Parse.User.current().id);
    // }

    // if (lastExercice && lastExercice.brique_id && lastExercice.protocole_id) {
    //   this.lastExercice = lastExercice;

    //   // Trouver le folder correspondant
    //   var matchingFolder = this.folders.find(
    //     (folder) => folder.id === lastExercice.brique_id
    //   );

    //   if (matchingFolder) {
    //
    //     this.recupId(matchingFolder)
    //     this.isDisplay = true;
    //     //mettre 1 seconde de délai pour que le loading s'affiche
    //     setTimeout(() => {
    //       this.recupIdSeance(lastExercice.protocole_id)
    //     }, 1000);
    //   } else {
    //     //console.log(
    //       "Aucun folder correspondant trouvé pour la brique_id donnée."
    //     );
    //   }
    // } else {
    //   //console.log("Aucun exercice trouvé pour cet utilisateur");
    // }

    this.findDataForRelecture();
  },
  mounted() {
    this.descriptionPro = this.group.description;
    // if (this.idPatientTherapeute) {
    //   this.recupIdPatient(this.idPatientTherapeute);
    // } else {
    //   this.recupIdPatient(Parse.User.current().id);
    // }
    ParseConfig();
    if (
      this.idPatientTherapeute ||
      !this.user.therapeute ||
      this.idUserForSave
    ) {
      //console.log("Affichage patient bloc..");
      this.affichagePatientBloc = true;
    }
    try {
      const ObjectFile = Parse.Object.extend("file");
      const QueryFile = new Parse.Query(ObjectFile);
      QueryFile.limit(1000)
        .find()
        .then((e) => {
          return (this.element = e);
        });
      tableau.push(element);
    } catch {}
    try {
      const ObjectLink = Parse.Object.extend("link");
      const QueryLink = new Parse.Query(ObjectLink);
      QueryLink.limit(1000)
        .find()
        .then((e) => {
          return (this.element_link = e);
        });
      tableau_link.push(element_link);
    } catch {}
  },
};
</script>

<style>
.descriptionPro {
  margin: 0px 19% 0px 19%;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 50%);
  background-color: rgba(236, 231, 231, 0.5);
  padding: 15px;
}

.protocolBloc {
  background-repeat: no-repeat;
  background-attachment: local;
  background-position: center center;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 50%);
  background-size: 100%;
}

.boxFolder {
  border: 5px solid #239380;
}

.buttonprog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  float: left;
}

.button:hover {
  border: solid 2px white;
}

.buttonprog {
  display: flex;
  align-items: center;
  justify-content: center;
}

#boxProgrammes {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  cursor: pointer;
  margin-top: 1%;
  border: 1px solid #00800069;

  /* Ajout de la règle suivante */
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
}

#boxProgrammes:hover {
  border: 1px solid green;
  background-color: rgb(72, 145, 129) !important;
  color: white !important;
}

#boxProgrammesActived {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  background-color: #489181 !important;
  border-radius: 6px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: white;
  display: block;
  padding: 1.25rem;
  cursor: pointer;
  margin-top: 1%;
  border: 1px solid #00800069;
}

.affichageText {
  background-color: #239380 !important;
}

@media (max-width: 900px) {
  #boxProgrammes {
    width: 100%;
    /* Prend toute la largeur disponible */
    margin-top: 2%;
  }
}
@media (min-width: 900px) {
  #boxProgrammes {
    width: 80%;
  }
}
@media (max-width: 900px) {
  #boxProgrammes {
    width: 100%;
  }
}
@media (max-width: 900px) {
  #boxProgrammesActived {
    width: 100%;
    /* Prend toute la largeur disponible */
    margin-top: 2%;
  }
}

@media (max-width: 1024px) {
  .divVideo {
    /* Prend toute la largeur disponible */
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .divVideo {
    width: 50%;
    /* Largeur fixe */
  }
}

@media (max-width: 768px) {
  .textSeance {
    width: 95%; /* plus de largeur sur un petit écran */
    padding: 1rem; /* Moins d'espacement intérieur sur les petits écrans */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .textSeance {
    width: 85%; /* largeur intermédiaire pour les tablettes */
  }
}

@media (min-width: 1025px) {
  .textSeance {
    width: 80%; /* largeur fixe sur un grand écran */
  }
}

.textSeance {
  border: 2px solid #239380;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  text-align: left;
  margin-bottom: 2%;
  margin-top: -6px;
  padding: 1.5%;
  border-radius: 0% 0% 10px 10px;
  background-color: white;
}

.arrowDown {
  width: 40px;
  margin-left: auto;
}

@media (max-width: 768px) {
  .arrowDown {
    width: 20px;
    /* Prend toute la largeur disponible */
    height: 10px;
  }
}

@media (min-width: 768px) {
  .arrowDown {
    width: 40px;
    /* Prend toute la largeur disponible */
  }
}

.arrowDown_exo {
  top: 50%;
}

@media (max-width: 768px) {
  .arrowDown_exo {
    width: 20px;
    /* Prend toute la largeur disponible */
  }
}

@media (min-width: 768px) {
  .arrowDown_exo {
    width: 40px;
    /* Prend toute la largeur disponible */
  }
}

.colorButton {
  background-color: #489181 !important;
  color: white !important;
}

.buttonProg {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  background-color: lightgray;
  color: black;
  margin: 8px;
  font-size: 80%;
}

.buttonProtocol {
  display: flex;
  justify-content: center;
  align-items: center;
}

#boxExercices {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  cursor: pointer;
  margin-top: 1%;
  border: 1px solid #00800069;
  display: flex;
}

@media (max-width: 768px) {
  #boxExercices {
    width: 100%;
    margin-top: 5%;
  }
}
.video-container-introduction {
  display: flex;
}

.video-player-introduction {
  flex: 1;
}

.video-playlist-introduction {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.video-playlist-introduction > div {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
}

.video-playlist-introduction img {
  width: 60px;
  height: 40px;
  object-fit: cover;
  margin-right: 8px;
}
@media (min-width: 768px) {
  #boxExercices {
    width: 95%;
    /* Prend toute la largeur disponible */
  }
}

@media (max-width: 768px) {
  #boxExercicesActived {
    width: 100%;
    /* Prend toute la largeur disponible */
    margin-top: 5%;
  }
}

@media (min-width: 768px) {
  #boxExercicesActived {
    width: 95%;
    margin-top: 1%;
  }
}

#boxExercices:hover {
  border: 1px solid green;
  background-color: #239380 !important;
  color: white !important;
}

#boxExercicesActived {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  background-color: rgb(72, 145, 129) !important;
  border-radius: 6px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: white;
  display: block;
  padding: 1.25rem;
  cursor: pointer;
  border: 1px solid #00800069;
  display: flex;
}

.buttonProg {
  transition: transform 0.5s ease;
}
.grayed {
  opacity: 0.6 !important;
}
.buttonProg.colorButton {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  #boxExercicesActived {
    width: 100%;
    /* Prend toute la largeur disponible */
  }
}

@media (min-width: 768px) {
  #boxExercicesActived {
    width: 95%;
    /* Prend toute la largeur disponible */
  }
}

#boxExercices_Audio {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  border-radius: 6px;
  color: #4a4a4a;
  display: block;
}

@media (max-width: 768px) {
  #boxExercices_Audio {
    margin-top: 1.5rem;
  }
}

@media (min-width: 768px) {
  #boxExercices_Audio {
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  #boxExercices_Audio {
    width: 100%;
    /* Prend toute la largeur disponible */
  }
}

@media (min-width: 768px) {
  #boxExercices_Audio {
    width: 95%;
    /* Prend toute la largeur disponible */
  }
}

#boxExercicesQCM {
  padding: 2%;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  background-color: #fff;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  border: 1px solid #00800069;
  margin-top: -6px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 0px 0px 6px 6px;
}

@media (max-width: 768px) {
  #boxExercicesQCM {
    width: 100%;
    /* Prend toute la largeur disponible */
  }
}

@media (min-width: 768px) {
  #boxExercicesQCM {
    width: 95%;
    /* Prend toute la largeur disponible */
  }
}

.bubble {
  width: 100%;
  border: 1px solid #00800069;
  background: #e5e5e5;
  position: relative;
  border-radius: 6px;
}
.bubblediv {
  margin-top: 1%;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 768px) {
  .bubblediv {
    width: 95%;
    /* Prend toute la largeur disponible */
  }
}

@media (min-width: 768px) {
  .bubblediv {
    width: 80%;
    /* Prend toute la largeur disponible */
  }
}

.triangle {
  width: 0;
  border-bottom: 20px solid rgb(72, 145, 129);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.VideoPlacement {
  margin: 0 auto;
}

@media (max-width: 768px) {
  .VideoPlacement {
    margin-top: 5%;
  }
}

@media (min-width: 768px) {
  .VideoPlacement {
    margin-top: 10px;
  }
}

/* .JeuPlacement {
  margin-left: auto;
  margin-right: auto;
  width: 55%;
} */

.IntroExoPlacement {
  margin-top: 1%;
  width: 55%;
  margin-right: auto;
  margin-left: auto;
}

.ButtonNextPreviousPlacements {
  text-align: center;
}

.buttonVideo {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  background-color: #489181;
  color: white;
  border: 0.5px solid white;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  margin: 0.5%;
  box-shadow: 0px 3px 6px #00000029;
}

.buttonVideo > a {
  text-decoration: none;
  color: black;
}

#boxProgrammes:hover > #ProgressBarModule {
  width: 95%;
  height: 10px;
  background-color: #c6c6c6;
  border-radius: 5px;
}

#boxProgrammes:hover > #ProgressBarModule > div {
  height: 100%;
  background-color: white;
  border-radius: 5px;
  transition: width 0.5s;
}

#ProgressBarModule {
  width: 95%;
  height: 10px;
  background-color: #c6c6c6;
  border-radius: 5px;
}

#ProgressBarModule > div {
  height: 100%;
  background-color: #239380;
  border-radius: 5px;
  transition: width 0.5s;
}

#ProgressBarModuleActived {
  width: 95%;
  height: 10px;
  background-color: #c6c6c6;
  border-radius: 5px;
}

#ProgressBarModuleActived > div {
  height: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  transition: width 0.5s;
}

.loading-spinner {
  /* Style the spinner */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(to right, #7ed56f 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
  position: relative;
  margin: auto;
  margin-top: 50px;
  /* Animate the spinner */
  animation: spin 2s linear infinite;
  animation-timing-function: linear;

  /* Add a transition effect */
  transition: all 0.2s ease-in-out;
}

.disabled {
  background-color: gray;
  color: black;
  cursor: not-allowed;
  pointer-events: none;
}

.loading-spinner::after {
  /* Style the arrow */
  content: "";
  width: 20px;
  height: 20px;
  border-top: 2px solid #489181;
  border-right: 2px solid #489181;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;

  /* Animate the arrow */
  animation: spin-arrow 2s linear infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    background-position: right bottom;
  }

  100% {
    transform: rotate(360deg);
    background-position: right bottom;
  }
}

@keyframes spin-arrow {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.loading-spinner:hover {
  /* Change the spinner color on hover */
  background: linear-gradient(to right, #5cb85c 50%, transparent 50%);
}

.loading-spinner:active {
  /* Change the spinner color when it's clicked */
  background: linear-gradient(to right, #449d44 50%, transparent 50%);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.loading-spinner2 {
  /* Style the spinner */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(to right, #7ed56f 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
  position: relative;
  margin: auto;
  margin-top: 10px;
  /* Animate the spinner */
  animation: spin 2s linear infinite;
  animation-timing-function: linear;

  /* Add a transition effect */
  transition: all 0.2s ease-in-out;
}

.ResultatScore {
  color: rgb(72, 145, 129) !important;
  font-size: x-large;
  text-align: center;
}

.loading-spinner2::after {
  /* Style the arrow */
  content: "";
  width: 20px;
  height: 20px;
  border-top: 2px solid #489181;
  border-right: 2px solid #489181;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;

  /* Animate the arrow */
  animation: spin-arrow 2s linear infinite;
  animation-timing-function: linear;
}

.texteQuestionnaireCompleted {
  text-align: center;
  padding-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .texteQuestionnaireCompleted {
    font-size: 0.8em;
    padding: 5px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    background-position: right bottom;
  }

  100% {
    transform: rotate(360deg);
    background-position: right bottom;
  }
}

@keyframes spin-arrow {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.loading-spinner2:hover {
  /* Change the spinner color on hover */
  background: linear-gradient(to right, #5cb85c 50%, transparent 50%);
}

.loading-spinner2:active {
  /* Change the spinner color when it's clicked */
  background: linear-gradient(to right, #449d44 50%, transparent 50%);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.BriqueActived {
  transition: transform 0.5s ease;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .texteExercice {
    font-size: 0.8em;
    padding: 5px;
  }
}

@media (min-width: 768px) {
  .texteExercice {
  }
}
@media (max-width: 768px) {
  .responsiveDivTop {
    margin-top: 4rem;
  }
}

@media (max-width: 768px) {
  .texteSeance {
    font-size: 1em;
    padding: 5px;
  }
}

@media (min-width: 768px) {
  .texteSeance {
  }
}

.slide-right-enter-active {
  animation: slide-right-enter 0.5s;
}

.slide-right-leave-active {
  animation: slide-right-leave 0.5s;
}

@keyframes slide-right-enter {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slide-right-leave {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.divVideo {
  margin-left: auto;
  margin-right: auto;
}
</style>

<style lang="scss">
// Display a cross with CSS only.
//
// $size  : px or em
// $color : color
// $thickness : px
@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  span {
    display: block;
  }
}
.selected-video {
  filter: grayscale(1);
}
// Example 1.
.btn-close {
  margin: 0;
  border: 0;
  padding: 0;
  background: rgb(72, 145, 129);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;

  .icon-cross {
    @include cross(20px, #fff, 6px);
  }

  &:hover,
  &:focus {
    transform: rotateZ(90deg);
    background: rgb(59, 163, 140);
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
  opacity: 0;
}

.notification-container {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

JeuUnityComponentVue {
  width: 100%;
  box-sizing: border-box;
}

.notification-box {
  background-color: #28a745;
  color: #fff;
  padding: 30px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
}

.notification-text {
}

.notification-close {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 5px;
}

.progress-bar-notification-container {
  width: 100%;
  height: 8px;
  margin-top: 20px;
  background-color: #eee;
  border-radius: 4px;
}

.progress-bar-notification {
  height: 100%;
  background-color: rgb(195, 194, 194);
  border-radius: 4px;
  transition: width 0.3s ease-in;
}

.MoniteurPlacement {
  margin-left: auto;
  margin-right: auto;
  width: 92%;
}

#protocol_list {
  margin-top: 2%;
}

@media (max-width: 768px) {
  .test1 {
    font-size: 12px;
  }
}

.PlacementImageSeance {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.scale-enter-active,
.scale-leave-active {
  transition: transform 2s;
}

.scale-enter,
.scale-leave-to {
  transform: scale(1);
}

.scale-enter-to,
.scale-leave {
  transform: scale(1.2);
}

.PlacementBoutonQuestionnaire {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListExercice {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .ListExercice {
    width: 80%;
    /* Prend toute la largeur disponible */
  }
}

@media (max-width: 768px) {
  .ListExercice {
    width: 95%;
    /* Prend toute la largeur disponible */
  }
}

.loader-start-page {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.loader-start-page::after,
.loader-start-page::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid black;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}

.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.LoaderBeginStart {
  display: flex;
  height: 400px;
}

.numeroExercice {
  margin-right: 15px;
  font-size: 20px;
}
.grayed {
  opacity: 0.7;
}
.video-container-introduction {
  width: 100%;
}

.video-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.video-player-introduction {
  flex: 1;
  min-width: 50%;
}

.video-playlist-introduction {
  flex: 1;
  min-width: 50%;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .video-player-introduction,
  .video-playlist-introduction {
    min-width: 100%;
  }
}
/* Couleurs lors du survol de infoBox */
.infoBox:hover .session-number,
.infoBox:hover .session-title,
.infoBox:hover .session-details,
.infoBox:hover .session-completion {
  color: white; /* Texte en blanc lors du survol */
}

/* Couleurs lorsque infoBox est en état "complété" ou "déverrouillé" */
.infoBox[style*="background-color: #489181"] .session-number,
.infoBox[style*="background-color: #489181"] .session-title,
.infoBox[style*="background-color: #489181"] .session-details,
.infoBox[style*="background-color: #489181"] .session-completion {
  color: white !important; /* Texte en blanc */
}
.infoBox {
  width: 80%;
  margin: 1% auto 0 auto;
  padding: 1rem !important;
  background-color: #fff;
  border: 1px solid #00800069;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 1%;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.infoBox:hover {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: #239380 !important;
  color: white !important;
}
.infoBox:hover .TextSeanceResponsive {
  color: white !important;
}

@media (max-width: 768px) {
  .infoBox {
    width: 95%; /* plus de largeur sur un petit écran */
    padding: 1rem; /* Moins d'espacement intérieur sur les petits écrans */
    margin-top: 3%;
  }
}
.textContent p {
  font-size: 16px;
  line-height: 1.4;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .infoBox {
    width: 85%; /* largeur intermédiaire pour les tablettes */
    margin-top: 2%;
  }
}

@media (min-width: 1025px) {
  .infoBox {
    width: 80%; /* largeur fixe sur un grand écran */
  }
}

.infoBox.activeState,
.infoBox:hover {
  background-color: #239380;
  color: white !important;
}

.infoBox:hover .toggleArrow {
  content: url("../../../assets/img/ChevronDownBlancV2.png");
}

/* Couleurs lors du survol de infoBox */
.infoBoxExo:hover .session-number,
.infoBoxExo:hover .session-title,
.infoBoxExo:hover .session-details,
.infoBoxExo:hover .session-completion {
  color: white; /* Texte en blanc lors du survol */
}

/* Couleurs lorsque infoBox est en état "complété" ou "déverrouillé" */
.infoBoxExo[style*="background-color: #489181"] .session-number,
.infoBoxExo[style*="background-color: #489181"] .session-title,
.infoBoxExo[style*="background-color: #489181"] .session-details,
.infoBoxExo[style*="background-color: #489181"] .session-completion {
  color: white; /* Texte en blanc */
}
.infoBoxExo {
  width: 95%;
  margin: 1% auto 0 auto;
  padding: 1rem !important;
  background-color: #fff;
  border: 1px solid #00800069;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 1%;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.infoBoxExo:hover {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: #239380 !important;
  color: white !important;
}
.infoBoxExo:hover .TextSeanceResponsive {
  color: white !important;
}

@media (max-width: 768px) {
  .infoBoxExo {
    width: 95%; /* plus de largeur sur un petit écran */
    padding: 1rem; /* Moins d'espacement intérieur sur les petits écrans */
    margin-top: 3%;
  }
}
.textContent p {
  font-size: 16px;
  line-height: 1.4;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .infoBoxExo {
    width: 95%; /* largeur intermédiaire pour les tablettes */
    margin-top: 2%;
  }
}

@media (min-width: 1025px) {
  .infoBoxExo {
    width: 95%; /* largeur fixe sur un grand écran */
  }
}

.infoBoxExo.activeState,
.infoBoxExo:hover {
  background-color: #239380;
  color: white !important;
}

.infoBoxExo:hover .toggleArrow {
  content: url("../../../assets/img/ChevronDownBlancV2.png");
}
.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textContent,
.iconContent {
  display: flex;
  align-items: center;
}

.textContent p {
  font-size: 1.3rem;
}

@media (max-width: 768px) {
  .textContent p {
    font-size: 1.2rem; /* taille du texte plus petite pour les petits écrans */
  }
}

.toggleArrow {
  width: 40px;
  transition: all 0.3s ease;
}

.toggleArrow.activeArrow {
  transform: rotate(180deg);
}

/* Responsive */
@media (max-width: 768px) {
  .toggleArrow {
    width: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .toggleArrow {
    width: 30px; /* taille intermédiaire pour les tablettes */
  }
}

@media (min-width: 1025px) {
  .toggleArrow {
    width: 40px;
  }
}

@media (max-width: 768px) {
  .TextSeanceResponsive {
    width: 90%;
  }
}
/* Styliser le texte */
.TextSeanceResponsive {
  margin: 0;
  font-size: 1em;
}

/* Styles spécifiques pour les éléments individuels */
.session-number {
  font-weight: bold; /* gras */
}

.session-title {
  margin-left: 10px;
}

.session-details {
  margin-left: 10px;
  font-size: 0.9em;
}

.session-completion {
  margin-left: 10px;
}

/* Les icônes */
.toggleArrow {
  width: 32px;
  height: auto;
}

/* Media queries pour le responsive */
@media (max-width: 768px) {
  .TextSeanceResponsive {
    font-size: 0.9em;
  }
  .toggleArrow {
    width: 20px;
  }
}

.activeSession {
  background-color: #239380 !important;
  color: white !important;
}
.IconExercice {
  max-width: 30px;
  max-height: 30px;
  vertical-align: middle;
  margin-right: 10px;
}
/* Responsive pour les petits écrans */
@media (max-width: 768px) {
  .IconExercice {
    max-width: 20px;
    max-height: 20px;
  }
}

/* Responsive pour les écrans de taille intermédiaire */
@media (min-width: 769px) and (max-width: 1024px) {
  .IconExercice {
    max-width: 25px;
    max-height: 25px;
  }
}

/* Responsive pour les grands écrans */
@media (min-width: 1025px) {
  .IconExercice {
    max-width: 30px;
    max-height: 30px;
  }
}
.infoBoxExo:hover .IconAudioExercice {
  content: url("../../../assets/img/IconAudioWhite.png");
}
.infoBoxExo:hover .IconExerciceVideo {
  content: url("../../../assets/img/IconVideoWhite.png");
}
.infoBoxExo:hover .IconJeu {
  content: url("../../../assets/img/IconJeuWhite.png");
}
.infoBoxExo:hover .IconQuestionnaire {
  content: url("../../../assets/img/IconQuestionnaireWhite.png");
}
.infoBoxExo:hover .IconMoniteur {
  content: url("../../../assets/img/IconMoniteurWhite.png");
}
</style>
