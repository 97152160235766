<template>
  <div>
    <div class="container">
      <div v-if="loading" class="loading">Chargement...</div>
      <div class="table-container" v-else>
        <div>
          <button class="chart-button" v-on:click="findDataCC()">
            <!-- <i class="fas fa-chart-bar"></i> -->
            Afficher le graphique du suivi de l'indice de lâcher prise
            <i
              :class="[
                'fas',
                displayChartAverageCC ? 'fa-chevron-up' : 'fa-chevron-down',
              ]"
            ></i>
          </button>
          <div v-if="loadingAverage" class="loader"></div>
          <div v-if="displayChartAverageCC" id="chartResultId"> 
            <button
            class="chart-button-fullscreen my-button"
            v-on:click="toggleFullScreen()"
          >
            Afficher en plein écran le graphique
          </button>
          <button class="chart-button-fullscreen my-button" v-on:click="printChart()">
            Imprimer le graphique
          </button>
            <chart-result
              v-if="ArrayDates.length > 0"
              :dates="ArrayDates"
              :scores="ArrayAverageCCs"
            />
          </div>
        </div>
        <table class="data-table">
          <thead>
            <tr>
              <th @click="sort('name')" class="sortable">
                Nom de l'exercice
                <span class="icon" v-if="sortKey === 'name'">▲</span>
              </th>
              <th @click="sort('module')" class="sortable">
                Module
                <span class="icon" v-if="sortKey === 'module'">▲</span>
              </th>
              <th @click="sort('date')" class="sortable">
                Date de réalisation
                <span class="icon" v-if="sortKey === 'date'">▲</span>
              </th>
              <th>Détail</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(fileDone, index) in paginatedFiles">
              <tr :key="index">
                <td>{{ fileDone.file.attributes.name }}</td>
                <td>{{ fileDone.module.attributes.name }}</td>
                <td>{{ "le " + formatDate(fileDone.createdAt) }}</td>
                <td class="centered-button">
                  <button
                    class="button is-small button-custom"
                    v-on:click="MoreInformations(fileDone)"
                  >
                    <i class="icon fas fa-info-circle"></i>
                    <p>détail</p>
                  </button>
                </td>
              </tr>

              <tr
                class="divRelecture full-width"
                v-if="detailsVisible === fileDone.id"
                :key="fileDone.id + '-details'"
              >
                <td :colspan="4">
                  <AppelRelectureAniVue
                    @commentaire-change="handleCommentaireChange"
                    @save-change="saveCommentaireChange"
                    class="ComponentRelecture"
                    :valeurX="valeurX"
                    :fcMoyenne="BpmCC"
                    :ccMoyenne="AverageCC"
                    :couleurCCMoyenne="couleurCCMoyenne"
                    :TabRowFC="valeurY"
                    :DefaultOpenedDetails="DefaultOpenedDetails"
                    :TabRowCC="valeurCCY"
                    :ValeurCCX="valeurCCX"
                    :labelChrono="labelChrono"
                    :timeElapsed="timeElapsed"
                    :comment="comment"
                  />
                  <div v-if="showNotification">
                    <b-notification
                      :type="notificationType"
                      :message="notificationMessage"
                      :has-icon="true"
                      :closable="true"
                      position="is-bottom-right"
                      @close="showNotification = false"
                    ></b-notification>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1">
            Précedent
          </button>
          <span>Page {{ currentPage }} sur {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">
            Suivante
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFilesDonesANI } from "../../services/relecture/getFilesDonesANI.js";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import AppelRelectureAniVue from "../relecture/relectureDetailsAni.vue";
import ChartResult from "../chart/chartResultComparaison.vue";

export default {
  data() {
    return {
      filesDoneAni: [],
      loading: false,
      detailsVisible: null,
      sortKey: "",
      DefaultOpenedDetails: "5VBBk5J53k",
      resultatAni: [],
      valeurX: 0,
      BpmCC: 0,
      AverageCC: 0,
      couleurCCMoyenne: "#FE0000",
      valeurY: 0,
      valeurCCX: 0,
      valeurCCY: 0,
      labelChrono: 0,
      timeElapsed: 0,
      comment: "",
      commentaireMoniteur: "",
      filesDoneSelectedId: "",
      showNotification: false,
      notificationType: "",
      notificationMessage: "",
      currentPage: 1,
      itemsPerPage: 10,
      displayChartAverageCC: false,
      loadingAverage: false,
      ArrayDates: [],
      ArrayAverageCCs: [],
      suiviDataAni: [],
    };
  },
  computed: {
    sortedFilesDoneAni() {
      if (!this.sortKey) return this.filesDoneAni;
      // Création d'une copie du tableau original avant le tri
      return [...this.filesDoneAni].sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return bDate - aDate;
      });
    },
    paginatedFiles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      //inverser l'ordre
      return this.sortedFilesDoneAni.slice().reverse().slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.sortedFilesDoneAni.length / this.itemsPerPage);
    },
  },
  components: {
    AppelRelectureAniVue,
    ChartResult,
  },
  methods: {
    toggleFullScreen() {
      let element = document.getElementById("chartResultId");
      if (!document.fullscreenElement) {
        element.requestFullscreen().catch((err) => {
          alert(
            `Erreur lors de la tentative de passage en plein écran: ${err.message} (${err.name})`
          );
        });
        this.isFullScreen = true; // Mettre à jour l'état en plein écran
      } else {
        document.exitFullscreen();
      }
    },
    exitFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.isFullScreen = false; // Mettre à jour l'état lors de la sortie du plein écran
      }
    },

    async printChart() {
      const element = document.getElementById("chartResultId");

      // Ajoutez la classe 'no-print' aux éléments à cacher
      const buttons = element.querySelectorAll(".my-button");
      buttons.forEach((btn) => btn.classList.add("no-print"));

      const canvas = await html2canvas(element);

      // Création et sauvegarde du PDF comme avant
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        0,
        0,
        canvas.width,
        canvas.height
      );
      pdf.save("resultat.pdf");

      // Retirez la classe 'no-print' après la création du PDF
      buttons.forEach((btn) => btn.classList.remove("no-print"));
    },
    async findDataCC() {
      if (this.displayChartAverageCC) {
        this.displayChartAverageCC = false;
        return;
      }
      this.loadingAverage = true;
      console.log("filteredFilesDones avant filtrage", this.sortedFilesDoneAni);
      let dates = []; // Tableau pour stocker les dates
      let averageCCs = []; // Tableau pour stocker les AverageCC

      // Traiter chaque fichier filtré et effectuer des requêtes asynchrones
      for (const file of this.sortedFilesDoneAni) {
        try {
          console.log("Récupération des données pour", file);
          var AverageCC = file.resultatJeux.AverageCC;
          var createdAt = new Date(file.createdAt);
          const options = { year: "numeric", month: "long", day: "numeric" };
          const frenchDate = new Intl.DateTimeFormat("fr-FR", options).format(
            createdAt
          );
          //mettre en français la date
          console.log("Date", frenchDate);
          console.log("AverageCC", AverageCC);
          if (AverageCC > 0) {
            dates.push(frenchDate);
            averageCCs.push(AverageCC);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données pour",
            file.key,
            ":",
            error
          );
        }
      }

      // Affichage des tableaux des résultats
      console.log("Tableau des dates :", dates);
      console.log("Tableau des AverageCC :", averageCCs);
      this.ArrayDates = dates;
      this.ArrayAverageCCs = averageCCs;
      this.displayChartAverageCC = true;
      this.loadingAverage = false;
    },
    saveCommentaireChange() {
      const queryFileDone = new Parse.Query("file_done");
      queryFileDone.equalTo("objectId", this.filesDoneSelectedId);
      queryFileDone.first().then((fileDone) => {
        fileDone.set("comment", this.commentaireANI);
        fileDone.save().then((result) => {
          console.log("Commentaire enregistré avec succès", result);
          this.showNotification = true;
          this.notificationType = "is-success"; // ou "is-danger" pour une notification d'erreur
          this.notificationMessage =
            "Le commentaire a été enregistré avec succès.";
        });
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    MoreInformations(result) {
      this.filesDoneSelectedId = result.id;
      // Vérifiez d'abord que resultatJeux et ArrayTacho existent
      if (result.resultatJeux && result.resultatJeux.ArrayTacho) {
        // Utilisez la méthode map pour extraire tous les x et diviser par 500 pour obtenir le temps en secondes
        this.valeurX = result.resultatJeux.ArrayTacho.map((item) => item.x);
        //arrondir à l'unité
        this.valeurX = this.valeurX.map((item) => Math.round(item));

        this.valeurY = result.resultatJeux.ArrayTacho.map((item) => item.y);
      }
      if (result.resultatJeux && result.resultatJeux.TabCC) {
        this.valeurCCX = result.resultatJeux.TabCC.map((item) => item.x);
        this.valeurCCX = this.valeurCCX.map((item) => Math.round(item));
        this.valeurCCY = result.resultatJeux.TabCC.map((item) => item.y);
      }
      this.BpmCC = result.resultatJeux.BpmCC;
      this.AverageCC = result.resultatJeux.AverageCC;
      if(this.AverageCC >= 85) {
        this.couleurCCMoyenne = "#51C901";
      } else if(this.AverageCC >= 60) {
        this.couleurCCMoyenne = "#FFC801";
      } else {
        this.couleurCCMoyenne = "#FE0000";
      }
      this.labelChrono = result.timeCalibrage;
      this.timeElapsed = result.time_elapsed;
      this.comment = result.comment;
      this.detailsVisible =
        this.detailsVisible === result.id ? null : result.id;
    },
    handleCommentaireChange(commentaire) {
      this.commentaireANI = commentaire;
    },
    async getFilesDones() {
      this.loading = true;
      ParseConfig();
      this.filesDoneAni = await getFilesDonesANI(Parse.User.current().id);
      this.loading = false;
    },
    formatDate(date) {
      return format(new Date(date), "dd/MM/yyyy à HH:mm:ss", { locale: fr });
    },
    sort(key) {
      this.sortKey = key;
    },
  },
  created() {
    this.getFilesDones();
  },
  mounted() {
    setTimeout(() => {
      this.findDataCC();
    }, 1000);
  },
};
</script>

<!-- Le reste du script reste le même -->

<style scoped>
.container {
  margin: 0 auto;
  padding: 20px;
  min-width: 100%;
}

.loading {
  font-size: 20px;
  color: #888;
  text-align: center;
  padding: 20px;
}

.table-container {
  /* min-width: 100%; */
  /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);  Add shadow for depth */
  border-radius: 5px; /* Round corners for modern look */
  overflow: hidden; /* Hide overflow */
}

.data-table {
  border-collapse: separate; /* Use separate to add space between rows */
  border-spacing: 0 10px; /* Add vertical space between rows */
  width: 100%;
  table-layout: auto;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background: #ffffff; /* Add background color */
  border-radius: 5px; /* Round corners */
}

.data-table th {
  background-color: rgb(35, 147, 128);
  color: white;
  position: sticky;
  top: 0;
}

.sortable {
  cursor: pointer;
}

.sortable:hover {
  background-color: #ddd;
}

.sortable .icon {
  display: inline-block;
  margin-left: 5px;
}

.data-table tr:hover {
  background-color: #f5f5f5;
}

.data-table .even-row {
  background-color: #f2f2f2;
}

.button-custom {
  background-color: #218977;
  border-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  padding: 10px 15px; /* Make button a bit bigger */
}

.centered-button {
  text-align: center;
}

.button-custom:hover {
  background-color: #4d978b;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.icon {
  margin-right: 0.25rem;
}

.ComponentRelecture {
  margin-left: auto;
  margin-right: auto;
}

.divRelecture.full-width {
  width: 100%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: "Arial", sans-serif;
}

.pagination button {
  background-color: #489181;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #6d968d;
}

.pagination span {
  font-size: 18px;
  margin: 0 10px;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey background */
  border-top: 8px solid #239380; /* Blue color for the spinner part */
  border-radius: 50%; /* Circular border */
  width: 40px; /* Size of the loader */
  height: 40px; /* Size of the loader */
  animation: spin 2s linear infinite; /* Animation to spin the loader */
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.chart-button {
  background-color: #239380; /* Green background */
  color: white; /* White text */
  padding: 12px 24px; /* Padding around the text */
  font-size: 16px; /* Button text size */
  border: none; /* No borders */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  display: inline-flex; /* Flexbox for inline alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center text and icon horizontally */
  margin-bottom: 1rem; /* Add some space below the button */
}

.chart-button:hover {
  background-color: #1e7c6d; /* Darker shade of green on hover */
  transform: translateY(2px); /* Slightly move the button down */
}

.chart-button i {
  margin-left: 8px; /* Space between text and icon */
}

.chart-button i:last-child {
  margin-right: 0; /* Pas d'espace à droite du chevron */
  margin-left: 8px; /* Espace entre le texte et le chevron */
}


.no-print {
  display: none !important;
}


.chart-button-fullscreen {
  background-color: #239380; /* Green background */
  color: white; /* White text */
  padding: 6px 12px; /* Padding around the text */
  font-size: 12px; /* Button text size */
  border: none; /* No borders */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  display: inline-flex; /* Flexbox for inline alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center text and icon horizontally */
  margin-bottom: 1rem; /* Add some space below the button */
  margin-left: auto;
  display: flex;
  margin-right: 1rem;
  margin-top: 1rem;
}

</style>
