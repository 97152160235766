<template>
  <div class="full-screen-container">
    <!-- <timer
      @start-timer="handleStartTimer"
      :isConnected="isConnected"
      @finish="finishAni"
      :lastAni="lastAni"
      :lastBPM="lastBPM"
    ></timer> -->
    <button class="full-screen-button" @click="fullScreenDivContainer()">
      <i class="fas fa-expand"></i> Plein écran
    </button>

    <div class="InformationDivParentTimer">
      <div v-show="timerRunning" class="timer-display" id="timer">{{ time }}</div>
    </div>
    <span class="error-socket-ani" v-if="errorSocketAni"
      >Une erreur de signal a été détectée. Veuillez vérifier votre connexion et
      votre matériel.</span
    >
    <div id="PlacementMoniteurPasLance" v-if="!isConnected">
      <div id="sensor-instructions-container">
        <div class="button-container">
          <button
            v-on:click="openAppInAppStore()"
            class="buttonChart"
            style="background-color: rgb(35, 147, 128); color: white"
          >
            Ouvrir le SymbioSensor
          </button>
        </div>
        <p class="sensor-instructions-heading">
          Si vous avez déjà installé le SymbioSensor, assurez-vous des points
          suivants :
        </p>
        <ul class="sensor-instructions-list">
          <li>
            Le logiciel SymbioSensor est en cours d'exécution sur votre
            ordinateur et utilise le même identifiant que votre session en
            cours.
          </li>
          <li>Le capteur est correctement connecté à votre machine.</li>
        </ul>
      </div>
    </div>
    <div v-if="isConnected">
      <real-time-chart
        :value1="MoyenneAniMinute"
        :value2="lastAni"
        :lastBPM="lastBPM"
      />
    </div>
    <!-- <button @click='test(MoyenneAniMinute,lastAni,lastBPM)'>TESTT</button> -->
    <div class="InformationDivParent">
      <div class="InformationDiv InformationDivAni">
        <span id="heartAni">&hearts; {{ lastAni }} </span>
        <small>Indice de lâcher-prise Instantanné</small>
      </div>
      <div>
        <div v-if="CalibrationTime" class="calibration-container">
          <div class="loader-calibration-ani"></div>
          Calibration en cours...
        </div>
        <button
          class="startButton"
          @click="startTimer(), handleStartTimer()"
          v-if="!timerStarted"
          :disabled="!isConnected"
        >
          Démarrer
        </button>
        <button
          class="finish-button"
          @click="finishAni()"
          v-if="timerStarted"
          :disabled="!canFinish"
        >
          Terminer
        </button>
      </div>
      <div class="InformationDiv InformationDivBpm">
        <span id="heartBpm">&hearts; {{ lastBPM }} </span>

        <small>Battements par minutes</small>
      </div>
    </div>

    <jauge-chart :value="MoyenneAniMinute" :isConnected="isConnected" />
    <popUpRelecture
      :FinishAniBool="FinishAniBool"
      :valeurX="valeurX"
      :fcMoyenne="fcMoyenne"
      :ccMoyenne="ccMoyenne"
      :couleurCCMoyenne="couleurCCMoyenne"
      :valeurY="valeurY"
      :DefaultOpenedDetails="DefaultOpenedDetails"
      :valeurCCX="valeurCCX"
      :valeurCCY="valeurCCY"
      :TimeCalibrage="TimeCalibrage"
      :elapsedExerciseTime="elapsedExerciseTime"
      @save-data="handleSaveData"
    />
  </div>
</template>
<script>
import jaugeChart from "./jaugeChart.vue";
import RealTimeChart from "./RealTimeChart.vue";
import Timer from "./Timer.vue";
import popUpRelecture from "./popUpRelecture.vue";

import io from "socket.io-client";
import Parse from "parse";

import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  data() {
    return {
      tabAni: [],
      tabBPM: [],
      lastBPM: 0,
      lastAni: 0,
      totalAni: 0,
      numAni: 0,
      MoyenneAniMinute: 75,

      elapsedExerciseTime: 0,
      startTime: null, // Pour enregistrer le moment de début de l'exercice
      elapsedExerciseTime: 0,

      aniRecords: [],
      averageAniRecords: [],
      os: "",
      connexionWebSocket: false,
      isConnected: false,
      timerStarted: false,

      TimeCalibrage: 0,
      valeurX: [],
      valeurY: [],
      ccMoyenne: 0,
      fcMoyenne: 0,
      valeurCCX: [],
      valeurCCY: [],
      FinishAniBool: false,
      couleurCCMoyenne: "#FE0000",
      DefaultOpenedDetails: "5VBBk5J53k",
      previousBPM: 0,
      socket: null,
      errorSocketAni: false,
      time: "00:00",
      startTime: null,
      currentTime: null,
      timerRunning: false,
      interval: null,
      canFinish: false,
      CalibrationTime: false,
    };
  },
  components: {
    jaugeChart,
    RealTimeChart,
    Timer,
    popUpRelecture,
  },
  computed: {
    MoyenneAni() {
      if (this.totalAni && this.numAni && this.timerStarted) {
        return Math.round(this.totalAni / this.numAni);
      }
      return 75;
    },
    newAni() {
      return this.lastAni;
    },
  },
  methods: {
    openAppInAppStore() {
      if (navigator.platform === "MacIntel") {
        // Ouvre l'application SymbioSensor dans le Mac App Store si l'application VueJS est exécutée sur un Mac
        window.open(
          "https://apps.apple.com/fr/app/symbio/id1611896454",
          "_blank"
        );
      } else {
        // Ouvre l'application SymbioSensor dans le Microsoft Store si l'application VueJS est exécutée sur un autre système d'exploitation
        window.open("ms-windows-store://pdp/?productid=9NQ9QQ9T3R2W", "_blank");
      }
    },
    fullScreenDivContainer() {
      const elem = document.querySelector(".full-screen-container");

      // Vérifier si le document est déjà en plein écran
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        // Tenter d'entrer en plein écran
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari et Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen();
        }
      } else {
        // Sortir du plein écran
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari et Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen();
        }
      }
      this.updateButton();
    },
    updateButton() {
      const button = document.querySelector(".full-screen-button i");
      const text = document.querySelector(".full-screen-button span");
      if (document.fullscreenElement) {
        button.className = "fas fa-compress";
        text.textContent = "Quitter le plein écran";
      } else {
        button.className = "fas fa-expand";
        text.textContent = "Plein écran";
      }
    },
    finishAni() {
      // this.TimeCalibrage = this.convertToSeconds(time);
      this.valeurX = this.aniRecords.map((item) => item.x);
      this.valeurY = this.aniRecords.map((item) => item.y);
      this.ccMoyenne = this.MoyenneAni;
      this.ccMoyenne = Math.round(this.ccMoyenne);
      this.valeurCCX = this.averageAniRecords.map((item) => item.x);
      this.valeurCCY = this.averageAniRecords.map((item) => item.y);
      const somme = this.tabBPM.reduce((acc, curr) => acc + curr, 0);
      const moyenne = Math.round(somme / this.tabBPM.length);
      this.fcMoyenne = moyenne;

      if (this.interval) {
        clearInterval(this.interval);
      }

      // Convertissez time en secondes si nécessaire et effectuez les opérations finales
      this.TimeCalibrage = 60;
      this.FinishAniBool = true;

      if (this.socket) {
        this.socket.disconnect(); // Utilisez .close() si votre bibliothèque le requiert
        this.connexionWebSocket = false; // Mettez à jour l'état de la connexion WebSocket
        console.log("Socket disconnected");
      }
      console.log(
        "TimeCalibrage:",
        this.TimeCalibrage,
        "elapsedExerciseTime:",
        this.elapsedExerciseTime,
        "MoyenneAni:",
        this.MoyenneAni,
        "fcMoyenne:",
        this.fcMoyenne,
        "ccMoyenne:",
        this.ccMoyenne,
        "couleurCCMoyenne:",
        this.couleurCCMoyenne,
        "valeurX:",
        this.valeurX,
        "valeurY:",
        this.valeurY,
        "valeurCCX:",
        this.valeurCCX,
        "valeurCCY:",
        this.valeurCCY
      );
    },
    formatTime(milliseconds) {
      let totalSeconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;

      minutes = String(minutes).padStart(2, "0");
      seconds = String(seconds).padStart(2, "0");

      return `${minutes}:${seconds}`;
    },
    async handleSaveData() {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      console.log("Données sauvegardées");
      //créer l'objet résultat :
      var resultat = {
        ArrayTacho: this.aniRecords,
        TabCC: this.averageAniRecords,
        AverageCC: this.MoyenneAni,
        TimeCompletion: this.elapsedExerciseTime,
        Timer: this.TimeCalibrage,
        BpmCC: this.fcMoyenne,
      };
      //le passer en string
      var resultatString = JSON.stringify(resultat);
      const ObjectFolder = Parse.Object.extend("folder");
      const ObjectFile = Parse.Object.extend("file");
      const ObjectGroup = Parse.Object.extend("user_group");
      const ObjetLink = Parse.Object.extend("link");
      const ObjectUser = Parse.Object.extend("User");
      const QueryUser = new Parse.Query(ObjectUser);
      const QueryLink = new Parse.Query(ObjetLink);
      const QueryFolder = new Parse.Query(ObjectFolder);
      const QueryFolder2 = new Parse.Query(ObjectFolder);
      const QueryFile = new Parse.Query(ObjectFile);
      const QueryGroup = new Parse.Query(ObjectGroup);
      QueryFolder.equalTo("objectId", "4PBNNhoevP");
      QueryFolder2.equalTo("objectId", "wtjd8wT4W7");

      QueryFile.equalTo("objectId", "ZoRAP8YFNC");
      QueryLink.include("file");
      QueryLink.matchesQuery("file", QueryFile);
      let brique, module, file, user, link;

      QueryUser.equalTo("objectId", Parse.User.current().id);
      [brique, module, file, user, link] = await Promise.all([
        QueryFolder.find(),
        QueryFolder2.find(),
        QueryFile.find(),
        QueryUser.find(),
        QueryLink.find(),
      ]);

      var timeCalibrage = Number(this.TimeCalibrage);
      const dataFileDone = {
        brique: brique[0],
        time_elapsed: this.elapsedExerciseTime,
        user: user[0],
        module: module[0],
        protocole: module[0],
        resultatJeux: resultatString,
        note: null,
        file: file[0],
        comment: this.commentaireANI,
        group: Parse.User.current().get("group"),
        timeCalibrage: timeCalibrage,
        ANI: true,
      };
      const Data = Parse.Object.extend("file_done");
      const dataDone = new Data();
      try {
        await dataDone.save(dataFileDone);
        this.FinishAniBool = false;

        Toast.fire({
          icon: "success",
          title: "Votre exercice sa bien été sauvegardé !",
        });
        this.refreshPage();
      } catch (error) {
        //console.log("Error while saving data", error);
        Toast.fire({
          icon: "error",
          title: "Une erreur s'est produite lors de la sauvegarde",
          text: error,
        });
        this.refreshPage();
      }
    },
    test(MoyenneAniMinute, lastAni, lastBPM) {
      console.log("MoyenneAniMinute:", MoyenneAniMinute);
      console.log("lastAni:", lastAni);
      console.log("lastBPM:", lastBPM);
    },
    performActionBasedOnOS() {
      const os = this.getOperatingSystem();
      if (os === "Mac") {
        this.os = "Mac";
      } else if (os === "Windows") {
        this.os = "Windows";
      } else {
        this.os = "Inconnu";
      }
    },
    refreshPage() {
      window.location.reload();
    },
    resetComponent() {
      this.FinishAniBool = false;
      this.elapsedExerciseTime = 0;
      this.startTime = null;
      this.elapsedExerciseTime = 0;
      this.aniRecords = [];
      this.averageAniRecords = [];
      this.totalAni = 0;
      this.numAni = 0;
      this.lastBPM = 0;
      this.lastAni = 0;
      this.tabAni = [];
      this.tabBPM = [];
      this.MoyenneAniMinute = 0;
      this.TimeCalibrage = 0;
      this.valeurX = [];
      this.valeurY = [];
      this.ccMoyenne = 0;
      this.fcMoyenne = 0;
      this.valeurCCX = [];
      this.valeurCCY = [];
      this.couleurCCMoyenne = "#FE0000";
      this.DefaultOpenedDetails = "5VBBk5J53k";
      this.socket = null;
      // this.connectSocket();
    },
    handleStartTimer() {
      this.CalibrationTime = true;
      this.timerStarted = true;
      this.startTime = Date.now(); // Enregistre le moment de début

      // Initialiser un intervalle pour mettre à jour le temps écoulé toutes les secondes
      this.interval = setInterval(() => {
        // Calcul du temps écoulé en secondes depuis le début
        this.elapsedExerciseTime = Math.floor(
          (Date.now() - this.startTime) / 1000
        );
      }, 1000);
    },
    convertToSeconds(timeString) {
      const parts = timeString.split(":"); // Sépare la chaîne par le caractère ':'
      const minutes = parseInt(parts[0], 10); // Convertit la partie des minutes en entier
      const seconds = parseInt(parts[1], 10); // Convertit la partie des secondes en entier

      return minutes * 60 + seconds; // Retourne le total en secondes
    },
    startTimer() {
      if (!this.timerRunning) {
        this.timerRunning = true;
        this.startTime = Date.now();
        this.interval = setInterval(() => {
          const elapsedTime = Date.now() - this.startTime;
          this.time = this.formatTime(elapsedTime);
          if (elapsedTime >= 30000 && !this.canFinish) {
            console.log("Can finish");
            this.canFinish = true;
          }
        }, 1000);
        this.$emit("start-timer");
      }
    },
    getOperatingSystem() {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;
      const macPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
      const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];

      if (macPlatforms.indexOf(platform) !== -1) {
        return "Mac";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return "Windows";
      } else {
        return "Inconnu";
      }
    },
    connectSocket() {
      this.socket = io.connect("https://www.symbiocenter.com", {});
      let windowData = []; // stocke les données de la fenêtre glissante
      let windowStart = Date.now(); // marque le début de la fenêtre glissante
      let lastValue = null; // Dernière valeur reçue
      let repeatCount = 0;

      this.socket.on("connect", () => {
        this.connexionWebSocket = true;
        this.socket.emit("authentication", {
          username: Parse.User.current().attributes.username,
        });

        this.socket.on("authenticated", function () {});
        this.socket.on("unauthorized", function () {});
        this.socket.on("connect", () => {
          this.isConnected = true; // Mettre à jour l'état lors de la connexion
        });
        this.socket.on("disconnect", () => {
          this.isConnected = false; // Mettre à jour l'état lors de la déconnexion
        });
        this.socket.on("ani", (ani) => {
          console.log("Connection à la socket ani....");
          this.isConnected = true;
          console.log("isConnected:", this.isConnected);
          this.tabAni.push(ani);
          var aniJson = JSON.parse(ani);
          if (aniJson.aniValue > 0) {
            this.CalibrationTime = false;
          }

          if (aniJson.aniValue === lastValue) {
            repeatCount++;
            if (repeatCount === 20) {
              console.error(
                "Erreur : La même valeur a été reçue 20 fois consécutives."
              );
              this.errorSocketAni = true;
            }
          } else {
            lastValue = aniJson.aniValue;
            repeatCount = 1; // Réinitialisez le compteur car une nouvelle valeur a été reçue.
            this.errorSocketAni = false;
          }

          // console.log("ani json reçu:", aniJson);
          if (this.os === "Mac") {
            this.lastBPM = Math.round(60000 / aniJson.RR);
          } else {
            this.lastBPM = aniJson.RR;
          }
          if (this.lastBPM === 0 || this.lastBPM <= 30) {
            console.log(
              "Last BPM est inférieur ou égal à 30. Gardant la valeur précédente."
            );
            this.lastBPM = this.previousBPM;
          } else {
            this.previousBPM = this.lastBPM; // Mettre à jour la valeur précédente
          }

          console.log("LAST BPM:", this.lastBPM);
          // console.log("LastBPM:", lastBPM);
          if (this.lastBPM > 30 && this.lastBPM < 200) {
            this.tabBPM.push(this.lastBPM);
          }
          if (this.timerStarted && aniJson.aniValue <= 150) {
            this.lastAni = aniJson.aniValue;
          }
          this.totalAni += aniJson.aniValue; // mise à jour de la somme totale
          this.numAni++; // mise à jour du compteur
          if (this.timerStarted) {
            this.aniRecords.push({
              x: this.elapsedExerciseTime,
              y: aniJson.aniValue,
            });
            this.averageAniRecords.push({
              x: this.elapsedExerciseTime,
              y: this.MoyenneAni,
            });
          }
          // Ajout de la nouvelle valeur à la fenêtre glissante
          const aniValue = parseFloat(aniJson.aniValue);
          if (!isNaN(aniValue)) {
            // Vérifie que aniValue est un nombre
            windowData.push(aniValue);
            console.log("windowData:", windowData);
          }

          // Suppression des valeurs qui sont hors de la fenêtre glissante d'une minute
          while (Date.now() - windowStart > 60000) {
            windowData.shift();
            windowStart += 1000; // déplace la fenêtre d'une seconde
          }

          // Calcul de la moyenne sur la fenêtre glissante, en gérant le cas où windowData est vide
          if (windowData.length > 0) {
            this.MoyenneAniMinute = Math.round(
              windowData.reduce((a, b) => a + b, 0) / windowData.length
            );
          } else {
            // Gérer le cas d'un tableau vide : attribuer une valeur par défaut ou sauter le calcul
            this.MoyenneAniMinute = 0; // Ou toute autre valeur par défaut appropriée
          }
        });
      });
    },
  },
  created() {
    this.performActionBasedOnOS();
    this.connectSocket();
  },
};
</script>
<style scoped>
.full-screen-container {
  position: relative;
  width: 80vw;
  /* height: 75vh; */
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column; /* ou 'row' selon l'orientation souhaitée pour vos composants */
  align-items: center; /* Centrer le contenu horizontalement */
  background: white;
  border: 4px solid #489181;
}
.connection-status {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: gray; /* Couleur par défaut */
}

.is-connected {
  background-color: green; /* Vert pour connecté */
}

.is-disconnected {
  background-color: red; /* Rouge pour déconnecté */
}
.finish-button {
  display: block; /* Assure que le bouton est bien aligné et facile à cliquer */
  margin: 0 auto; /* Centre le bouton horizontalement */
  padding: 10px 20px; /* Ajoute un padding pour un meilleur aspect et une meilleure sensation au clic */
  font-size: 1rem; /* Taille de police appropriée pour le bouton */
  color: white; /* Couleur de texte pour le bouton */
  background-color: #489181; /* Couleur de fond pour le bouton, peut être ajustée */
  border: none; /* Supprime la bordure par défaut du bouton */
  border-radius: 5px; /* Arrondit les coins pour un look moderne */
  cursor: pointer; /* Change le curseur pour indiquer que le bouton est cliquable */
  transition: background-color 0.3s; /* Transition douce pour l'interaction du bouton */
}

.finish-button:hover {
  background-color: #357f63; /* Assombrit le bouton au survol pour un feedback visuel */
}

.finish-button:disabled {
  background-color: #cccccc;
  color: #666666; /* Gris foncé */
  cursor: not-allowed; /* Change le curseur pour indiquer l'interdiction */
  border: 1px solid #cccccc; /* Optionnel: ajoute une bordure gris pour le bouton désactivé */
}

.error-socket-ani {
  display: inline-block; /* Permet d'appliquer des marges et un padding */
  background-color: #ffdddd; /* Un fond rouge clair pour indiquer une erreur */
  color: #d8000c; /* Une couleur rouge foncé pour le texte, pour le contraste */
  border: 1px solid #d8000c; /* Une bordure rouge foncé pour délimiter le message */
  padding: 10px; /* Un peu d'espace autour du texte pour la lisibilité */
  margin: 10px 0; /* Ajoutez un peu d'espace autour de l'élément pour le distinguer */
  border-radius: 5px; /* Des coins arrondis pour adoucir l'apparence */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Une légère ombre pour un effet de profondeur */
  font-weight: bold; /* Rend le texte plus lourd pour attirer l'attention */
  text-align: center; /* Centre le texte pour une meilleure lisibilité */
}
.arrow {
  position: relative;
  width: 200px; /* Ajustez la largeur selon vos besoins */
  height: 50px; /* Ajustez la hauteur selon vos besoins */
  background-color: #f4f4f4; /* Couleur de fond de la flèche */
  text-align: center; /* Centrer le texte */
  line-height: 50px; /* Ajuster avec la hauteur pour centrer le texte verticalement */
  margin-right: 30px; /* Espace pour la pointe de la flèche */
}

.arrow::after {
  content: "";
  position: absolute;
  right: -30px; /* Ajustez pour changer la position de la pointe */
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 30px; /* Crée la pointe de la flèche */
  border-color: transparent transparent transparent #f4f4f4; /* Ajustez la couleur de la pointe */
}
#heartBpm {
  color: red; /* Couleur du cœur (rouge) */
  font-size: 20px; /* Taille du cœur */
  margin-right: 5px; /* Espace entre le cœur et le nombre de BPM */
}

.InformationDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #333;
  transition: all 0.2s ease-in-out;
  /* margin-bottom:5px; */
}

@media (max-width: 1300px) {
  .InformationDiv {
    margin-bottom: 5px !important;
    margin: 0;
    padding: 0;
    font-size: 10px;
  }
}

.InformationDiv:hover {
  background-color: #f5f5f5;
}

.InformationDiv small {
  margin-left: 5px;
  color: #777;
}
@media (max-width: max-width) {
  .InformationDiv small {
    margin-left: 0;
  }
}

.InformationDiv .value2 {
  font-size: 24px;
  color: #444;
}

.InformationDiv .icon {
  width: 20px;
  height: 20px;
  fill: #777;
}

@media (max-width: 768px) {
  .InformationDiv {
    flex-direction: column;
    align-items: flex-start;
  }

  .InformationDiv .value2 {
    font-size: 18px;
  }

  .InformationDiv .icon {
    display: none;
  }
}

.timer-display {
  font-size: 1.5rem; /* Taille de police accrue pour une meilleure lisibilité */
  color: #333; /* Couleur de texte pour contraste élevé */
  margin-bottom: 10px; /* Espacement pour séparation visuelle */
  font-weight: bold; /* Rend le texte du timer plus marquant */
  text-align: center; /* Centre le texte du timer pour un alignement visuel */
}

.InformationDivParent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-right: 5%;
}

.InformationDivParentTimer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.startButton {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #489181;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
@media (max-width: 1300px) {
  .startButton {
    padding: 5px 10px;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.startButton:hover {
  background-color: #3b6e5e;
}

.startButton:active {
  background-color: #2e5344;
}

.startButton:focus {
  outline: none;
}

.startButton:disabled {
  background-color: #cccccc; /* Gris */
  color: #666666; /* Gris foncé */
  cursor: not-allowed; /* Change le curseur pour indiquer l'interdiction */
  border: 1px solid #cccccc; /* Optionnel: ajoute une bordure gris pour le bouton désactivé */
}
#heartAni {
  color: rgba(255, 159, 64); /* Couleur du cœur (rouge) */
  font-size: 20px; /* Taille du cœur */
  margin-right: 5px; /* Espace entre le cœur et le nombre de BPM */
}
@media (max-width: 1300px) {
  #heartAni {
    font-size: 12px;
  }
  #heartBpm {
    font-size: 12px;
  }
}

.calibration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.loader-calibration-ani {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #489181;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.full-screen-button {
  background-color: #489181; /* Couleur verte */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  position: absolute;
    right: 10px;
}

.full-screen-button i {
  margin-right: 5px;
}

.full-screen-button:hover {
  background-color: #3b6e5e;
}

#sensor-instructions-container {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  width: 80%;
  margin: auto;
}

.sensor-instructions-heading {
  font-weight: bold;
  color: #495057;
  margin-bottom: 10px;
}

.sensor-instructions-list {
  list-style-type: disc;
  margin-left: 20px;
}

.sensor-instructions-list li {
  margin-bottom: 10px;
}
#PlacementMoniteurPasLance {
  margin-top: 50px;
  margin-bottom: 50px;
}


#timer {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: auto;
  width: 70px;
  height: 70px;
  border: 3px solid rgb(35, 147, 128);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s;
  margin-bottom: 10px;
  background-color: white !important;
  z-index: 1;
  margin-top:10px;
}

#timer:hover {
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.15);
}

</style>
