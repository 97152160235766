<template>
  <div class="preferences-page">
    <h1 class="title-bfp">
      Votre thérapeute a souhaité vous soumettre ce questionnaire.
    </h1>
    <div
      v-if="this.QuestionnaireIds[currentQuestionnaireIndex] === '8YKAqC9cB0'"
    >
      <div class="explication-bfp">
        <span
          >Prenez trois minutes pour vous donner une note sur chacune de ces
          trois échelles. Regardez avec attention le texte associé à votre
          chiffre (allant de 1 à 10) afin de choisir celui qui correspond le
          mieux à votre ressenti immédiat. Reportez vos trois scores dans la
          partie résultat.</span
        >
      </div>
      <div class="bfp-div">
        <bfpComponent
          :idPatientTherapeute="idPatientTherapeute"
          :isForBeginPage="true"
          @EnableButton="handleEnableButton"
        ></bfpComponent>
      </div>
      <div class="info-message" v-if="!enableButton">
        <i class="info-icon">ℹ️</i>
        <span
          >Veuillez terminer le questionnaire avant de pouvoir continuer.</span
        >
      </div>
      <div class="info-message-finish" v-else-if="enableButton">
        <div class="info-icon-container-finish">
          <i class="info-icon-finish">ℹ️</i>
        </div>
        <div class="info-text-finish">
          <p>Félicitations, vous avez terminé !</p>
          <p>
            Vous pouvez désormais accéder à l'espace d'activité avec les accès
            attribués par votre thérapeute.
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <edasComponent
        :idPatientTherapeute="idPatientTherapeute"
        :isForBeginPage="true"
        @EnableButton="handleEnableButton"
      />
    </div>
    <button
      class="continue-button"
      :class="{ 'disabled-button': !enableButton }"
      @click="nextQuestionnaire"
      :disabled="!enableButton"
    >
      {{
        currentQuestionnaireIndex < QuestionnaireIds.length - 1
          ? "Prochain Questionnaire"
          : "Continuer"
      }}
    </button>
  </div>
</template>
<script>
import bfpComponent from "../bfp/bfpComponent.vue";
import Parse from "parse";
import edasComponent from "../edas/edasComponent.vue";

export default {
  name: "preferencesPage",
  data() {
    return {
      enableButton: false,
      QuestionnaireIds: [],
      currentQuestionnaireIndex: 0,
    };
  },
  components: {
    bfpComponent,
    edasComponent,
  },
  props: {
    idPatientTherapeute: {
      type: String,
      default: "",
    },
  },
  created() {
    console.log("Recup bilan flash");
    this.recupBilanFlash(Parse.User.current().id);
  },
  methods: {
    handleEnableButton() {
      this.enableButton = true;
    },
    nextQuestionnaire() {
      if (this.currentQuestionnaireIndex < this.QuestionnaireIds.length - 1) {
        this.currentQuestionnaireIndex++;
        this.enableButton = false; // Réinitialiser le bouton pour le prochain questionnaire
      }
      else {
        location.reload();
      }
    },
    recupBilanFlash(idUser) {
      var userQuery = new Parse.Query(Parse.User);
      userQuery.equalTo("objectId", idUser);
      userQuery.include("QuestionnaireBeginIdList");
      userQuery.find().then((results) => {
        if (results[0].attributes.QuestionnaireBeginIdList) {
          var relation = results[0].relation("QuestionnaireBeginIdList");
          relation
            .query()
            .find()
            .then((results) => {
              this.QuestionnaireIds = results.map(
                (questionnaire) => questionnaire.id
              );
              if (this.QuestionnaireIds.length > 0) {
                this.QuestionnaireId = this.QuestionnaireIds[0]; // Commence avec le premier questionnaire
              }
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.continue-button {
  background-color: #239380;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #239380;
  }
}
.title-bfp {
  font-size: 20px;
  margin-bottom: 20px;
}

.disabled-button {
  opacity: 0.5;
  pointer-events: none;
  background: #ccc;
  color: #000;
}
/* Style de base pour .info-message */
.info-message {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #555;
  background-color: white; /* Ajout d'une couleur de fond */
  border: 1px solid #ccc; /* Ajout d'une bordure */
  border-radius: 5px; /* Ajout de coins arrondis */
  padding: 10px; /* Ajout d'un espacement intérieur */
  font-size: 16px; /* Taille de police augmentée */
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

/* Style pour .info-icon */
.info-icon {
  margin-right: 10px; /* Augmentation de la marge pour un espacement visuel accru */
  font-size: 20px; /* Taille de police de l'icône augmentée */
  margin-right: 10px;
  font-size: 20px;
  border: 1px solid black;
  border-radius: 50%;
  /* padding: 10px; */
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  background: #239380;
}

/* Style pour .info-message */
.info-message-finish {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px; /* Espacement supplémentaire en bas */
  background-color: #e5f0e5; /* Couleur de fond agréable */
  border: 1px solid #7bc67b; /* Bordure légère */
  border-radius: 10px; /* Coins arrondis */
  padding: 20px; /* Espacement intérieur */
  color: #007f00; /* Couleur du texte agréable */
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

/* Style pour .info-icon-container */
.info-icon-container-finish {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7bc67b; /* Couleur de fond du cercle de l'icône */
  border-radius: 50%; /* Création d'un cercle autour de l'icône */
  width: 50px; /* Largeur fixe pour le cercle de l'icône */
  height: 50px; /* Hauteur fixe pour le cercle de l'icône */
  margin-right: 20px; /* Espacement entre l'icône et le texte */
}

/* Style pour .info-icon */
.info-icon-finish {
  font-size: 24px; /* Taille de police de l'icône augmentée */
  color: #fff; /* Couleur de l'icône */
}

/* Style pour .info-text */
.info-text-finish p {
  margin: 0; /* Supprime les marges par défaut des paragraphes */
}

.explication-bfp {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
</style>
