import * as types from "./user-mutation-type";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import router from "@/router";
import generateUserGroup from "@/services/userGroup/generateUserGroup";
import { getFoldersByType } from "@/services/folders/getFoldersByType.js";
import getMyHeadings from "@/services/headings/getMyHeadings.js";
import getAccesWithAbonnement from "@/services/abonnement/getAccesWithAbonnement.js";
import moment from "moment";
// import WebSocketService from "@/services/socket/WebSocketService.js";
function initialState() {
  return {
    user: null,
    error: null,
    abonnement: {
      type: null,
      endDate: null,
    },
    group: null,
    firstConnection: true,
    abonnementTemp: {},
    sauvegardeUtilisateur: false,
    folders: null,
    modalTablette: false,
    cgu: false,
    headings: null,
  };
}
const state = initialState();

const namespaced = true;

const mutations = {
  [types.EDIT_USER](state, user) {
    state.user = user;
  },
  [types.EDIT_ERROR](state, error) {
    state.error = error;
  },
  [types.EDIT_ABONNEMENT](state, abonnement) {
    state.abonnement = abonnement;
  },
  [types.EDIT_USER_GROUP](state, group) {
    state.group = group;
  },
  [types.EDIT_FIRST_CONNECTION](state, firstConnection) {
    state.firstConnection = firstConnection;
  },
  [types.EDIT_ABONNEMENT_TEMP](state, abonnementTemp) {
    state.abonnementTemp = abonnementTemp;
  },
  [types.EDIT_HEADINGS](state, headings) {
    state.headings = headings;
  },
  [types.EDIT_SAUVEGARDE_UTILISATEUR](state, result) {
    state.sauvegardeUtilisateur = result;
  },
  [types.EDIT_FOLDERS](state, folders) {
    //récupérer seulement les 5 premiers folders
    state.folders = folders;
  },
  [types.EDIT_MODAL_TABLETTE](state, modalTablette) {
    state.modalTablette = modalTablette;
  },
  [types.EDIT_CGU](state, cgu) {
    state.cgu = cgu;
  },
  [types.EDIT_PAYANT](state, payant) {
    state.payant = payant;
  },
};

const actions = {
  async logIn({ getters, commit, dispatch }, data) {
    commit(types.EDIT_MODAL_TABLETTE, false);
    commit(types.EDIT_ERROR, null);
    ParseConfig();
    if (await Parse.User.current()) {
      Parse.User.logOut();
    }
    try {
      const QueryUser = new Parse.Query(Parse.User);
      QueryUser.equalTo("username", data.email);
      const ResponseUser = await QueryUser.first();
      console.log("User:0, ", ResponseUser);
      const ObjectUserGroup = Parse.Object.extend("user_group");
      const QueryUserGroup = new Parse.Query(ObjectUserGroup);
      QueryUserGroup.equalTo("objectId", ResponseUser.attributes.group.id);
      const ResponseUserGroup = await QueryUserGroup.first();
      var dureeEngagement =
        ResponseUserGroup.attributes.engagement_annuel !== undefined &&
        ResponseUserGroup.attributes.engagement_annuel
          ? "years"
          : "months";
      console.log("étape numéro 1");
      if (
        ResponseUserGroup.attributes.resilie !== undefined &&
        ResponseUserGroup.attributes.resilie &&
        ResponseUserGroup.attributes.date_paiement !== undefined &&
        moment(ResponseUserGroup.attributes.date_paiement).add(
          1,
          dureeEngagement
        ) <= moment()
      ) {
        //Mettre le user en gratuit
        ResponseUserGroup.set("type_abonnement", "gratuit");
        ResponseUserGroup.save();
        // Parse.Cloud.run("resetLinkUserForGratuit", { user: ResponseUser.id });
      }
      const QueryRole = new Parse.Query(Parse.Role);
      QueryRole.equalTo("users", ResponseUser);
      const ResponseRole = await QueryRole.first();
      console.log(
        "étape numéro 2",
        ResponseUserGroup,
        ResponseUserGroup.attributes.compte_valide,
        ResponseRole
      );

      if (
        (ResponseRole && ResponseRole.get("name") === "Administrators") ||
        ResponseUserGroup.attributes.compte_valide
      ) {
        if (
          ResponseUserGroup.attributes.resilie === true &&
          ResponseUser.attributes.therapeute === false && ResponseUser.attributes.fin_acces_plateforme > new Date()
        ) {
          commit(
            types.EDIT_ERROR,
            "L'abonnement de votre thérapeute est résilié. Vos accès sont suspendus."
          );
          return;
        }
        if (ResponseUser.attributes.compte_valide === false) {
          commit(
            types.EDIT_ERROR,
            "Votre compte n'est pas encore validé. Veuillez vérifier votre boîte mail."
          );
          return;
        }
        if (ResponseUser.attributes.type_pratique === "cabinet") {
          commit(
            types.EDIT_ERROR,
            "Votre accès à distance est terminé. Veuillez contacter votre thérapeute."
          );
          return;
        }
        await Parse.User.logIn(data.email, data.password);
        console.log("étape numéro 3");
        var userCourant = new Parse.Object.extend("_User");

        userCourant = await Parse.User.current();
        commit(types.EDIT_USER, userCourant);
        commit(types.EDIT_CGU, userCourant.attributes.cgu);
        commit(
          types.EDIT_PAYANT,
          userCourant.attributes.group.attributes.type_abonnement === "gratuit"
            ? false
            : true
        );
        commit(types.EDIT_ERROR, null);
        var sessionObject = Parse.Object.extend("session_detail");
        var sessionQuery = new Parse.Query(sessionObject);
        sessionQuery.equalTo("user", userCourant);
        var sessionFind = await sessionQuery.find();

        sessionFind.forEach((session) => {
          session.destroy();
        });

        console.log("étape numéro 3");

        // var sessionObject = Parse.Object.extend("session_detail");
        var newSession = new sessionObject();
        newSession.set("user", userCourant);
        newSession.set("group", userCourant.attributes.group);
        const uuid = require("uuid");
        const uniqueToken = uuid.v4();
        newSession.set("token", uniqueToken);
        await newSession.save();
        await dispatch("updateUserGroup");

        console.log("étape numéro 4");

        // enregister le token dans le local storage
        localStorage.setItem("token", uniqueToken);
        // enregister la date de connexion dans le local storage
        localStorage.setItem("dateConnexion", Date.now().toString());
        // deleteMyHeadings();
        const user_headings = await getMyHeadings();

        var headingsdisableByDefault = [
          "actualites",
          "RetrocessionEtRoyaltiesTitre",
        ];

        var dt = new Date();

        if (
          dt.getTime() >
          ResponseUserGroup.attributes.subscription_end_date.getTime()
        ) {
          ResponseUserGroup.set("type_abonnement", "gratuit");
          await ResponseUserGroup.save();
        }
        console.log("étape numéro 5");

        try {
          // console.log("user_headings", user_headings);

          // Boucle qui affiche chaque name
          for (let i = 0; i < user_headings.length; i++) {
            const element = user_headings[i];
            if (element.attributes.heading) {
              // console.log("Nom de l'heading :", element.attributes.heading.attributes.name);
            }
          }

          //vérifier si l'heading 'Messagerie' est présent dans les headings de l'utilisateur
          // var isHeadingPresent = user_headings.find(
          //   (el) => el.attributes.heading && el.attributes.heading.attributes.name == "Messagerie"
          //   );
          // if (!isHeadingPresent) {
          //     console.log("L'heading 'Messagerie' n'est pas présent dans les headings de l'utilisateur");
          //     var headingObject = Parse.Object.extend("headings");
          //     var headingQuery = new Parse.Query(headingObject);
          //     headingQuery.equalTo("name", "Messagerie");
          //     var headingFind = await headingQuery.first();
          //     console.log("headingFind", headingFind);
          //     var UserHeadings = Parse.Object.extend("user_headings");
          //     var newUserHeadings = new UserHeadings();
          //     newUserHeadings.set("user", userCourant);
          //     newUserHeadings.set("isActive", true);
          //     newUserHeadings.set("heading", headingFind);
          //     await newUserHeadings.save();
          // }
          // var isHeadingPresent = user_headings.find(
          //   (el) => el.attributes.heading && el.attributes.heading.attributes.name == "monAni"
          //   );
          // if (!isHeadingPresent) {
          //     console.log("L'heading 'monAni' n'est pas présent dans les headings de l'utilisateur");
          //     var headingObject = Parse.Object.extend("headings");
          //     var headingQuery = new Parse.Query(headingObject);
          //     headingQuery.equalTo("name", "monAni");
          //     var headingFind = await headingQuery.first();
          //     console.log("headingFind", headingFind);
          //     var UserHeadings = Parse.Object.extend("user_headings");
          //     var newUserHeadings = new UserHeadings();
          //     newUserHeadings.set("user", userCourant);
          //     newUserHeadings.set("isActive", true);
          //     newUserHeadings.set("heading", headingFind);
          //     await newUserHeadings.save();
          // }
          // var isHeadingPresent = user_headings.find(
          //   (el) => el.attributes.heading && el.attributes.heading.attributes.name == "mesVisios"
          //   );
          // if (!isHeadingPresent) {
          //     console.log("L'heading 'mesVisios' n'est pas présent dans les headings de l'utilisateur");
          //     var headingObject = Parse.Object.extend("headings");
          //     var headingQuery = new Parse.Query(headingObject);
          //     headingQuery.equalTo("name", "mesVisios");
          //     var headingFind = await headingQuery.first();
          //     console.log("headingFind", headingFind);
          //     var UserHeadings = Parse.Object.extend("user_headings");
          //     var newUserHeadings = new UserHeadings();
          //     newUserHeadings.set("user", userCourant);
          //     newUserHeadings.set("isActive", true);
          //     newUserHeadings.set("heading", headingFind);
          //     await newUserHeadings.save();
          // }
        } catch (error) {
          console.error(
            "Une erreur s'est produite lors de la recherche de 'Messagerie' dans les headings :",
            error
          );
        }
        console.log("étape numéro 6", userCourant);
        console.log(user_headings);
        //Code temporaire
        if (user_headings.length == 0) {
          user_headings.forEach((el) => {
            el;
          });

          const QueryHeadings = new Parse.Query(
            Parse.Object.extend("headings")
          );
          QueryHeadings.limit(1000);
          const ResponseHeadings = await QueryHeadings.find();

          ResponseHeadings.forEach(async function (heading) {
            const UserHeadings = Parse.Object.extend("user_headings");
            const newUserHeadings = new UserHeadings();
            newUserHeadings.set("user", userCourant);
            if (
              headingsdisableByDefault.find(
                (head) => head == heading.attributes.name
              ) == undefined &&
              getAccesWithAbonnement(heading.attributes.type_abonnement) <=
                getAccesWithAbonnement(
                  ResponseUserGroup.attributes.type_abonnement
                )
            ) {
              newUserHeadings.set("isActive", true);
            } else {
              newUserHeadings.set("isActive", false);
            }
            newUserHeadings.set("heading", heading);
            newUserHeadings.save();
          });
        }
        // Fin code temporaire
        // console.log('Fin de la connexion  1...')
        //si c'est un thérapeute
        if (ResponseUser.attributes.therapeute) {
          try {
            await dispatch("editHeadings", { payload: await getMyHeadings() });
          } catch (error) {
            console.error(
              "Erreur lors de la récupération des headings :",
              error
            );
          }
        }
        console.log("étape numéro 7");

        // console.log('Fin de la connexion  2...')
        var mesFolders = await getFoldersByType(
          "Brique",
          userCourant,
          getters.group.type_abonnement
        );
        // console.log('Fin de la connexion  3...')
        console.log("étape numéro 8", mesFolders);
        try {
          commit(types.EDIT_FOLDERS, mesFolders);
        } catch (error) {
          console.error("Erreur lors de la récupération des folders :", error);
        }
        console.log("étape numéro 9");
        try {
          var userId = Parse.User.current().id;

          Parse.Cloud.run("updateLastConnection", { id: userId })
            .then(function (result) {
              console.log(
                "Date de dernière connexion mise à jour avec succès :",
                result
              );
            })
            .catch(function (error) {
              console.error(
                "Erreur lors de la mise à jour de la dernière connexion :",
                error
              );
            });
        } catch (error) {
          console.error(
            "Erreur lors de la mise à jour de la dernière connexion :",
            error
          );
        }
        // try {
        //   const webSocketService = new WebSocketService();

        //   // Pour se connecter au WebSocket
        //   webSocketService.connectToWebSocket();

        //   // Pour envoyer l'ID de l'utilisateur
        //   const userId = Parse.User.current().id;
        //   webSocketService.sendUserId(userId);
        // } catch (error) {
        //   console.error("Erreur lors de la connexion au WebSocket :", error);
        // }

        location.reload();

        // if (data.avecRedirectionUser) router.push("/utilisateurs");
        // else router.go("/accueil");
      } else {
        Parse.User.logOut();
        commit(types.EDIT_USER, null);
        commit(
          types.EDIT_ERROR,
          "Connexion non authorisée. veuillez d'abord valider l'inscription depuis le mail que vous avez reçu."
        );
      }
    } catch (error) {
      if (error.message === "Invalid username/password.") {
        commit(types.EDIT_ERROR, "Email ou mot de passe incorrect.");
      } else if (error.message === "Username must be a string.") {
        commit(types.EDIT_ERROR, "Veuillez saisir votre email.");
      } else if (error.message === "Password must be a string.") {
        commit(types.EDIT_ERROR, "Veuillez saisir votre mot de passe.");
      }
    }
  },
  logOut({ commit }) {
    ParseConfig();
    Parse.User.logOut();
    router.go("/connexion"); // deconnexion de l'application
    commit(types.EDIT_USER, null);
    commit(types.EDIT_FIRST_CONNECTION, false);
    location.reload();
  },
  isNotFirstConnection({ commit }) {
    commit(types.EDIT_FIRST_CONNECTION, false);
  },
  async updateUserGroup({ getters, commit, dispatch }) {
    ParseConfig();
    const UserGroup = Parse.Object.extend("user_group");
    const query = new Parse.Query(UserGroup);
    if (getters.user.group && getters.user.group.objectId) {
      query.equalTo("objectId", getters.user.group.objectId);
    } else if (getters.user.attributes && getters.user.attributes.group.id) {
      query.equalTo("objectId", getters.user.attributes.group.id);
    }
    const group = await query.first();
    const newGroup = generateUserGroup(group);
    commit(types.EDIT_USER_GROUP, newGroup);
    dispatch("gestionAbonnement");
  },
  gestionAbonnement({ getters, commit }) {
    const abonnement = {
      type: null,
      endDate: null,
    };
    abonnement.type = getters.group.type_abonnement;
    abonnement.endDate = getters.group.subscription_end_date;
    commit(types.EDIT_ABONNEMENT, abonnement);
    commit(types.EDIT_FIRST_CONNECTION, true);
  },
  editAbonnementTemp({ commit }, payload) {
    let abonnementTemp = payload.abonnement;
    abonnementTemp.amount = payload.transactionData.amount;
    abonnementTemp.reference = payload.transactionData.reference;
    commit(types.EDIT_ABONNEMENT_TEMP, payload.abonnement);
  },
  editHeadings: function ({ commit }, payload) {
    commit(types.EDIT_HEADINGS, payload.payload);
  },
  async updateUser({ commit }) {
    commit(types.EDIT_SAUVEGARDE_UTILISATEUR, true);
    commit(types.EDIT_USER, await Parse.User.current());
  },
  updateSauvegardeUtilisateur({ commit }) {
    commit(types.EDIT_SAUVEGARDE_UTILISATEUR, false);
  },
  editModalTablette({ commit }) {
    commit(types.EDIT_MODAL_TABLETTE, true);
  },
  async loadFolder({ commit, getters }) {
    var mesFolders = await getFoldersByType(
      "Brique",
      getters.user,
      getters.group.type_abonnement
    );
    commit(types.EDIT_FOLDERS, mesFolders);
  },
  updateCgu({ commit }) {
    commit(types.EDIT_CGU, true);
  },
};

const getters = {
  user: (state) => {
    return state.user;
  },
  error: (state) => {
    return state.error;
  },
  abonnement: (state) => {
    return state.abonnement;
  },
  group: (state) => {
    return state.group;
  },
  firstConnection: (state) => {
    return state.firstConnection;
  },
  abonnementTemp: (state) => {
    return state.abonnementTemp;
  },
  headings: (state) => {
    return state.headings;
  },
  sauvegardeUtilisateur: (state) => {
    return state.sauvegardeUtilisateur;
  },
  folders: (state) => {
    return state.folders;
  },
  modalTablette: (state) => {
    return state.modalTablette;
  },
  cgu: (state) => {
    return state.cgu;
  },
  payant: (state) => {
    return state.payant;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
