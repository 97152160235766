<template>
  <div class="flex-container">
    <div class="main marginMain">
      <HeaderTest
        :key="keyHeader"
        class="IndexNavbar"
        @blocName="blocName"
        :UserSelectedByTherapeute="user"
      ></HeaderTest>

      <listProtocol
        v-on:pop-up-contact="popUpContactEmitted"
        :ParamJeuGuidee="ParamJeuGuidee"
        :SeanceSelected="SeanceSelected"
        class="container-protocol"
        :idPatientTherapeute="objectIdPatient"
        v-on:is-display-programmes="onIsDisplayProgrammes"
        v-if="blocSelected == 'programmes' && isProgrammesDisplayed"
      ></listProtocol>
      <listRessources
        :ParamJeu="ParamJeu"
        class="container-protocol"
        :idPatientTherapeute="objectIdPatient"
        v-if="blocSelected == 'programmes' && isDisplayRessources"
      ></listRessources>
      <listEchelles
        v-on:pop-up-contact="popUpContactEmitted"
        class="container-protocol"
        :idPatientTherapeute="objectIdPatient"
        v-if="blocSelected == 'programmes' && isDisplayEchelles"
      ></listEchelles>
      <jitsiMeetVue v-if="blocSelected === 'visio'"></jitsiMeetVue>
      <profil
        @displayHeader="handleDisplayHeader"
        v-if="blocSelected == 'profil'"
      ></profil>
      <beginPage
        @continue="handleContinue"
        v-if="blocSelected == 'beginPage'"
      ></beginPage>
      <beginPage2
        @continue="handleContinue2"
        v-if="blocSelected == 'beginPage2'"
      ></beginPage2>
      <beginPage3
        :idPatientTherapeute="objectIdPatient"
        @continue="handleContinue3"
        v-if="blocSelected == 'beginPage3'"
      ></beginPage3>
      <!-- {{ blocSelected }} -->
    </div>
    <ContactIcon :idUserMsg="ParamMsg" ref="childRef"></ContactIcon>
    <!-- <MessagerieBottom></MessagerieBottom> -->
    <!-- <VisioBottom></VisioBottom> -->
    <MajNotification
      v-if="maj.length > 0 && showNotification"
      :maj="maj"
    ></MajNotification>
    <FooterMailBienEtreVue v-if="showModalBienEtre"></FooterMailBienEtreVue>
    <FooterMailTechnique v-if="showModalTechnique"></FooterMailTechnique>
    <FooterA
      class="sticky-footer"
      @openModal="handleOpenModal"
      @openModalTechnique="handleOpenModalTechnique"
    ></FooterA>
  </div>
</template>
<script>
import HeaderTest from "@/components/accueilV2/Header2.vue";
// import FooterA from "@/components/FooterA.vue";
import FooterA from "@/components/accueilV2/Footer2.vue";
import profil from "@/components/profil.vue";
import listProtocol from "@/components/SymbioCenter_Activites/ListProtocolNew.vue";
import listRessources from "@/components/SymbioCenter_Activites/ListRessourcesNew.vue";
import listEchelles from "@/components/SymbioCenter_Activites/ListEchellesNew.vue";
// import MessagerieBottom from "@/components/chat/MessagerieBottom.vue";
// import VisioBottom from "@/components/visio/VisioBottom.vue";
// import doughnut from "@/components/doughnut.vue";
// import VideoChat from "@/components/visio/VideoChat.vue";
import { getUserById } from "@/services/utilisateurs/getUserById";
import jitsiMeetVue from "@/components/visio/meetingComponent.vue";
// import testAppelBluetoothVue from "../components/testAppelBluetooth.vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import { verificationToken } from "@/services/token/verificationToken.js";
import MajNotification from "@/components/notification/MajNotification.vue";
import FooterMailBienEtreVue from "@/components/accueilV2/FooterMailBienEtre.vue";
import FooterMailTechnique from "@/components/accueilV2/FooterMailTechnique.vue";
import ContactIcon from "@/components/contact/contactIcon.vue";
import WebSocketService from "@/services/socket/WebSocketService.js";

import beginPage from "@/components/accueilV2/beginPage.vue";
import beginPage2 from "@/components/accueilV2/beginPage2.vue";
import beginPage3 from "@/components/accueilV2/beginPage3.vue";

// import router
import router from "@/router";
export default {
  name: "Accueil",
  components: {
    FooterA,
    listEchelles,
    profil,
    listProtocol,
    listRessources,
    jitsiMeetVue,
    // MessagerieBottom,
    HeaderTest,
    MajNotification,
    // VisioBottom,
    FooterMailBienEtreVue,
    FooterMailTechnique,
    beginPage,
    beginPage2,
    beginPage3,
    ContactIcon,
  },
  data() {
    return {
      UserSelectedByTherapeute: "",
      blocSelected: "programmes",
      element_user_local: [],
      objectIdPatient: "",
      isProgrammesDisplayed: true,
      isDisplayRessources: true,
      isDisplayEchelles: true,
      foldersBrique: [],
      user: null,
      bgColor: null,
      bgImage: null,
      typeT: null,
      linkedObjectPatient: null,
      Cgu: null,
      lastExercice: null,
      keyProfile: 0,
      keyHeader: 0,
      maj: [],
      showNotification: true,
      showModalTechnique: false,
      showModalBienEtre: false,
      ParamJeu: null,
      ParamJeuGuidee: null,
      SeanceSelected: null,
      showPopUpContact: false,
      ParamMsg: null,
    };
  },
  computed: {
    isTherapeute() {
      try {
        return !!Parse.User.current().attributes.therapeute;
      } catch (error) {
        console.error("Erreur lors de la vérification de therapeute:", error);
        return false;
      }
    },
  },
  async created() {
    ParseConfig();
    try {
      this.recupParamJeu();
      this.recupParamJeuGuidee();
      this.recupParamMsg();
    } catch (error) {
      console.log(error);
    }
    try {
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      this.objectIdPatient = urlParams.get("objectId");
    } catch (error) {
      console.log(error);
    }
    if (localStorage.getItem("idUserForSave")) {
      this.user = await getUserById(localStorage.getItem("idUserForSave"));
      // console.log("Utilisateur connecté:", this.user);
    }
    try {
      var user = Parse.User.current();
      if (user.attributes.cgu !== true) {
        this.blocSelected = "beginPage";
      }
    } catch (error) {
      //console.log(error)
    }

    try {
      this.verifBfp();
    } catch (error) {
      console.log(error);
    }
    this.getLatestMaj();
  },
  watch: {
    imageTexture() {
      if (this.imageTexture) {
        this.isCardAjoutTexture = true;
      }
    },
    async verifToken() {
      var token = localStorage.getItem("token");
      var loginTimestamp = parseInt(localStorage.getItem("dateConnexion"), 10);
      var result = await verificationToken(token);
      if (Date.now() - loginTimestamp > 12 * 60 * 60 * 1000) {
        // si le token est trop vieux, on le supprime
        localStorage.removeItem("token");
        localStorage.removeItem("idUserForSave");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
      if (result == false) {
        //si le token est mauvais, on le supprime
        localStorage.removeItem("token");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
    },
    imageBackground() {
      if (this.imageBackground) {
        this.isCardAjoutBackground = true;
      }
    },

    user: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  async mounted() {
    ParseConfig();
    // this.callSocketOnline();
  },
  methods: {
    callSocketOnline() {
      try {
        const webSocketService = new WebSocketService();
        // Pour se connecter au WebSocket
        webSocketService.connectToWebSocket();
        // Pour envoyer l'ID de l'utilisateur
        const userId = Parse.User.current().id;
        webSocketService.sendUserId(userId);
      } catch (error) {
        console.error("Erreur lors de la connexion au WebSocket :", error);
      }
    },
    handleDisplayProfile() {},
    handleDisplayHeader() {
      this.keyHeader++;
    },
    handleClosePopUpContact() {
      this.showPopUpContact = false;
    },
    handleDisplayContactPopUp() {
      this.showPopUpContact = !this.showPopUpContact;
    },
    async verifBfp() {
      // console.log("VérificationBFP", console.log(this.objectIdPatient),localStorage.getItem("idUserForSave"));
      var userId =
        this.objectIdPatient || localStorage.getItem("idUserForSave");
      if (userId) {
        console.log("USER ID :", userId);
        this.blocSelected = await this.checkUser(userId);
        console.log("blocSelected", this.blocSelected);
      }
    },
    async checkUser(userId) {
      try {
        var user = await getUserById(userId);
        console.log('User Check User', user);
        if (user.QuestionnaireBegin) {
          // let results = await Parse.Cloud.run("getUserFilesDone", {
          //   userId: userId,
          // });
          let results = this.findFilesDone(user);
          console.log("Fichiers trouvés :", results);
          return "programmes";
        } else {
          console.log("Questionnaire Begin No");
          return "programmes";
        }
      } catch (error) {
        console.error(
          "Erreur lors de la vérification de l'utilisateur :",
          error
        );
        return "beginPage3";
      }
    },
    findFilesDone(user) {
      let results = [];
      var UserQuery= Parse.Object.extend("User");
      var query = new Parse.Query(UserQuery);
      query.equalTo("objectId", user.id);
      var FileDoneQuery = Parse.Object.extend("file_done");
      var queryFileDone = new Parse.Query(FileDoneQuery);
      queryFileDone.matchesQuery("user", query);
      queryFileDone.equalTo("bfp", true);
      results = queryFileDone.find();
      return results;
    },
    handleContinue() {
      this.blocSelected = "beginPage2";
    },
    recupParamMsg() {
      // console.log("recupParamJeu");
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      this.ParamMsg = urlParams.get("idUserMsg");
      console.log("ParamMsg", this.ParamMsg);
    },
    recupParamJeu() {
      // console.log("recupParamJeu");
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      this.ParamJeu = urlParams.get("jeu");
      // console.log("ParamJeu", this.ParamJeu);
    },
    recupParamJeuGuidee() {
      // console.log("recupParamJeuGuidee");
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      // console.log("urlParams", urlParams);
      this.ParamJeuGuidee = urlParams.get("jeu_guidee");
      // console.log("ParamJeu", this.ParamJeuGuidee);
      this.SeanceSelected = urlParams.get("SeanceSelected");
      // console.log("SeanceSelected", this.SeanceSelected);
    },
    handleContinue2() {
      if (Parse.User.current().attributes.QuestionnaireBegin) {
        this.blocSelected = "beginPage3";
      } else {
        this.blocSelected = "programmes";
      }
    },
    popUpContactEmitted() {
      console.log("popUpContactEmitted");
      this.$refs.childRef.childMethod();
    },
    handleContinue3() {
      this.blocSelected = "programmes";
    },
    blocName(e) {
      this.blocSelected = e;
    },
    handleOpenModal() {
      this.showModalBienEtre = true;
    },
    handleOpenModalTechnique() {
      this.showModalTechnique = true;
    },
    onIsDisplayProgrammes(value) {
      this.isProgrammesDisplayed = value;
    },

    async checkIfAcknowledged() {
      const MajStatut = Parse.Object.extend("maj_user_statuts");
      const query = new Parse.Query(MajStatut);
      query.equalTo("user", Parse.User.current());
      query.equalTo("maj", this.maj[0]);

      try {
        const results = await query.first();
        if (results) {
          this.showNotification = false;
        }
      } catch (error) {
        console.log(
          "Erreur lors de la vérification du statut de la mise à jour:",
          error
        );
      }
    },
    async getLatestMaj() {
      ParseConfig();
      const Maj = Parse.Object.extend("maj");
      const query = new Parse.Query(Maj);
      query.descending("createdAt");
      query.limit(1);

      try {
        this.maj = await query.find();
        this.checkIfAcknowledged();
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des mises à jour:",
          error
        );
      }
    },
    getFoldersByTheme(monTheme) {
      return this.foldersBrique.filter((el) => {
        if (el.themes.length < 2) {
          return el.themes[0].objectId === monTheme;
        }
        return (
          el.themes[0].objectId === monTheme ||
          el.themes[1].objectId === monTheme
        );
      });
    },
    getFoldersRessourcesForHeader() {
      var monTheme = "aQzA2siUUB";
      var folderBrique = this.getFoldersByTheme(monTheme);
      let isProgrammesDisplayed = false;
      for (let i = 0; i < folderBrique.length; i++) {
        if (
          folderBrique[i].deverrouille &&
          folderBrique[i].deverrouille_user &&
          folderBrique[i].protocoles[3] &&
          folderBrique[i].protocoles[3].deverrouille
        ) {
          if (!isProgrammesDisplayed) {
            this.isDisplayRessources = true;
            isProgrammesDisplayed = true;
          }
        }
      }

      if (!isProgrammesDisplayed) {
        this.isDisplayRessources = false;
      }
    },
  },
};
</script>

<style>
html {
  max-height: 100% !important;
  max-width: 100% !important;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica Neue, sans-serif;
  background-color: #f3f3f3;
}
.container {
  min-height: 60vh;
  margin-bottom: -50px;
  padding-bottom: 50px;
}
@media (min-width: 1800px) {
  .container {
    min-height: 70vh;
  }
}
.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

/* Styles du footer */
.sticky-footer {
  flex-shrink: 0;
}

.IndexNavbar {
  z-index: 1000;
}

.marginMain {
  margin-bottom: 40px;
  min-height: 100vh;
}

.container-protocol {
  margin-bottom: 15px;
}
</style>
