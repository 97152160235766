<template lang="html">
    <div
    >
      <div class="is-fullheight accueil">
        <div>
          <div class="overflow2">
            <folders-component
              :idPatientTherapeute="idPatientTherapeute"
              :ParamJeu="ParamJeu"
              :foldersBrique="foldersBrique"
              :themes="themes"
              :group="group"
              @unlockProtocol="unlockProtocol"
              @goAbonnement="goAbonnement"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ParseConfig from "@/api/parse.config.js";
  import Parse from "parse";
  import { Chrome } from "vue-color/";
  import { createNamespacedHelpers } from "vuex";
  import cloneDeep from "lodash/cloneDeep";
  import foldersComponent from "../RessourcesFolders2.vue";
  import { unlockFolder } from "@/services/folders/unlockFolder.js";
  import { URL_FRONT } from "@/configuration/urlConf.js";
  const User = createNamespacedHelpers("user");
  
  export default {
    name: "mes-activites",
    components: {
      "chrome-picker": Chrome,
      FoldersComponent: foldersComponent,
    },
    props: {
      idPatientTherapeute: {
        type: String,
        default: "",
      },
      ParamJeu: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        color: { hex: "#4579B2" },
        editorOption: {
          placeholder: "",
          theme: "snow",
        },
        urlFront: URL_FRONT,
        userGroup: {},
        error: false,
        foldersBrique: [],
        themes: [],
        foldersProtocolLoad: false,
        hasImage: false,
        loaderFolderActive: false,
        isStepFolder: false,
      };
    },
    computed: {
      ...User.mapGetters(["group", "user", "folders"]),
    },
    methods: {
      ...User.mapActions({
        actionsUpdateUserGroup: "updateUserGroup",
        actionsLogOut: "logOut",
        actionsLoadFolder: "loadFolder",
      }),
      async goAbonnement() {
        this.$router.push("abonnement");
      },
      async goToActivites() {
        await this.actionsLogOut();
        window.location.href = this.urlFront;
      },
      async unlockProtocol(props) {
        await unlockFolder(props, this.group.type_abonnement, true);
      },
    },
    async mounted() {
      this.bgColor = this.group.couleur;
      this.loaderFolderActive = true;
      if (this.folders === null) await this.actionsLoadFolder();
  
      this.foldersBrique = cloneDeep(
        this.folders.filter((e) => e.domaine == "Santé")
      );
  
      const oDomaine = Parse.Object.extend("domaine");
      const queryDomaine = new Parse.Query(oDomaine);
      queryDomaine.equalTo("nom", "Santé");
      const domaineSante = await queryDomaine.first();
      const oTheme = Parse.Object.extend("theme");
      const queryTheme = new Parse.Query(oTheme);
      queryTheme.equalTo("domaine", domaineSante);
      queryTheme.ascending("ordre");
      this.themes = await queryTheme.find();
      this.foldersProtocolLoad = true;
      this.loaderFolderActive = false;
  
      this.error = false;
      if (!this.userGroup.name) {
        this.error = true;
        return;
      }
      this.loaderFolderActive = true;
    },
  };
  </script>
  
  <style>
  .steps .step-item.is-active .step-marker {
    color: #239380 !important;
    border-color: #239380 !important;
  }
  
  .steps .step-item.is-completed .step-marker {
    background-color: #239380 !important;
  }
  
  .steps.is-large .is-active:not(:first-child)::before {
    background: #239380 !important;
  }
  
  .steps.is-large .is-completed:not(:first-child)::before {
    background: #239380 !important;
  }
  
  .step-detailsDesc {
    font-size: medium;
    background-color: #239380;
    color: white;
    padding: 5px;
    border-radius: 9px;
  }
  
  .steps.is-large .step-item .step-details {
    margin-top: 4rem;
  }
  
  .cacherDetails {
    display: none;
  }
  
  .retourEspaceGestion {
    background-color: #b2b2b2;
    color: white;
    margin: 15px;
    padding: 30px;
    white-space: normal;
  }
  
  .retourEspacePersonnalisation {
    margin: 15px;
    padding: 30px;
    white-space: normal;
  }
  
  .decouvrirEspaceActivite {
    white-space: normal;
    padding: 30px;
    margin: 15px;
  }
  
  .decouvrirEspaceActivite img {
    width: 36px;
    margin-left: 20px;
  }
  
  .retourEspacePersonnalisation img {
    width: 306px;
    margin-left: 20px;
  }
  @media (max-width: 768px) {
    .responsiveDivTop {
      margin-top: 4rem;
    }
  }
  .retourEspaceGestion label {
    width: 250px;
  }
  
  .thisproto {
    opacity: 1;
    border: 2px solid #239380;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  
  .thisproto-lg {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 540px) {
    .thisproto-lg {
      height: 100px !important;
      width: 360px !important;
    }
  }
  @media screen and (max-width: 585px) and (min-width: 540px) {
    .thisproto-lg {
      height: 120px !important;
      width: 185px !important;
    }
  }
  @media screen and (max-width: 720px) and (min-width: 585px) {
    .thisproto-lg {
      height: 120px !important;
      width: 200px !important;
    }
  }
  @media screen and (max-width: 768px) and (min-width: 720px) {
    .thisproto-lg {
      height: 120px !important;
      width: 260px !important;
    }
  }
  @media screen and (min-width: 768px) {
    .thisproto-lg {
      height: 200px !important;
      width: 303px !important;
    }
  }
  .thisproto-sm {
    height: 115px;
    width: 115px;
  }
  
  .modules-list {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  @media screen and (max-width: 480px) {
    .modules-list {
      overflow-x: auto;
      flex-wrap: nowrap;
      justify-content: start;
    }
  }
  
  .dir-col-mobile {
    flex-direction: column;
  }
  
  @media screen and (min-width: 480px) {
    .dir-col-mobile {
      flex-direction: row;
    }
  }
  </style>
  