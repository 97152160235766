<template>
  <div>
    <div v-if="isLoading">
      <loader-component />
    </div>
    <div v-else-if="numberFilteredSortedFolderBrique > 0">
      <div class="carousel-title-div">
        <div class="carousel-title">
          {{ name }}
        </div>
        <!-- <div class="searchInputContainer">
          <input
            type="text"
            placeholder="Rechercher..."
            v-model="searchText"
            @input="filterFolders"
          />
          <i class="fas fa-search"></i>
        </div> -->
      </div>
      <div>
        <bfpComponent v-if="bfp_acces && name === 'Échelles d\'évaluation'" />
        <edasComponent v-if="bfp_acces && name === 'Échelles d\'évaluation'" @TerminerExerciceQCM="handleTerminerExerciceQCM" :idPatientTherapeute="idPatientTherapeute"/> 
      </div>
      <div class="carousel-wrap">
        <div>
          <div
            class="owl-carousel owl-carousel-folder owl-theme"
            ref="carousel"
          >
            <div
              class="item-folders"
              v-for="folderBrique in filteredSortedFolderBrique"
              :key="folderBrique.id"
              v-on:click="recupId(folderBrique)"
              :class="{
                ActiveImageCarousel:
                  idActive === folderBrique.id && name === 'Ressources libres',
                InactiveImageCarousel:
                  idActive !== folderBrique.id &&
                  firstBriqueOpen &&
                  name === 'Ressources libres',
              }"
              v-bind:style="
                name === 'Ressources libres'
                  ? { 'background-color': folderBrique.color }
                  : {}
              "
            >
              <div
                v-if="idActive === folderBrique.id"
                class="CroixActiveBrique"
              >
                X
              </div>
              <div
                class="IconBriqueMoniteur"
                @click="goToSymbiosensor()"
                v-if="
                  folderBrique.name === 'Biofeedback de cohérence cardiaque' || folderBrique.name ==='Indice de Lâcher-Prise'
                "
              >
                <img
                  src="../../../../assets/img/IconMoniteur.png"
                  alt="image-folders"
                />
              </div>
              <img
                v-if="
                  name === 'Programmes' || name === 'Échelles d\'évaluation'
                "
                :src="folderBrique.resourceBg.url"
                :class="{
                  ActiveImageCarousel: idActive === folderBrique.id,
                  InactiveImageCarousel:
                    idActive !== folderBrique.id && firstBriqueOpen,
                }"
                alt="image-folders"
              />
              <div
                v-else-if="name === 'Ressources libres'"
                class="ressourcesDivCarousel"
                alt="image-folders"
              ></div>
              <div class="brique-text" v-if="name === 'Programmes'">
                <!-- NOM DE LA BRIQUE -->
                <p class="is-size-7">
                  {{ folderBrique.protocoles[1].name }}
                </p>
                <!-- AUTEUR DE LA BRIQUE -->
                <p class="is-size-7">
                  {{ folderBrique.protocoles[0].link.folder.author }}
                </p>
              </div>
              <!-- <button @click="test(folderBrique)">TESTTT</button>  -->
              <div
                class="brique-text"
                v-if="
                  name === 'Ressources libres' ||
                  name === 'Échelles d\'évaluation'
                "
              >
                <p class="is-size-7">
                  {{ folderBrique.name }}
                </p>
              </div>
            </div>
            <div
              v-if="
                name === 'Échelles d\'évaluation' &&
                numberOfEvaluationScales === 0
              "
              class="no-evaluation-scales"
            >
              <p>Aucune échelle d'évaluation ne vous a été attribuée</p>
            </div>
          </div>
          <div class="owl-navigation">
            <div class="owl-nav">
              <div
                :class="{ hidden: numberFilteredSortedFolderBrique === 1 }"
                class="owl-prev"
                @click="prevSlide"
              >
                <i class="fas fa-chevron-left"></i>
              </div>
              <div
                :class="{ hidden: numberFilteredSortedFolderBrique === 1 }"
                class="owl-next"
                @click="nextSlide"
              >
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import bfpComponent from "../bfp/bfpComponent.vue";
import edasComponent from "../edas/edasComponent.vue";
import loaderComponent from "../loader/loaderComponent.vue";
export default {
  name: "CarouselFolders",
  data() {
    return {
      idActive: null,
      firstBriqueOpen: false,
      abonnementFinish: true,
      bfp_acces: false,
      listFolderIds: [],
      ListLinkId: [],
      LinkAccessFolderId: [],
      isLoading: false,
      idUserForSave: null,
      idCookie: null,
      objectIdPatient: null,
      searchText: "", // Pour stocker le texte de recherche
    };
  },
  components: {
    bfpComponent,
    loaderComponent,
    edasComponent,
  },
  props: {
    folderBrique: {
      required: true,
    },
    name: {
      required: true,
    },
    idPatientTherapeute: {
      type: String,
    },
  },
  mounted() {
    // // //console.log("Entrée dans le composant CarouselTest");
  },
  watch: {
    isLoading: function (newVal, oldVal) {
      // // //console.log("Finish to loading......." ,this.LinkAccessFolderId)
      if (oldVal === true && newVal === false) {
        setTimeout(() => {
          this.initCarousel();
        }, 500);
      }
    },
    searchText: function (newSearchText, oldSearchText) {
      this.filteredAndSortedFolderBrique =
        this.filteredSortedFolderBriqueWithSearchText();
    },
  },
  created() {
    console.log('BRIQUE RESSOURCES FOLDER BRIQUE :', this.folderBrique);
    try {
      this.idCookie = this.getCookie("idUserForSave");
    } catch (error) {
      // //console.log(error)
    }
    try {
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      this.objectIdPatient = urlParams.get("objectId");
    } catch (error) {
      // //console.log(error)
    }
    if (this.idCookie) {
      this.idUserForSave = this.idCookie;
      //  //console.log('-------')
      //  //console.log("IdUserForSave 1", this.idUserForSave);
      //  //console.log(this.name);
      //  //console.log('-------')
    } else if (this.idPatientTherapeute) {
      this.idUserForSave = this.idPatientTherapeute;
      //  //console.log('-------')
      // //console.log(this.name);
      //  //console.log("IdUserForSave 2", this.idUserForSave);
      //  //console.log('-------')
    } else {
      this.idUserForSave = Parse.User.current().id;
      // //console.log('-------')
      // //console.log("IdUserForSave 3", this.idUserForSave);
      // //console.log(this.name);
      // //console.log('-------')
    }
    ParseConfig();
    if (Parse.User.current().attributes.bfp_acces) {
      this.bfp_acces = true;
    } else {
      this.bfp_acces = false;
    }
    // //console.log('recupIdAcces en cours...')
    this.recupIdAcces();
    // this.verifAbonnement();
  },
  methods: {
    prevSlide() {
      $(this.$refs.carousel).trigger("prev.owl.carousel");
    },
    handleTerminerExerciceQCM(id, resultat,answer,categoryScores) {
      this.$emit("TerminerExerciceQCM", id, resultat,answer,categoryScores);
      //console.log("Id du questionnaire terminé :", id);
      //console.log("Résultat :", resultat);
    },
    test(folderBrique) {
      console.log('folderBrique:', folderBrique);
      console.log('Protocoles[2]',folderBrique.protocoles[2].id, 'Protocoles[3]',folderBrique.protocoles[3].id);
      console.log("Link",this.LinkAccessFolderId);
      
    },
    initCarousel() {
      $(".owl-carousel-folder").owlCarousel({
        loop: false,
        margin: 10,
        nav: false,
        autoplay: false,
        autoplayHoverPause: false,
        autoplayTimeout: 1000000,
        dots: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          800: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      });
    },
    displayBrique(e) {
      // //console.log("displayBrique", e);
    },
    filterFolders() {
      console.log("Recherche:", this.searchText);
    },
    goToSymbiosensor() {
      if (navigator.platform === "MacIntel") {
        // Ouvre l'application SymbioSensor dans le Mac App Store si l'application VueJS est exécutée sur un Mac
        window.open("https://apps.apple.com/fr/app/symbio/id1611896454", "_blank");
      } else {
        // Ouvre l'application SymbioSensor dans le Microsoft Store si l'application VueJS est exécutée sur un autre système d'exploitation
        window.open("ms-windows-store://pdp/?productid=9NQ9QQ9T3R2W", "_blank");
      }
    },
    updateCarousel() {
    // Détruire l'instance Owl Carousel existante
    this.$refs.carousel.owlCarousel('destroy');
    
    // Réinitialiser le carousel avec les nouvelles données
    this.$nextTick(() => {
      this.$refs.carousel.owlCarousel({
        // Configuration du carousel
      });
    });
  },
    nextSlide() {
      $(this.$refs.carousel).trigger("next.owl.carousel");
    },
    async recupIdAcces() {
      this.isLoading = true;
      try {
        const query = new Parse.Query("AvailableFolders");
        query.equalTo("name", this.name);

        const results = await query.find();
        if (results.length === 0) throw new Error("Aucun résultat");

        const firstResult = results[0];
        const folders = await firstResult.relation("folders").query().find();

        const folderIds = folders.map((folder) => folder.id);
        console.log('folderIds in recupIdAcces:', folderIds);
        const queryLink = new Parse.Query("link");
        queryLink.containedIn("folder", folderIds);
        console.log('FOLDERS ID:', folderIds);
        // Fetch the specific user by idUserForSave
        const userQuery = new Parse.Query(Parse.User);
        userQuery.equalTo("objectId", this.idUserForSave);

        const user = await userQuery.first();
        // //console.log("Voici le compte de l'utilisateur utilisé :", user)
        if (!user) {
          throw new Error(`User with id ${this.idUserForSave} not found.`);
        }

        const [links] = await Promise.all([queryLink.find()]);

        this.ListLinkId = links.map((link) => link.id);
        const linksRelation = user.relation("link");
        const queryUserLinks = linksRelation.query();
        queryUserLinks.containedIn("objectId", this.ListLinkId);

        const userLinks = await queryUserLinks.find();
        // //console.log('UserLink reçu:', userLinks);

        this.LinkAccessFolderId = userLinks.map(
          (link) => link.attributes.folder.id
        );
        if(user.attributes.therapeute)
        {
          console.log('CET UTILISATEUR EST UN THERAPEUTE..');
          // rajoute cet id dans la liste des folders xCNcTtS3bx
          // this.LinkAccessFolderId.push('xCNcTtS3bx');
          // this.LinkAccessFolderId.push('4PBNNhoevP');  
        }
        console.log("this.LinkAccessFolderId", this.LinkAccessFolderId);
      } catch (error) {
        // console.error("Erreur :", error);
      } finally {
        // //console.log('Chargement du recupIdAcces terminé...')
        this.isLoading = false;
      }
    },
    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    },

    filteredSortedFolderBriqueWithSearchText() {
      // Filtrer les éléments en fonction de la valeur de searchText
      if(!this.searchText) return this.filteredSortedFolderBrique;
      var SearchTextFolderBrique = this.filteredSortedFolderBrique.filter((folder) => {
        const protocoles = folder.protocoles;
        console.log(protocoles);
        if(protocoles[1].name.toLowerCase().includes(this.searchText.toLowerCase()))
        {
          //ajouter l'élément SearchTextDisplay à true pour chaque folder
          folder.SearchTextDisplay = true;
        }
        else
        {
          //ajouter l'élément SearchTextDisplay à false pour chaque folder
          folder.SearchTextDisplay = false;
        }
      });
      console.log("SearchTextFolderBrique:",SearchTextFolderBrique);
      return SearchTextFolderBrique;
    },
    recupId(folderBrique) {
      if (this.idActive === folderBrique.id) {
        this.idActive = null;
        this.firstBriqueOpen = false;
        this.$emit("recupId", null); // Émettre null pour indiquer que la brique est fermée
      } else {
        this.idActive = folderBrique.id;
        this.firstBriqueOpen = true;
        // //console.log('Id récupéré:', folderBrique.id)
        this.$emit("recupId", folderBrique);
      }
    },
  },
  computed: {
    sortedFolderBrique() {
      return _.memoize((folderBrique) => {
        return folderBrique.sort((a, b) => a.link.order - b.link.order);
      })(this.folderBrique);
    },
    numberFilteredSortedFolderBrique() {
      return this.filteredSortedFolderBrique.length;
    },
    filteredSortedFolderBrique() {
      const filteredFolders = this.folderBrique.filter((folder) => {
        const protocoles = folder.protocoles;
        if (!protocoles) return false;

        let id1, id2;

        if (this.name === "Programmes") {
          id1 = protocoles[1] && protocoles[1].id;
          id2 = protocoles[2] && protocoles[2].id;
        } else if (this.name === "Ressources libres") {
          id1 = protocoles[2] && protocoles[2].id;
          id2 = protocoles[3] && protocoles[3].id;
        } else if (this.name === "Échelles d'évaluation") {
          id1 = protocoles[0] && protocoles[0].link.folder.objectId;
          id2 = protocoles[1] && protocoles[1].link.folder.objectId;
        }
        return (
          this.LinkAccessFolderId.includes(id1) ||
          this.LinkAccessFolderId.includes(id2)
        );
      });

      return filteredFolders.sort((a, b) => a.link.order - b.link.order);
    },
    numberOfEvaluationScales() {
      return this.sortedFolderBrique.length;
    },
    numberOfFreeResources() {
      return this.sortedFolderBrique.filter((folderBrique) => {
        return (
          this.name === "Ressources libres" &&
          folderBrique.deverrouille &&
          folderBrique.deverrouille_user
        );
      }).length;
    },
    numberOfPrograms() {
      return this.sortedFolderBrique.filter((folderBrique) => {
        // Votre condition longue ici, similaire à celle dans le template
        return (
          this.name === "Programmes" &&
          folderBrique.deverrouille &&
          folderBrique.deverrouille_user &&
          (this.linkedObjectPatient && this.linkedObjectPatient.length > 0
            ? this.linkedObjectPatient.includes(folderBrique.id)
            : true) &&
          folderBrique.protocoles[1] &&
          folderBrique.protocoles[1].deverrouille
        );
      }).length;
    },
  },
};
</script>

<style scoped>
.carousel-wrap {
  margin: 40px auto;
  padding: 0 5%;
  width: 80%;
  position: relative;
  transform: translateY(20px); /* Décalez légèrement vers le bas */
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.item-folders {
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  margin: 0 15px;
  width: 345px; /* Largeur fixe pour un meilleur contrôle */
  overflow: hidden;
  transition: box-shadow 0.4s ease, transform 0.4s ease; /* Ajustement des transitions */
}

.item-folders img {
  width: 100%;
  height: 240px; /* Hauteur augmentée pour une apparence plus rectangulaire */
  cursor: pointer;
  object-fit: cover;
  border-radius: 12px;
}

.item-folders:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.12);
  transform: translateY(-3px);
}
/* .item-folders:hover .IconBriqueMoniteur img {
  transform: scale(1.1); Ajustez la valeur de mise à l'échelle selon vos besoins 
} */
.item-folders:hover .IconBriqueMoniteur::before {
  opacity: 1;
}
.IconBriqueMoniteur::before {
  content: "Symbiosensor";
  position: absolute;
  top: 7px;
  left: 30px; /* Ajustez la distance du texte par rapport à l'image */
  opacity: 0;
  transition: opacity 2s ease;
}
.IconBriqueMoniteur {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #239380;
}
.item-folders .IconBriqueMoniteur {
  transition: width 1s ease; /* Durée de la transition et type de transition */
}
.item-folders:hover .IconBriqueMoniteur {
  width: 140px;
  border-radius: 20px;
  justify-content: flex-start;
  border: 1px solid #239380;
}
.item-folders:hover .IconBriqueMoniteur img {
  width: 30px;
  height: 30px;
}
.IconBriqueMoniteur img {
  width: 30px !important;
  height: 30px !important;
}

.brique-text {
  padding: 8px 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background: rgb(0 0 0 / 12%);
  color: white; /* Texte blanc */
  padding: 5px; /* Espace autour du texte */
  border-radius: 12px;
}
.brique-text p {
  margin: 5px 0;
  font-size: 1rem !important;
  text-align: center;
}

.owl-carousel-folder .owl-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.owl-carousel-folder .owl-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.owl-prev,
.owl-next {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  width: 50px; /* Définir une largeur et une hauteur pour garantir une forme circulaire parfaite */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(72, 145, 129, 0.3);
  font-size: 24px;
  color: #2c3e50; /* Couleur de l'icône */
}

.owl-prev:hover,
.owl-next:hover {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 6px 16px rgba(72, 145, 129, 0.4);
  cursor: pointer;
}

.owl-prev:hover i,
.owl-next:hover i {
  color: #489181; /* Changez la couleur de l'icône lors du survol */
}
/* Nouveau style pour placer les flèches à gauche et à droite */
.owl-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.carousel-title-div {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.owl-prev {
  position: absolute;
  left: -10%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.owl-next {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
/* Style de base pour le conteneur de recherche */
.carousel-title-div .searchInputContainer {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 10px;
  overflow: hidden; /* Masquer le débordement de l'icône pendant l'animation */
}

/* Style de base pour l'input de recherche */
.carousel-title-div input[type="text"] {
  padding: 10px 30px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  width: 200px;
  outline: none;
  transition: border-color 0.3s;
  background: transparent; /* Arrière-plan transparent pour les animations */
}

/* Style pour l'icône de recherche */
.carousel-title-div .fas.fa-search {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
  transition: color 0.3s;
  z-index: 2; /* Pour placer l'icône au-dessus de l'input */
}

/* Animation d'agrandissement de l'input au focus */
.carousel-title-div input[type="text"]:focus {
  border-color: #239380;
  width: 250px; /* Largeur augmentée au focus */
  transition: width 0.3s, border-color 0.3s;
}

/* Animation de couleur de l'icône au hover */
.carousel-title-div .searchInputContainer:hover .fas.fa-search {
  color: #239380;
  transition: color 0.3s;
}

/* Animation de largeur de l'input en dehors du focus */
.carousel-title-div .searchInputContainer:not(:hover) input[type="text"] {
  width: 200px;
  transition: width 0.3s, border-color 0.3s;
}

.ActiveImageCarousel {
  border: 6px solid #489181;
  transition: border-color 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 255, 0, 0.2);
}

.item-folders,
.item-folders img {
  transition: all 0.3s ease;
}
.brique-text {
  color: white;
}
.carousel-title {
  font-size: 2em; /* Taille de la police */
  text-align: left; /* Texte aligné à gauche */
  margin-bottom: 20px; /* Espacement entre le titre et le carrousel */
  color: #2c3e50; /* Couleur du texte */
  font-weight: bold; /* Gras */
  text-transform: uppercase; /* Tout en majuscules */
  letter-spacing: 1.5px; /* Espacement entre les lettres */
  position: relative;
  margin-left: 5%;
}

.carousel-title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 5%; /* Alignement avec le texte */
  width: 60px; /* Largeur de la barre sous le titre */
  height: 3px; /* Hauteur de la barre */
  background-color: #489181 !important; /* Couleur de la barre */
}
/* Style pour les points du carousel */

.owl-carousel-folder .owl-dot.active {
  background-color: #3498db; /* Couleur de fond du point actif */
}

.owl-carousel-folder .owl-dots {
  text-align: center; /* Centrer les points horizontalement */
  padding-top: 10px; /* Espacement entre le carousel et les points */
}

.owl-carousel-folder .owl-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ddd; /* Couleur de fond des points */
  border-radius: 50%; /* Rend les points circulaires */
  transition: background-color 0.3s; /* Transition pour le changement de couleur */
}

.item-folders .brique-text {
  transition: background-color 1s ease, color 1s ease;
}
.item-folders:hover .brique-text {
  background-color: #23938099;
  color: white;
}
.item-folders:hover .CroixActiveBrique {
  background-color: white;
  color: #489181;
  transition: all 0.3s ease;
  border: 4px solid #489181;
}
.owl-carousel-folder .owl-dot.active {
  background-color: #3498db; /* Couleur de fond du point actif */
}
@media (max-width: 600px) {
  .carousel-wrap {
    width: 100%;
    padding: 0 2%;
    margin: 20px auto;
  }

  .item-folders {
    width: 70%; /* Ajustez la largeur pour qu'elle s'adapte mieux aux petits écrans */
    margin: 0 auto 15px; /* Centrer les éléments et ajouter un espace entre eux */
  }
  .item-folders img {
    height: 200px; /* Hauteur augmentée pour une apparence plus rectangulaire */
  }

  .carousel-title {
    font-size: 1.5em; /* Réduisez la taille de la police pour qu'elle s'adapte mieux */
    padding-left: 2%; /* Réduisez le padding pour maximiser l'espace */
  }

  .carousel-title::after {
    left: 2%; /* Alignement avec le texte ajusté */
  }

  .owl-prev,
  .owl-next {
    width: 40px; /* Réduisez la taille des boutons pour qu'ils s'adaptent mieux */
    height: 40px;
    font-size: 20px;
  }

  .owl-carousel-folder .owl-dot {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }
  .brique-text {
    padding: 10px 0; /* Augmentez le padding pour donner plus d'espace au texte */
  }

  .brique-text {
    padding: 10px 0; /* Augmentez le padding pour donner plus d'espace au texte */
  }

  .brique-text p {
    font-size: 1em; /* Augmentez la taille de la police pour une meilleure lisibilité */
    margin: 6px 0; /* Un peu plus d'espace entre les lignes */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Ajoutez une ombre au texte pour le rendre plus lisible */
  }

  .carousel-title {
    font-size: 1.5em; /* Réduisez la taille de la police pour qu'elle s'adapte mieux */
    padding-left: 2%; /* Réduisez le padding pour maximiser l'espace */
  }

  .carousel-title::after {
    left: 2%; /* Alignement avec le texte ajusté */
  }

  .owl-prev,
  .owl-next {
    width: 40px; /* Réduisez la taille des boutons pour qu'ils s'adaptent mieux */
    height: 40px;
    font-size: 20px;
  }

  .owl-prev,
  .owl-next {
    display: none; /* Masquez les flèches sur les téléphones */
  }
}
@media (min-width: 1024px) and (max-width: 1700px) {
  .item-folders {
    width: 80%;
  }
  .item-folders img {
    height: 200px; /* Hauteur augmentée pour une apparence plus rectangulaire */
  }
  .brique-text p {
    font-size: 1.2rem !important;
  }
}

/* responive pour tablette */
@media (max-width: 1024px) {
  .carousel-wrap {
    width: 100%;
    padding: 0 2%;
    margin: 20px auto;
  }

  .item-folders {
    width: 90%; /* Ajustez la largeur pour qu'elle s'adapte mieux aux petits écrans */
    margin: 0 auto 15px; /* Centrer les éléments et ajouter un espace entre eux */
  }

  .carousel-title {
    font-size: 1.5em; /* Réduisez la taille de la police pour qu'elle s'adapte mieux */
    padding-left: 2%; /* Réduisez le padding pour maximiser l'espace */
  }

  .carousel-title::after {
    left: 2%; /* Alignement avec le texte ajusté */
  }

  .owl-prev,
  .owl-next {
    /*ne pas les affiches*/
    display: none;
  }
  .item-folders {
    width: 80%;
  }

  .brique-text p {
    font-size: 1.4rem !important;
  }
}
.ressourcesDivCarousel {
  width: 100%;
  height: 240px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .ressourcesDivCarousel {
    width: 100%;
    height: 200px;
  }
}

.not-active {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.CroixActiveBrique {
  position: absolute;
  color: #489181;
  padding: 5px;
  border: 4px solid #489181;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.CroixActiveBrique:hover {
  background-color: white;
  color: #489181;
  transition: all 0.3s ease;
  border: 4px solid #489181;
}
.InactiveImageCarousel {
  opacity: 0.4;
  transition: opacity 0.3s ease;
  transform: scale(0.99);
}

.expired-subscription-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Greyed out */
  border-radius: 8px; /* Same as .item-folders border-radius */
  display: flex;
  align-items: center;
  justify-content: center;
}

.expired-subscription-text {
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  z-index: 10;
}
/* Style pour le message "Aucune ressource n'est affectée à cet utilisateur" */
.no-resources {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Hauteur du conteneur */
  background-color: #f2f2f2; /* Couleur de fond */
  border-radius: 8px; /* Rayon de la bordure */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); /* Ombre de la boîte */
  margin: 20px 0; /* Marge autour du conteneur */
}

.no-resources p {
  font-size: 1.5em; /* Taille de la police */
  color: #333; /* Couleur du texte */
  text-align: center; /* Alignement du texte */
}
.no-click {
  pointer-events: none;
}
.hidden {
  display: none !important;
}
.no-evaluation-scales {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Hauteur du conteneur */
  background-color: #f9f9f9; /* Couleur de fond */
  border-radius: 8px; /* Rayon de la bordure */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); /* Ombre de la boîte */
  margin: 20px 0; /* Marge autour du conteneur */
  transition: all 0.3s ease; /* Transition pour l'ensemble du conteneur */
}

.no-evaluation-scales p {
  font-size: 1.2em; /* Taille de la police */
  color: #666; /* Couleur du texte */
  text-align: center; /* Alignement du texte */
  margin: 0; /* Pas de marge pour le paragraphe */
}

/* Animation d'apparition */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.no-evaluation-scales {
  animation: appear 0.5s ease-in-out;
}
</style>
