import moment from "moment";

export default function getDateRenouvellement(group) {
  if (group.essai_gratuit !== undefined && group.essai_gratuit)
    return new Date(moment(group.subscription_end_date));
  if (group.date_paiement === undefined || group.date_paiement === null)
    return "période d'essai";
  let duration = null;
  let dateRenouvellement = null;
  if (group.engagement_annuel) duration = "years";
  else duration = "months";
  dateRenouvellement = new Date(moment(group.date_paiement).add(1, duration));
  while (dateRenouvellement < new Date()) {
    dateRenouvellement = new Date(moment(dateRenouvellement).add(3, duration));
  }
  return dateRenouvellement;
}
