<template>
  <div>
    <div>
      <transition name="slide-fade">
        <div class="notification-container" v-if="showNotification">
          <div class="notification-box">
            <button @click="closeNotification" class="notification-close">
              &times;
            </button>
            <div class="notification-text">Exercice terminé</div>
          </div>
        </div>
      </transition>
    </div>
    <div
      v-for="item in questionnaireBFP"
      class="card-container-bfp"
      :key="item.id"
    >
      <div
        v-bind:style="
          questionnaireSelectedId === item.id && displayQuestionnaire
            ? 'background-color: #489181'
            : ''
        "
        class="card-bfp"
        @click="openForm(item)"
        v-if="!isForBeginPage"
      >
        <div class="card-content-bfp">
          <div class="card-icon-bfp">
            <img
              v-if="questionnaireSelectedId === item.id && displayQuestionnaire"
              src="../../assets/img/icon-bfp-white.png"
            />
            <img src="../../assets/img/icon-bfp.png" v-else />
          </div>
          <div class="card-text-bfp">
            <h2
              v-bind:style="
                questionnaireSelectedId === item.id && displayQuestionnaire
                  ? 'color:white'
                  : ''
              "
            >
              {{ item.attributes.name }}
            </h2>
            <img
              v-if="questionnaireSelectedId === item.id && displayQuestionnaire"
              class="chevron-down-bfp"
              src="../../../../assets/img/ChevronUpBlancV2.png"
            />
            <img
              v-else
              src="../../../../assets/img/ChevronDownV2.png"
              class="chevron-down-bfp"
            />
          </div>
        </div>
      </div>
      <div
        v-if="displayQuestionnaire && questions.length > 0 && questionnaireSelectedId===item.id"
        class="questions"
      >
        <div v-if="displayQuestionnaire && currentQuestion" class="question">
          <h3 class="title is-4">{{ currentQuestion.name }}</h3>
          <horizontal-gauge
            :key="refreshKey"
            ref="horizontalGauge"
            :responses="currentQuestion.answers"
            @response-selected="
              confirmResponse(questions.indexOf(currentQuestion), $event)
            "
          ></horizontal-gauge>
        </div>
        <div v-if="selectedResponse" class="SelectedResponse">
          <p>
            Vous avez choisi la réponse
            <strong>{{ selectedResponse.responseNumber }}</strong> , cela
            correspond à <strong>{{ selectedResponse.responseName }}</strong> .
          </p>
          <p>
            Êtes-vous sur que ce n'est pas
            <strong>{{
              getAdjacentNumbers(selectedResponse.responseNumber)
            }}</strong>
          </p>
          <p>Êtes-vous sûr de vouloir valider cette réponse ?</p>
          <button class="button is-danger" @click="cancelResponse()">
            Non
          </button>
          <button
            class="button is-success"
            @click="validateResponse(selectedResponse.responseNumber)"
          >
            Oui
          </button>
        </div>
      </div>
      <div
        v-if="displayQuestionnaire && !currentQuestion && !isForBeginPage"
        class="score-results"
      >
        <div class="result-indicator" :class="resultbfp"></div>
        <div class="score-details">
          <h3 class="title is-4">Score total : {{ getScoreTotal() }}</h3>
          <p>
            Bilan : <strong>{{ getBilan(getScoreTotal()) }}</strong
            ><br />
            État : <strong>{{ textresultbfp }}</strong>
          </p>
        </div>
      </div>
      <!-- <button @click="testReset()">test</button> -->
      <!-- <div  class="score-results" v-else-if="isForBeginPage && displayQuestionnaire && !currentQuestion">
        Vous avez terminé le questionnaire, vous pouvez maintenant continuer
      </div> -->
    </div>
  </div>
</template>

<script>
import { getBFP } from "../../services/bfp/getBFP";
import { findQuestionsByQuestionnaireId } from "@/services/bfp/findQuestionsByQuestionnaireId";
import HorizontalGauge from "@/components/bfp/HorizontalGauge.vue";
import Parse from "parse";
import { createNotificationForBeginQuestionnaire } from "@/services/notifications/createNotificationForBeginQuestionnaire.js";

import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  data() {
    return {
      formOpen: false,
      questionnaireBFP: [],
      questionnaireSelected: "",
      questions: [],
      selectedAnswers: [],
      currentQuestionIndex: 0,
      selectedResponse: null,
      resultbfp: "",
      refreshKey: 0,
      textresultbfp: "",
      etatcrise: false,
      displayQuestionnaire: false,
      questionnaireSelectedId: "",
      totalScore: 0,
      showNotification: false,
      questionScores: [],
    };
  },
  components: {
    HorizontalGauge,
  },
  async created() {
    this.questionnaireBFP = await getBFP();
    if (this.isForBeginPage) {
      this.openForm(this.questionnaireBFP[0]);
    }
  },
  watch: {
    currentQuestion(newValue, oldValue) {
      console.log("currentQuestion", newValue, oldValue);
      if (newValue === undefined) {
        this.TerminerExercice(this.questionnaireSelectedId, this.totalScore);
      }
    },
  },
  computed: {
    isQuestionnaireEmpty() {
      return Object.keys(this.questionnaireBFP[0]).length === 0;
    },
    unansweredQuestions() {
      return this.questions.filter(
        (question, index) => !this.selectedAnswers[index]
      );
    },
    currentQuestion() {
      return this.getCurrentQuestion();
    },
  },
  props: {
    isForBeginPage: {
      type: Boolean,
      default: false,
    },
    idPatientTherapeute: {
      type: String,
      default: "",
    },
  },
  methods: {
    refreshComponent() {
      this.refreshKey++; // Incrémente la valeur de refreshKey pour déclencher le rafraîchissement du composant
    },
    testReset()
    {
      this.$refs.horizontalGauge.testRef();
    },
    openForm(questionnaire) {
      this.questionnaireSelectedId = questionnaire.id;
      this.questionnaireSelected = questionnaire;
      this.findQuestion(questionnaire.id);
      this.displayQuestionnaire = !this.displayQuestionnaire;
    },
    startTimer() {
      this.showNotification = true;
      this.progressWidth = 0;
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.progressWidth += 3.33;
        if (this.progressWidth >= 100) {
          clearInterval(this.intervalId);
        }
      }, 100);
      setTimeout(() => {
        this.closeNotification();
      }, 3000);
    },
    closeNotification() {
      this.showNotification = false;
      this.progressWidth = 0;
    },
    async findQuestion(id) {
      try {
        const results = await findQuestionsByQuestionnaireId(id);
        this.questions = results;
      } catch (error) {
        console.error("Error while fetching questions:", error);
      }
    },
    getCurrentQuestion() {
      return this.unansweredQuestions[this.currentQuestionIndex];
    },
    async TerminerExercice(fileId, note) {
      this.$emit("EnableButton");
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      var noteQuestionnaire = note.toString();
      const objectFolder = Parse.Object.extend("folder");
      const objectFile = Parse.Object.extend("file");
      const objectUser = Parse.Object.extend("User");
      const objetLink = Parse.Object.extend("link");
      const objectQuestionnaire = Parse.Object.extend("questionnaire");
      const queryFolder = new Parse.Query(objectFolder);
      const queryFolder2 = new Parse.Query(objectFolder);
      const queryUser = new Parse.Query(objectUser);
      const queryFile = new Parse.Query(objectFile);
      const queryLink = new Parse.Query(objetLink);
      const queryQuestionnaire = new Parse.Query(objectQuestionnaire);
      queryLink.include("file");
      //find a link with file
      queryQuestionnaire.equalTo("objectId", fileId);
      queryFile.matchesQuery("questionnaire_", queryQuestionnaire);
      queryLink.matchesQuery("file", queryFile);

      let brique, module, file, user, link, questionnaire;
      console.log('Id:', this.idPatientTherapeute, 'Local:', localStorage.getItem("idUserForSave"), 'Current:', Parse.User.current().id);
      if(this.idPatientTherapeute)
      {
        queryUser.equalTo("objectId", this.idPatientTherapeute);
      }
      else if(localStorage.getItem("idUserForSave"))
      {
        queryUser.equalTo("objectId", localStorage.getItem("idUserForSave"));
      }
      else
      {
        queryUser.equalTo("objectId", Parse.User.current().id);
      }
      [brique, module, file, user, link, questionnaire] = await Promise.all([
        queryFolder.find(),
        queryFolder2.find(),
        queryFile.find(),
        queryUser.find(),
        queryLink.find(),
        queryQuestionnaire.find(),
      ]);
      console.log(brique, module, file, user, link, questionnaire);
      var scoreDetail = JSON.stringify(this.questionScores);
      //passer la note en string
      var noteQuestionnaire = this.totalScore;
      var noteQuestionnaire2 = noteQuestionnaire.toString();
      const dataFileDone = {
        brique: undefined,
        time_elapsed: this.elapsedTime,
        user: user[0],
        module: undefined,
        protocole: undefined,
        resultatJeux: undefined,
        note: noteQuestionnaire,
        file: file[0],
        comment: undefined,
        group: Parse.User.current().get("group"),
        note_detail: scoreDetail,
        bfp: true,
      };
      const Data = Parse.Object.extend("file_done");
      const dataDone = new Data();
      try {
        const result = await dataDone.save(dataFileDone);
        createNotificationForBeginQuestionnaire(file[0], noteQuestionnaire);
        // const result2 = await dataLink.save(dataLinkCompleted);
        this.answers = [];
        Toast.fire({
          icon: "success",
          title: "Votre questionnaire a été envoyé avec succès",
        });
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Une erreur est survenue lors de l'envoi du questionnaire",
        });
        s;
        console.error("Error while creating Data: ", error);
      }
      this.$forceUpdate();
    },
  
    validateResponse(score) {
      this.questionScores.push({
        question: this.questions[this.currentQuestionIndex].name,
        score: score,
      });
      this.totalScore = this.getScoreTotal();
      this.selectedAnswers[this.selectedResponse.questionIndex] =
        this.selectedResponse.responseNumber;
      this.selectedResponse = null;
      //si le score est égal ou supérieur à 7
      if (score >= 7) {
        this.etatcrise = true;
      }
      if (this.currentQuestionIndex < this.questions.length - 1) {
        // Passer à la question suivante
        this.currentQuestionIndex++;
        this.$refs.horizontalGauge.resetSelectedResponse();
      } else {
        // Toutes les questions ont été répondues
        // Effectuer l'action souhaitée ici (par exemple, soumettre les réponses)
      }
      // Mettre à jour la question actuelle en fonction de l'index
      this.currentQuestionIndex = this.unansweredQuestions.findIndex(
        (question, index) => !this.selectedAnswers[index]
      );
      this.refreshComponent();
    },

    getScoreTotal() {
      return this.questionScores.reduce((accumulator, questionScore) => {
        return accumulator + questionScore.score;
      }, 0);
    },
    getBilan(score) {
      if (score >= 3 && score <= 10 && !this.etatcrise) {
        this.resultbfp = "vert";
        this.textresultbfp = "État d'équilibre";
        return "vert";
      } else if (score >= 11 && score <= 18 && !this.etatcrise) {
        this.resultbfp = "orange";
        this.textresultbfp = "État de vulnérabilité";
        return "orange";
      } else if ((score >= 19 && score <= 30) || this.etatcrise) {
        this.resultbfp = "rouge";
        this.textresultbfp = "État de crise";
        return "rouge";
      }
    },
    getAdjacentNumbers(number) {
      let lower = number - 1;
      let higher = number + 1;
      let adjacentNumbers = [];

      if (lower >= 1 && lower !== number) {
        adjacentNumbers.push(lower);
      }

      if (higher <= 10 && higher !== number) {
        adjacentNumbers.push(higher);
      }

      return adjacentNumbers.join(" ou ");
    },
    cancelResponse() {
      this.selectedResponse = null;
    },
    confirmResponse(indexQuestion, response) {
      this.selectedResponse = {
        questionIndex: indexQuestion,
        responseNumber: response.number,
        responseName: response.name,
      };
    },
  },
};
</script>

<style scoped>
.card-container-bfp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-bfp {
  width: 100%;
  max-width: 1000px;
  border: 1px solid #489181;
  padding: 20px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: white;
  margin-top: 1rem;
}
.card-bfp:hover {
  background-color: #489181;
  color: white !important;
  transition: 0.3s;
  transform: scale(1.04);
}

.card-bfp:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-content-bfp {
  display: flex;
  align-items: center;
}

.card-icon-bfp {
  margin-right: 10px;
  font-size: 24px;
  color: #42b983;
}

.card-text-bfp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.card-content-bfp h2,
.card-content-bfp p {
  margin: 0;
  color: #000;
}

.chevron-down-bfp {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.form-card-bfp {
  width: 80%;
  max-width: 1000px;
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: -10px;
  border-radius: 0px 0px 8px 8px;
  background-color: white;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.selected-questionnaire {
  margin-top: 2%;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 1.5rem;
}
.SelectedResponse {
  text-align: center;
}
.selected-questionnaire h2 {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  width: 50%;
}

.question {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  padding-bottom: 120px;
  background-color: white;
}

.SelectedResponse {
  padding: 15px;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  color: #856404;
  font-weight: bold;
}

.SelectedResponse p {
  margin-bottom: 10px;
}

.button {
  margin-right: 10px;
}
.selected-questionnaire .start-questionnaire {
  font-size: 1rem;
  font-weight: 600;
  background-color: #3273dc;
  color: #fff;
  border-color: transparent;
  box-shadow: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}

.selected-questionnaire .start-questionnaire:hover {
  background-color: #2366d1;
  color: #fff;
}
.questions {
  margin-top: 1rem;
}

.answer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

input[type="radio"] {
  margin-right: 0.5rem;
}
.score-results {
  display: flex;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result-indicator {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result-indicator.vert {
  background-color: #00ff00;
}

.result-indicator.orange {
  background-color: #ffa500;
}

.result-indicator.rouge {
  background-color: #ff0000;
}

.score-details {
  flex: 1;
}

.score-details h3 {
  margin-bottom: 10px;
  text-align: center;
}

.score-details p {
  text-align: center;
}
</style>
